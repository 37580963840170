import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Form, DatePicker } from "antd";
import InputField from "../../../../common/components/InputField/InputField";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import Btn from "../../../../common/components/Btn/Btn";
import OriginSelect from "../OriginSelect/OriginSelect";
import AddPhoto from "../../../../common/components/AddPhoto/AddPhoto";
import { postOneSong } from "../../../../Api/songs/postOneSong";
import Message from "../../../../common/components/Message/Message";
import Spinner from "../../../../common/components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { urls } from "../../../../Router/urls";
import store from "../../../../Redux/store";
import { showNotificationAction } from "../../../../Redux/actions/notificationActions";

const AddSongForm = (props) => {
  const { className } = props;
  const history = useHistory();

  const [form] = Form.useForm();
  const [imageData, setImageData] = useState("");
  const [photoError, setPhotoError] = useState("");
  const [isLodaing, setIsLodaing] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [additionalArtists, setAdditionalArtists] = useState([]);

  const onFinish = (values) => {
    if (imageData === "") {
      setPhotoError("Proszę dodaj zdjęcie okładki singla aby kontynouwać.");
      window.scrollTo(0, 0);
    } else {
      setIsLodaing(true);
      setPhotoError("");
      setGeneralError("");

      const year = values.year._d.getYear() + 1900; // JS zapisuje lata od 1900 w zwyż
      const songFinalData = {
        artist: values.artist,
        albumName: values.albumName,
        name: values.name,
        origin: values.origin,
        year: year,
        ytLink: values.ytLink,
      };
      postOneSong(songFinalData, imageData, additionalArtists).then((res) => {
        setIsLodaing(false);

        if (res.status === 400) {
          setGeneralError(res.data.general);
        } else {
          store.dispatch(
            showNotificationAction(
              {
                title: "PROŚBA DODANA",
                message:
                  "Dzięki, że nas wspierasz! Rozpatrzymy Twoją prośbę w ciągu 24h.",
                icon: "like",
              },
              10
            )
          );
          history.push(urls.homePage);
        }

        form.resetFields();
      });
    }
  };

  const addAdditionalArtistHandler = () => {
    setAdditionalArtists([...additionalArtists, ""]);
  };

  const updateAdditionalArtistsHandler = (e, index) => {
    const newArr = [...additionalArtists];
    newArr[index] = e.target.value;
    setAdditionalArtists(newArr);
  };

  return (
    <section className={`${className ?? ""}`}>
      <Form
        className="add-song"
        form={form}
        onFinish={onFinish}
        initialValues={{
          origin: "PL",
        }}
      >
        <div className="mb-4">
          <CenterContent>
            <AddPhoto
              id="song"
              btnText="Dodaj okładkę singla*"
              sendImageDataToParent={setImageData}
            />
          </CenterContent>
          {photoError !== "" && (
            <CenterContent>
              <Message type="error" className="mt-2">
                {photoError}
              </Message>
            </CenterContent>
          )}
        </div>
        <div className="mb-4">
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField label="Nazwa utworu*" />
          </Form.Item>
        </div>
        <div className="mb-4">
          <Form.Item
            name="artist"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField label="Wykonawca*" />
          </Form.Item>
        </div>
        {additionalArtists.length > 0 &&
          additionalArtists.map((v, index) => (
            <div className="mb-4">
              <InputField
                label="Wykonawca dodatkowy*"
                key={index}
                onChange={(e) => updateAdditionalArtistsHandler(e, index)}
              />
            </div>
          ))}
        <CenterContent className="mb-4">
          <Btn onClick={addAdditionalArtistHandler}>
            Dodaj kolejnego wykonawcę
          </Btn>
        </CenterContent>

        <div className="mb-4">
          <Form.Item
            name="year"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <DatePicker.YearPicker placeholder="Rok wydania*" />
          </Form.Item>
        </div>
        <div className="mb-4">
          <Form.Item
            name="ytLink"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField label="Link do utworu na YouTube*" />
          </Form.Item>
        </div>
        <div className="mb-6">
          <Form.Item
            name="origin"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <OriginSelect />
          </Form.Item>
        </div>
        <Form.Item>
          <CenterContent>
            {isLodaing ? (
              <Spinner />
            ) : (
              <Btn type="primary" htmlType="submit">
                Dodaj singiel
              </Btn>
            )}
          </CenterContent>
        </Form.Item>
        {generalError !== "" && (
          <CenterContent className="mt-2">
            <Message type="error">{generalError}</Message>
          </CenterContent>
        )}
        <p className="pt-6">
          Po naciśnięciu "Dodaj utwór" twoja porśba zostanie wysłana do
          moderatora. Czas rozpatrzenia prośby wynosi do <strong>24h</strong>.
          Punkty otrzymasz dopiero po akceptacji.
        </p>
      </Form>
    </section>
  );
};

AddSongForm.propTypes = {
  className: PropTypes.string,
};

export default memo(AddSongForm);
