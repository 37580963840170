import React, { memo, useState } from "react";
import MetaData from "../../common/components/MetaData/MetaData";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import ArticlesContainer from "./components/ArticlesContainer";
import { Tabs } from "antd";
import MonthSummariesContainer from "./components/MonthSummariesContainer";
const { TabPane } = Tabs;

const ArticlesPage = () => {
  const [activeTab, setActiveTab] = useState("articles");
  return (
    <section>
      <MetaData
        title={"Artykuły - Rap Krytyk"}
        description="Artykuły - Rap Krytyk"
      />
      <PageContainer>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(active) => setActiveTab(active)}
        >
          <TabPane tab={"Artykuły"} key={"articles"}>
            <>
              <PageTitle>Artykuły</PageTitle>
              <ArticlesContainer />
            </>
          </TabPane>
          <TabPane tab={"Rapowe podsumowania miesięcy"} key={"month-summaries"}>
            <>
              <PageTitle>Rapowe podsumowania miesięcy</PageTitle>
              <MonthSummariesContainer />
            </>
          </TabPane>
        </Tabs>
      </PageContainer>
    </section>
  );
};

export default memo(ArticlesPage);
