import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { leagues } from "../../../../common/variables/leagues/leagues";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";

const AwardList = (props) => {
  const { userRank, your } = props;

  const [awards, setAwards] = useState([]);

  useEffect(() => {
    const newAwards = [];
    for (let i = userRank; i >= 1; i--) {
      const srcString = `https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/rank-${i}.png?alt=media`;

      newAwards.push(
        <li className="awards-bio__list__item">
          {leagues[i].name}
          <div className="awards-bio__list__item__img-container">
            <img src={srcString} alt={srcString} />
          </div>
        </li>
      );
    }
    setAwards(newAwards);
  }, []);

  return (
    <div className="awards-bio">
      <h1 className="awards-bio__title">{your && "TWOJE "} NAGRODY</h1>
      {awards.length > 1 && <ul className="awards-bio__list">{awards}</ul>}
      {awards.length == 1 && (
        <CenterContent className="c-gray">
          Wbijaj kolejne rangi, by zdobywać nagrody.
        </CenterContent>
      )}
    </div>
  );
};

AwardList.propTypes = {
  userRank: PropTypes.number,
  your: PropTypes.bool,
};

export default memo(AwardList);
