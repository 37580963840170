import React, { memo } from "react";

import CenterContent from "../../../common/components/CenterContent/CenterContent";
import Btn from "../../../common/components/Btn/Btn";
import { TrophyFilled } from "@ant-design/icons";

const ContentSection = () => {
  return (
    <section className="points-section">
      <h2 className="recently-added-header">
        <CenterContent className="text-center c-yellow mt-2">
          PRZYGOTOWALIŚMY DLA WAS KONKURS !
        </CenterContent>
      </h2>
      <CenterContent>
        <strong>Nagrody (płyty):</strong>
      </CenterContent>
      <CenterContent>
        <TrophyFilled className="mr-1 c-gray" /> Europa - Taco Hemingway
      </CenterContent>
      <CenterContent>
        <TrophyFilled className="mr-1 c-gray" /> Pleśń - Guzior
      </CenterContent>
      <CenterContent>
        <TrophyFilled className="mr-1 c-gray" /> Nadciśnienie - Paluch
      </CenterContent>
      <CenterContent className="mt-4">
        <strong>Co trzeba zrobić?</strong>
      </CenterContent>
      <CenterContent className="mb-4">
        Wszystko znajdziesz w opisie pod zdjęćiem
      </CenterContent>
      <CenterContent>
        <a href="https://www.instagram.com/p/CJDjzhIjvfs/" target="_blank">
          <Btn>Przejdź do konkurus</Btn>
        </a>
      </CenterContent>
    </section>
  );
};

export default memo(ContentSection);
