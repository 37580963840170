import React, { memo } from "react";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { StarFilled } from "@ant-design/icons";

const SingleReviewOverviewSkeleton = () => {
  return (
    <div className="review-overview__single-skeleton">
      <div className="mb-4">
        <div className="review-overview__single-skeleton__short skeleton mb-2"></div>
        <div className="review-overview__single-skeleton__short skeleton mb-1"></div>
      </div>
      <div className="mb-4 ml-2 mr-2">
        <div className="review-overview__single-skeleton__long skeleton mb-2"></div>
        <div className="review-overview__single-skeleton__long skeleton mb-2"></div>
        <div className="review-overview__single-skeleton__long skeleton mb-2"></div>
        <div className="review-overview__single-skeleton__long skeleton mb-2"></div>
      </div>
      <CenterContent>
        <StarFilled />{" "}
        <span className="review-overview__single-skeleton__super-short skeleton ml-1"></span>
      </CenterContent>
    </div>
  );
};

export default SingleReviewOverviewSkeleton;
