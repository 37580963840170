import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-with-locales-es6";
import { Link } from "react-router-dom";

const RecommendedArticleTile = (props) => {
  const {
    id,
    title,
    headerImg,
    author,
    teaserText,
    tags,
    timeStamp,
    link,
  } = props.articleData;

  return (
    <Link className="recommended-article-tile" key={id} to={link}>
      <div className="recommended-article-tile__img-container">
        <img src={headerImg} alt="article-header" />
        <div className="recommended-article-tile__img-container__date">
          {moment(timeStamp).format("DD.MM.YYYY")}
        </div>
      </div>
      <div className="recommended-article-tile__text">
        <h2 className="recommended-article-tile__text__title">{title}</h2>
      </div>
    </Link>
  );
};

RecommendedArticleTile.propTypes = {
  articleData: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
    headerImg: PropTypes.string,
    id: PropTypes.string,
    link: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    teaserText: PropTypes.string,
    timeStamp: PropTypes.string,
  }).isRequired,
};

export default memo(RecommendedArticleTile);
