import * as firebase from "firebase";
import axios from "axios";
import store from "../../Redux/store";
import { getUserDataAction } from "../../Redux/actions/userActions";
import {
  START_AUTHENTICATION,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER_ERRORS,
} from "../../Redux/types/userActionTypes";
import { checkIfUserExists } from "../../Api/authentication/checkIfUeserExistsApiCall";
import { urls } from "../../Router/urls";
import jwtDecode from "jwt-decode";
import { showNotificationAction } from "../../Redux/actions/notificationActions";

const config = {
  apiKey: "AIzaSyDD-JGZrWTIkCO9aROy4-S331JtMLT-adI",
  authDomain: "rapkrytyk-8950a.firebaseapp.com",
  databaseURL: "https://rapkrytyk-8950a.firebaseio.com",
  projectId: "rapkrytyk-8950a",
  storageBucket: "rapkrytyk-8950a.appspot.com",
  messagingSenderId: "534996723677",
  appId: "1:534996723677:web:70a0cbe6d0d8dc2de2b834",
  measurementId: "G-F9GGRW0THZ",
};

firebase.initializeApp(config);
firebase.app().functions("europe-west1");
var googleProvider = new firebase.auth.GoogleAuthProvider();
var facebookProvider = new firebase.auth.FacebookAuthProvider();

export const analitics = firebase.analytics();

export const loginWithEmailAndPassword = (email, password) => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then((data) => {
          if (data.user.emailVerified) {
            store.dispatch(
              showNotificationAction(
                {
                  title: "CZEŚĆ!",
                  message: "Miło Ciebie widzieć.",
                  icon: "smile",
                },
                20
              )
            );
          } else {
            store.dispatch({
              type: SET_USER_ERRORS,
              payload: {
                general:
                  "Adres email nie został zweryfikowany. Proszę kliknij w link, który został przesłany na Twoją skrzynkę pocztową.",
              },
            });
          }
        })

        .catch((err) => {
          console.log(err);
          if (
            err.code === "auth/wrong-password" ||
            err.code === "auth/user-not-found"
          ) {
            store.dispatch({
              type: SET_USER_ERRORS,
              payload: {
                general:
                  "Podano nieprawidłowy e-mail lub hasło. Możliwe, że użyłeś złego typu logowania - użyj tego, który został użyty przy zakładaniu konta.",
              },
            });
          }
        });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logOutFunction = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      localStorage.removeItem("token");
      delete axios.defaults.headers.common["Authorization"];
      store.dispatch({ type: SET_UNAUTHENTICATED });
    })
    .catch((error) => {
      console.log(error);
    });
};

export const refreshIdToken = () => {
  firebase
    .auth()
    .currentUser.getIdToken(/* forceRefresh */ true)
    .then((idToken) => {
      return idToken;
    })
    .then((token) => {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const loginWithGooglePopup = () => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithPopup(googleProvider)
        .then((result) => {
          store.dispatch(
            showNotificationAction(
              {
                title: "CZEŚĆ!",
                message: "Miło Ciebie widzieć.",
                icon: "smile",
              },
              20
            )
          );
          return { sucess: true };
        })
        .catch((error) => {
          console.log(error.code);
          if (error.code === "auth/account-exists-with-different-credential")
            store.dispatch({
              type: SET_USER_ERRORS,
              payload: {
                general:
                  "Ten email posiada już konto, ale z innymi danymi do logowania. Spróbuj zalogować się inną metodą.",
              },
            });
          else {
            store.dispatch({
              type: SET_USER_ERRORS,
              payload: { general: "Wystąpił błąd." },
            });
          }
        });
    });
};

export const loginWithFacebookPopup = () => {
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => {
      firebase
        .auth()
        .signInWithPopup(facebookProvider)
        .then((result) => {
          store.dispatch(
            showNotificationAction(
              {
                title: "CZEŚĆ!",
                message: "Miło Ciebie widzieć.",
                icon: "smile",
              },
              20
            )
          );

          return { sucess: true };
        })
        .catch((error) => {
          console.log(error.code);
          if (error.code === "auth/account-exists-with-different-credential")
            store.dispatch({
              type: SET_USER_ERRORS,
              payload: {
                general:
                  "Ten email posiada już konto, ale z innymi danymi do logowania. Spróbuj zalogować się inną metodą.",
              },
            });
          else {
            store.dispatch({
              type: SET_USER_ERRORS,
              payload: { general: "Wystąpił błąd." },
            });
          }
        });
    });
};

// watcher for user login to get IdToken
export const startWatchingForIdToken = () => {
  firebase.auth().onAuthStateChanged((user) => {
    if (
      (user && user.emailVerified) ||
      (user && user.providerData[0].providerId == "facebook.com")
    ) {
      store.dispatch({
        type: START_AUTHENTICATION,
      });
      user
        .getIdToken(true)
        .then((idToken) => {
          localStorage.setItem("token", idToken);
          axios.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;
          const decodedToken = jwtDecode(idToken);
          console.log("expire time", new Date(decodedToken.exp * 1000));
        })
        .then(() => {
          checkIfUserExists({
            nickName: user.email.split("@")[0],
            email: user.email,
          }).then(() => {
            store.dispatch(getUserDataAction());
            store.dispatch({
              type: SET_AUTHENTICATED,
            });
            setInterval(function () {
              refreshIdToken();
            }, 59 * 60 * 1000); //evry 59 minutes
          });
        });
    }
  });
};
