import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { EditOutlined } from "@ant-design/icons";
import InputField from "../../../../common/components/InputField/InputField";
import Btn from "../../../../common/components/Btn/Btn";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import Message from "../../../../common/components/Message/Message";
import { editUserNickname } from "../../../../Api/user/editUserNickname";
import Spinner from "../../../../common/components/Spinner/Spinner";

const NickNameWithEdit = (props) => {
  const { className, nickName, userEmail } = props;
  const [editMode, setEditMode] = useState(false);
  const [newNickName, setNewNickname] = useState();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNewNickname(nickName);
  }, []);

  const saveChangesHandler = () => {
    setError(false);
    setLoading(true);
    if (newNickName === nickName) setEditMode(false);
    else {
      editUserNickname(userEmail, newNickName).then((res) => {
        setLoading(false);
        if (res.status == 400) {
          setError(true);
        } else {
          window.location.reload();
        }
      });
    }
  };

  return (
    <>
      {!editMode && (
        <h1 className={`profile__nickname ${className ?? ""}`}>
          {nickName}{" "}
          <EditOutlined
            className="profile__nickname__edit"
            onClick={() => setEditMode(true)}
          />
        </h1>
      )}
      {editMode && (
        <>
          <InputField
            value={newNickName}
            maxLength={25}
            onChange={(e) => setNewNickname(e.target.value)}
          />
          {loading && <Spinner />}

          {error && (
            <CenterContent className="mt-2">
              <Message type="error">Ten pseudonim jest już zajęty.</Message>
            </CenterContent>
          )}
          {!loading && (
            <CenterContent className="mt-2 mb-4">
              <Btn onClick={saveChangesHandler}>Zapisz zmiany</Btn>
            </CenterContent>
          )}
        </>
      )}
    </>
  );
};

NickNameWithEdit.propTypes = {
  className: PropTypes.string,
  nickName: PropTypes.string,
  userEmail: PropTypes.string,
};

export default memo(NickNameWithEdit);
