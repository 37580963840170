import axios from "axios";
import { urls } from "../../Router/urls";

export const registerApiCall = (registerData) => {
  return axios
    .post(`${urls.base}/api/signup`, registerData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response.data;
    });
};
