import React, { memo } from "react";
import PropTypes from "prop-types";
import { List } from "antd";

const SongListSkeleton = (props) => {
  const { className } = props;
  const rows = [1, 2, 3, 4, 5, 6, 7, 8];
  return (
    <div className={className}>
      <List
        className="add-album-container__list"
        size="large"
        header={
          <div className="add-album-container__list__header-skeleton skeleton"></div>
        }
        dataSource={rows}
        renderItem={(item) => (
          <List.Item
            actions={[
              <div className="add-album-container__list__right-skeleton skeleton"></div>,
            ]}
          >
            <div className="add-album-container__list__content-skeleton skeleton"></div>
          </List.Item>
        )}
      />
    </div>
  );
};

SongListSkeleton.propTypes = {
  className: PropTypes.string,
};

export default memo(SongListSkeleton);
