import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import CenterContent from "../CenterContent/CenterContent";
import PaginationBar from "../PaginationBar/PaginationBar";

const SimpleTable = (props) => {
  const { data, headers, paginationData } = props;

  return (
    <div>
      <Table columns={headers} dataSource={data} pagination={false} />
      {/* <CenterContent className="pt-6">
        <PaginationBar
          total={paginationData.total}
          current={paginationData.current}
        />
      </CenterContent> */}
    </div>
  );
};

Table.propTypes = {
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      dataIndex: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
    }).isRequired
  ),
  // paginationData: PropTypes.shape({
  //   total: PropTypes.number.isRequired,
  //   current: PropTypes.number.isRequired,
  // }).isRequired,
};
export default SimpleTable;
