import axios from "axios";
import { urls } from "../../Router/urls";
import store from "../../Redux/store";

export const getMyRequestsHistory = () => {
  const reduxState = store.getState();
  return axios
    .get(
      `${urls.base}/api/get-user-requests?user=${reduxState.user.credentials.email}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
