import React, { memo } from "react";
import PropTypes from "prop-types";

const PageContainer = (props) => {
  const { children, className, type } = props;
  return (
    <div className={`page-container ${type ?? ""} ${className ?? ""}`}>
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  type: PropTypes.string, //flex - only possiblity
};

export default memo(PageContainer);
