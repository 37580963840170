import React, { memo, useEffect } from "react";
import PropTypes from "prop-types";
import GrayText from "../../../../../common/components/GrayText/GrayText";
import PageTitle from "../../../../../common/components/PageTitle/PageTitle";
import { YoutubeFilled, StarFilled } from "@ant-design/icons";
import Number from "../../../../../common/components/Number/Number";
import CoverPhoto from "../../../../../common/components/CoverPhoto/CoverPhoto";
import AboutSongStaticSkeleton from "./AboutSongStaticSkeleton";
import { Link } from "react-router-dom";
import AdSense from "react-adsense";

const AboutSongStatic = (props) => {
  const { title } = props;
  const {
    name,
    artist,
    album,
    year,
    ytLink,
    avarageRate,
    imageUrl,
    numberOfRates,
    additionalArtists,
  } = props.songData;

  let ytIFRAMElink;
  if (ytLink) {
    if (ytLink.includes("youtube.com")) {
      const ytURL = new URL(ytLink);
      const ytParams = new URLSearchParams(ytURL.search);
      const songID = ytParams.get("v");
      ytIFRAMElink = `https://www.youtube.com/embed/${songID}`;
    } else if (ytLink.includes("spotify.com") && ytLink.includes("track/")) {
      const songID = ytLink.split("track/")[1];
      ytIFRAMElink = `https://open.spotify.com/embed/track/${songID}`;
    }
  }

  return (
    <>
      <PageTitle>{title}</PageTitle>
      <div className="about-song__wrapper">
        <div className="about-song__wrapper__info-block">
          <div className="about-song__wrapper__info-block__info mb-2">
            <GrayText className="mr-1">Wykonawca:</GrayText>
            <Link
              to={`/artist/${artist
                .replace(/[^\w\s]/gi, "-") //special characters
                .replace(/\s/g, "") //spaces
                .replace(/_/g, "-") //underscore
                .toLowerCase()}`}
            >
              <strong>{artist}</strong>
            </Link>
          </div>
          {additionalArtists && additionalArtists.length > 0 && (
            <div className="about-song__wrapper__info-block__info mb-2">
              <GrayText className="mr-1">Feat:</GrayText>
              {additionalArtists.map((singleAdditionalArtist) => (
                <Link
                  className="mr-1"
                  to={`/artist/${singleAdditionalArtist
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}`}
                >
                  <strong>{singleAdditionalArtist}</strong>
                </Link>
              ))}
            </div>
          )}
          {album && (
            <>
              {name == album ? (
                <div className="about-song__wrapper__info-block__info mb-2">
                  <GrayText className="mr-1">Singiel</GrayText>
                </div>
              ) : (
                <div className="about-song__wrapper__info-block__info mb-2">
                  <GrayText className="mr-1">Album:</GrayText>{" "}
                  <Link
                    to={`/album/${artist
                      .replace(/[^\w\s]/gi, "-") //special characters
                      .replace(/\s/g, "") //spaces
                      .replace(/_/g, "-") //underscore
                      .toLowerCase()}_${album
                      .replace(/[^\w\s]/gi, "-") //special characters
                      .replace(/\s/g, "") //spaces
                      .replace(/_/g, "-") //underscore
                      .toLowerCase()}`}
                  >
                    <strong>{album}</strong>
                  </Link>
                </div>
              )}
            </>
          )}

          <div className="about-song__wrapper__info-block__info mb-2">
            <GrayText className="mr-1">Rok wydania:</GrayText> {year}
          </div>
          {ytLink && (
            <div className="about-song__wrapper__info-block__info mb-2">
              <GrayText className="mr-1">Odsłuchaj na:</GrayText>
              <a
                href={ytLink}
                target="_blank"
                className="about-song__wrapper__info-block__link"
              >
                <YoutubeFilled />
              </a>
            </div>
          )}
          <div className="about-song__wrapper__info-block__info mb-2">
            <GrayText className="mr-1">Ilość ocen:</GrayText>
            <Number>{numberOfRates}</Number>
          </div>
          <div className="about-song__wrapper__info-block__rate mb-2">
            <GrayText>Średnia ocena:</GrayText>
            <Number>{avarageRate}</Number>
            <StarFilled />
          </div>
        </div>

        <CoverPhoto imageSrc={imageUrl} />
        {ytLink && (
          <iframe
            className="about-song__wrapper__iframe"
            src={ytIFRAMElink}
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        )}

        {/* <AdSense.Google
          client="ca-pub-1809861029392662"
          slot="7806394673"
          className="about-song__wrapper__ad-sense"
          layout="in-article"
          format="fluid"
        /> */}
      </div>
    </>
  );
};

AboutSongStatic.propTypes = {
  title: PropTypes.string.isRequired,
  songData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      album: PropTypes.string,
      artist: PropTypes.string,
      imageUrl: PropTypes.string,
      origin: PropTypes.string,
      year: PropTypes.number,
      ytLink: PropTypes.string,
      avarageRate: PropTypes.number,
      vocalAvarage: PropTypes.number,
      bitAvarage: PropTypes.number,
      lirycsAvarage: PropTypes.number,
      numberOfRates: PropTypes.number,
    })
  ).isRequired,
};

export default memo(AboutSongStatic);
