import React, { memo } from "react";
import PropTypes from "prop-types";
import CoverPhoto from "../../../common/components/CoverPhoto/CoverPhoto";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import PageTitle from "../../../common/components/PageTitle/PageTitle";
import { Link } from "react-router-dom";
import { List } from "antd";
import MetaData from "../../../common/components/MetaData/MetaData";

const AboutArtist = (props) => {
  const { albums, artist, singles, imageUrl, appearedIn } = props.artistData;
  return (
    <>
      {artist && (
        <MetaData
          title={`${artist} - albumy, single. Rap Krytyk`}
          description={`${artist} - odkrywaj albumy, single. Na rapkrytyk.pl sprawdzaj opinie użytkowników i pnij się się w górę rankingu krytyków. Zdobywaj punkty.`}
        />
      )}
      <PageTitle>{artist}</PageTitle>
      <CenterContent>
        <CoverPhoto imageSrc={imageUrl} />
      </CenterContent>

      <div className="mb-6">
        {albums && (
          <List
            className="about-artist__list"
            size="large"
            header={<div>{`Albumy:`}</div>}
            bordered
            dataSource={albums}
            renderItem={(album) => (
              <List.Item className="about-artist__list__item" actions={[]}>
                <Link
                  to={`/album/${artist
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}_${album
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}`}
                  className="ml-6"
                >
                  {album}
                </Link>
              </List.Item>
            )}
          />
        )}
      </div>

      <div className="mb-6">
        {singles && singles.length > 0 && (
          <List
            className="about-artist__list"
            size="large"
            header={<div>{`Single:`}</div>}
            bordered
            dataSource={singles}
            renderItem={(single) => (
              <List.Item className="about-artist__list__item" actions={[]}>
                <Link
                  to={`/song/${artist
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}_${single
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}_${single
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}`}
                  className="ml-6"
                >
                  {single}
                </Link>
              </List.Item>
            )}
          />
        )}
      </div>
      {appearedIn && appearedIn.length > 0 && (
        <div className="mb-6">
          <List
            className="about-artist__list"
            size="large"
            header={<div>{`Wystąpił w:`}</div>}
            bordered
            dataSource={appearedIn}
            renderItem={(singleAppearience) => (
              <List.Item className="about-artist__list__item" actions={[]}>
                <Link to={`/song/${singleAppearience.id}`} className="ml-6">
                  {singleAppearience.name}
                </Link>
              </List.Item>
            )}
          />
        </div>
      )}
    </>
  );
};

AboutArtist.propTypes = {
  artistData: PropTypes.arrayOf(
    PropTypes.shape({
      albums: PropTypes.array,
      artist: PropTypes.string,
      appearedIn: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          id: PropTypes.string,
        })
      ),
      singles: PropTypes.array,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
};

export default memo(AboutArtist);
