import React, { memo } from "react";
import PropTypes from "prop-types";
import SingleArtistResult from "../SingleArtistResult/SingleArtistResult";
import Position from "../../../SongRanking/components/Position/Position";

const ArtistList = (props) => {
  const { className, artistRankingData, showPosition, query } = props;
  return (
    <>
      <ul className={`artist-list ${className ?? ""}`}>
        {artistRankingData.map((artistData, index) => (
          <>
            {showPosition && <Position position={index + 1} />}
            <SingleArtistResult artistRankingData={artistData} key={artistData.author} query={query} />
          </>
        ))}
      </ul>
    </>
  );
};

ArtistList.propTypes = {
  className: PropTypes.string,
  artistRankingData: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ),
  showPosition: PropTypes.bool,
  query: PropTypes.string,
};

export default memo(ArtistList);
