import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../../common/variables/breakpoints/breakpoints";
import { getRecentlyAdded } from "../../../../Api/songs/getRecentlyAdded";
import SingleRecentlySongSkeleton from "../RecentlyAddedCarousel/SingleRecentlySongSkeleton";
import SingleRecentlySong from "../RecentlyAddedCarousel/SingleRecentlySong";
import { getSingleSong } from "../../../../Api/songs/getSingleSong";

const RecomendedCarousel = (props) => {
  const { className } = props;
  const [songData, setSongData] = useState();
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    const recomendedArray = [
      "pro8l3m_fightclub_geforce",
      "quebonafide_romanticpsycho_-wiattom-jplaczabaw",
      "kazba-agane_variacik_variacik",
      "sarius_antyhype_najshajs",
      "chada_wgw_natychosiedlach2",
      "bia-as_ta-czymyrak-n-roll_ta-czymyrak-n-roll",
      "sobel_pu-apkanamotyle_fio-kowepole",
      "kabe_s-uchajbracie_s-uchajbracie",
      "sarius_zapomnijomnie_zapomnijomnie",
    ];
    // [
    //   "pro8l3m_a2_a2",
    //   "kukonjuliamiku-a_dramat_b-dziemymie-dom-malutkidom",
    //   "lipa_paranoja_bezprawie",
    //   "grubymielzky_doweselasi-zagoi_jakwy-ni-",
    //   "pro8l3m_fightclub_fightclub",
    //   "oio_wmtb_wmtb",
    //   "-abson_ziomalskimixtape_hoodangel",
    //   "kubaknap_namazane_och-jaktobrzmia-o",
    //   "sobel_toja_toja",
    // ];
    // [
    //   "quebonafide_matchalatte_matchalatte",
    //   "pro8l3m_-ar_-ar",
    //   "szpaku_potw-r_potw-r",
    //   "majorspz_bambam_bambam",
    //   "oki_dlaziomaliremix_dlaziomaliremix",
    //   "bedoes_bukietbia-ychr--_bukietbia-ychr--",
    //   "pezet_ambergold_ambergold",
    //   "quebonafide_teenkasia_teenkasia",
    //   "kalimajor_hucpa_puk-puk-",
    // ];
    // [
    //   "quebonafide_teenkasia_teenkasia",
    //   "paluch_nadci-nienie_niema-ycia",
    //   "s-o-_redrum_g--biej",
    //   "kalimajor_hucpa_puk-puk-",
    //   "tacohemingway_europa_luxembourg",
    //   "bedoes_chromehearts_chromehearts",
    //   "quebonafide_romanticpsycho_tokyo2020",
    //   "kukon_jawiemzetywiesz_jawiemzetywiesz",
    //   "guzior_ple--_trapstar",
    // ];
    // [
    //   "kalimajor_hucpa_california",
    //   "s-o-_redrum_czerwonyrum",
    //   "guzior_ple--_blueberry",
    //   "paluch_nadci-nienie_maliludzie",
    //   "gedz_stamina_panamera",
    //   "kukon_krak-w-marzec2020_celebryta",
    //   "-abson_internaziomal_noprowo",
    //   "olszakumpel_kodeksmarki_kodeksmarki",
    //   "oki_77747mixtape_50cent-w",
    // ];
    // [
    //   "kukon_siostro_siostro",
    //   "guzior_ple--_blueberry",
    //   "guzior_ple--_fala",
    //   "paluch_nadci-nienie_kaszel",
    //   "paluch_nadci-nienie_czterykostkilodu",
    //   "gedz_stamina_kamikaze",
    //   "s-o-_wojnatotalna_wojnatotalna",
    //   "oki_77747mixtape_siri",
    //   "sarius_wszystkocoz-e_wiking",
    // ];
    let requests = recomendedArray.map((id) => {
      return getSingleSong(id).then((singleResponse) => {
        return {
          id: singleResponse.id,
          albumIndex: singleResponse.albumIndex,
          name: singleResponse.name,
          author: singleResponse.artist,
          album: singleResponse.album,
          imageUrl: singleResponse.imageUrl,
        };
      });
    });
    Promise.all(requests).then((responses) => {
      setSongData(responses);
    });
  }, []);

  const isSmallDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  useEffect(() => {
    let newTiles = [];

    if (songData) {
      if (isSmallDesktop) {
        for (let i = 0; i < Math.ceil(songData.length / 3); i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              {songData.slice(i * 3, (i + 1) * 3).map((singleSongData) => (
                <SingleRecentlySong songData={singleSongData} />
              ))}
            </div>
          );
        }
      } else {
        newTiles = songData.map((singleSongData) => (
          <div className="recently-added-carousel__item">
            <SingleRecentlySong songData={singleSongData} />
          </div>
        ));
      }
    } else {
      if (isSmallDesktop) {
        for (let i = 0; i < 3; i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              <SingleRecentlySongSkeleton />
              <SingleRecentlySongSkeleton />
              <SingleRecentlySongSkeleton />
            </div>
          );
        }
      } else {
        for (let i = 0; i < 9; i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              <SingleRecentlySongSkeleton />
            </div>
          );
        }
      }
    }

    setTiles(newTiles);
  }, [isSmallDesktop, songData]);

  return (
    <>
      <h2 className="recently-added-header">POLECANE</h2>
      {tiles && (
        <Carousel
          className="recently-added-carousel"
          arrows={isSmallDesktop}
          draggable
          autoplay
          autoplaySpeed={5000}
          speed={isSmallDesktop ? 1000 : 400}
        >
          {tiles}
        </Carousel>
      )}
    </>
  );
};

RecomendedCarousel.propTypes = {
  className: PropTypes.string,
};

export default memo(RecomendedCarousel);
