import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Menu } from "antd";
import { Link, useLocation, useHistory } from "react-router-dom";
import InputField from "../InputField/InputField";
import { SearchOutlined } from "@ant-design/icons";
import PageContainer from "../PageContainer/PageContainer";
import { Form } from "antd";
import { urls } from "../../../Router/urls";
import { HashLink } from "react-router-hash-link";
import AccountBlockPC from "./components/AccountBlock/AccountBlockPC";
import { GAeventSearch } from "../../../GAevents/GAevents";
import { connect } from "react-redux";

const NavigationPC = (props) => {
  const { user } = props;
  const [current, setCurrent] = useState("/");
  const [searchInputFocused, setSearchInputFocused] = useState(false);

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  //left side
  const activeClass = "navigation-active";
  let registerActive = current === urls.register ? activeClass : "";
  let loginActive = current === urls.login ? activeClass : "";

  const [form] = Form.useForm();
  const searchHandler = (value) => {
    if (value.search) {
      history.push("/search" + "?q=" + value.search);
      GAeventSearch(value.search);
    }
    form.resetFields();
  };
  return (
    <nav className="navigation">
      <PageContainer type="flex">
        {/* LEFT */}
        <AccountBlockPC
          setCurrent={setCurrent}
          registerActive={registerActive}
          loginActive={loginActive}
        />

        {/* RIGHT */}
        <Menu
          selectedKeys={[current]}
          mode="horizontal"
          className="navigation__menu"
        >
          <Form
            form={form}
            className={`navigation__search ${
              searchInputFocused ? "focused" : ""
            }`}
            onFinish={searchHandler}
          >
            <Form.Item name="search">
              <InputField
                placeholder="Szukaj..."
                icon={<SearchOutlined />}
                onIconClick={() => searchHandler(form.getFieldsValue())}
                onBlur={() => setSearchInputFocused(false)}
                onFocus={() => setSearchInputFocused(true)}
              />
            </Form.Item>
          </Form>
          <Menu.Item key={urls.homePage}>
            <Link to={urls.homePage}>Główna</Link>
          </Menu.Item>
          <Menu.Item key={urls.articles}>
            <Link to={urls.articles}>Artykuły</Link>
          </Menu.Item>
          <Menu.SubMenu title="Ranking">
            <Menu.Item key={urls.myList}>
              <Link to={user.credentials.email ? urls.myList : urls.login}>
                Moja lista
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.rankingPopular}>
              <Link to={urls.rankingPopular}>Popularności</Link>
            </Menu.Item>
            <Menu.Item key={urls.rankingAlbums}>
              <Link to={urls.rankingAlbums}>Albumów</Link>
            </Menu.Item>
            <Menu.Item key={urls.rankingList}>
              {/* to={urls.rankingList} */}
              <Link>Utworów (wkrótce)</Link>
            </Menu.Item>
            <Menu.Item key={urls.rankingCritics}>
              <Link to={urls.rankingCritics}>Krytyków</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key={urls.points}>
            <HashLink to={urls.points}> Punktacja</HashLink>
          </Menu.Item>
        </Menu>
      </PageContainer>
      <div className="longer-border-bottom"></div>
    </nav>
  );
};

NavigationPC.propTypes = {};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(NavigationPC));
