import axios from "axios";
import { urls } from "../../Router/urls";
import { getIfUserLiked } from "./getIfUserLiked";

export const getReviewsLoggedInUser = (id, currentUserEmail, lastDocId) => {
  return axios
    .get(`${urls.base}/api/get-review-list?id=${id}&lastDocId=${lastDocId}`)
    .then((res) => {
      let requests = res.data.reviews.map((review) => {
        return getIfUserLiked(id, review.email, currentUserEmail).then(
          (likeResponse) => {
            return {
              ...review,
              liked: likeResponse.liked,
            };
          }
        );
      });
      return Promise.all(requests).then((responses) => {
        return responses;
      });
    })
    .catch((error) => {
      return error.response;
    });
};
