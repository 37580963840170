import React, { memo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PointsSection from "./components/PointsSection";
import LeaguesSection from "./components/LeaguesSection";
import MetaData from "../../common/components/MetaData/MetaData";
import PageTitle from "../../common/components/PageTitle/PageTitle";

const PointsPage = (props) => {
  const { className } = props;

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Punktacja i rangi - Rap Krytyk"}
        description="Rap Krytyk - Wyjaśnienie punktacji, zestawienie rang. Za co dostajesz punkty? Dzięki zdobytym punktom możesz awansować do kolejnych rang i rywalizować z innymi w rankingu rap krytyków."
      />
      <PageContainer>
        <PageTitle>Punktacja i rangi</PageTitle>
        <PointsSection />
        <LeaguesSection />
      </PageContainer>
    </section>
  );
};

PointsPage.propTypes = {
  className: PropTypes.string,
};

export default memo(PointsPage);
