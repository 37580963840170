import React, { memo } from "react";
import PropTypes from "prop-types";
import Btn from "../../../common/components/Btn/Btn";
import { Link } from "react-router-dom";
import { SoundOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import sectionImage from "../../../images/hp-section-03.jpg";
import { urls } from "../../../Router/urls";
import Slide from "react-reveal/Slide";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../common/variables/breakpoints/breakpoints";

const YouHaveVote = (props) => {
  const { className } = props;
  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  return (
    <section className={`you-have-vote ${className ?? ""}`}>
      <div className="you-have-vote__left">
        <img
          src={sectionImage}
          alt="Odkrywaj muzykę"
          className="you-have-vote__left__img"
        />
      </div>

      <div className="you-have-vote__right">
        <h2 className="you-have-vote__right__title">MASZ GŁOS!</h2>
        <SoundOutlined className="you-have-vote__right__icon" />
        <Slide right={isDesktop}>
          <p className="you-have-vote__right__text">
            To właśnie Wy -<strong> Rap Krytycy</strong> tworzycie roczne
            rankingi albomów i utworów. <strong> Ocena publiczności</strong>{" "}
            uznawana jest za <strong> najważniejszą </strong> z nagród dla
            artystów.
          </p>
          <Link to={urls.rankingAlbums} className="you-have-vote__right__link">
            <Btn>Ranking albumów 2021</Btn>
          </Link>
        </Slide>
      </div>
    </section>
  );
};

YouHaveVote.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(YouHaveVote));
