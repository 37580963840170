import React, { useState, useEffect } from "react";
import RouterComponent from "./Router/Router";
import { BrowserRouter as Router, Redirect } from "react-router-dom";

//REDUX
import { Provider } from "react-redux";
import store from "./Redux/store";
//COMPONENTS
import Logo from "./common/components/Logo/Logo";
import Navigation from "./common/components/Navigation/Navigation";
import Footer from "./common/components/Footer/Footer";
import ScrollToTop from "./common/components/ScrollToTopRouter/ScrollToTopRouter";
import Notification from "./common/components/Notification/Notification";
import { startWatchingForIdToken } from "./common/functions/loginWithFunction";
import CookieConsent from "./common/components/CookieConsent/CookieConsent";
import { urls } from "./Router/urls";

const App = () => {
  //CHECK BROWSER START
  let ua = navigator.userAgent || navigator.vendor || window.opera;

  function isFacebookApp() {
    return ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;
  }

  function isInstagramApp() {
    return ua.indexOf("Instagram") > -1;
  }

  const wrongBrowser = isInstagramApp() || isFacebookApp();
  if (wrongBrowser)
    alert(
      "Zmień proszę przeglądarkę w prawym górnym rogu. W przeciwnym wypadku nie będziesz w stanie się zalogować."
    );
  //CHECK BROWSER START

  const [cookieAccepted, setCookieAccepted] = useState(false);
  useEffect(() => {
    startWatchingForIdToken();
  }, []);

  useEffect(() => {
    if (localStorage.getItem("cookie-accepted")) {
      setCookieAccepted(true);
    }
  }, [document.cookie]);

  return (
    <>
      <Provider store={store}>
        <Router>
          {/* {wrongBrowser && <Redirect to={urls.externalBrowser} />} */}
          <div className="App">
            <Notification />
            {!cookieAccepted && (
              <CookieConsent setCookieAccepted={setCookieAccepted} />
            )}
            <ScrollToTop />
            <header className="baner">
              <Logo />
            </header>
            <Navigation />
            <RouterComponent />
            <Footer />
          </div>
        </Router>
      </Provider>
    </>
  );
};

export default App;
