import React, { memo } from "react";
import PropTypes from "prop-types";
import CenterContent from "../../../../../common/components/CenterContent/CenterContent";
import { Link } from "react-router-dom";

const UserLikedList = (props) => {
  const { userList, className } = props;
  return (
    <ul className={`${className} pl-0`}>
      {userList.map((userData) => (
        <li className="mb-2">
          <CenterContent>
            <Link to={`/account/${userData.email.toLowerCase()}`}>
              {userData.nickName}
            </Link>
          </CenterContent>
        </li>
      ))}
    </ul>
  );
};

UserLikedList.propTypes = {
  className: PropTypes.string,
  userList: PropTypes.arrayOf(
    PropTypes.shape({
      nickName: PropTypes.string,
      email: PropTypes.string,
    })
  ).isRequired,
};

export default memo(UserLikedList);
