import axios from "axios";
import { urls } from "../../Router/urls";
import { getUserNickName } from "../user/getUserNickName";

export const getUserLikedYourCommentList = (id, currentUserEmail, type) => {
  return axios
    .get(
      `${urls.base}/api/get-user-liked-comment-list?id=${id}&currentUserEmail=${currentUserEmail}&type=${type}`
    )
    .then((res) => {
      let requests = res.data.map((userData) => {
        return getUserNickName(userData.email).then((userObject) => {
          return {
            nickName: userObject.nickName,
            email: userData.email,
          };
        });
      });
      return Promise.all(requests).then((responses) => {
        return responses;
      });
    })
    .catch((error) => {
      return error.response;
    });
};
