import axios from "axios";
import { urls } from "../../../Router/urls";

export const getContentRanking = (content, type, lastId, year) => {
  return axios
    .get(
      `${urls.base}/api/get-content-ranking?content=${content}&type=${type}&last=${lastId}&year=${year}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
