import {
  START_AUTHENTICATION,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_USER_ERRORS,
  ADD_FIVE_FOR_USER,
  ADD_TEN_FOR_USER,
  LEVEL_UP,
  UPDATE_USER_DESCRIPTION,
} from "../types/userActionTypes";

const initialState = {
  authenticated: false,
  loading: false,
  credentials: {},
  errorsBE: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_AUTHENTICATION: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_AUTHENTICATED: {
      return {
        ...state,
        authenticated: true,
      };
    }
    case SET_UNAUTHENTICATED: {
      return initialState;
    }

    case SET_USER: {
      const userData = action.payload;
      return {
        ...state,
        credentials: userData,
        errorsBE: {},
        loading: false,
      };
    }

    case SET_USER_ERRORS: {
      const errors = action.payload;
      return {
        ...state,
        errorsBE: errors,
        loading: false,
      };
    }

    case ADD_FIVE_FOR_USER: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          points: state.credentials.points + 5,
        },
      };
    }

    case ADD_TEN_FOR_USER: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          points: state.credentials.points + 10,
        },
      };
    }

    case LEVEL_UP: {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          rank: state.credentials.rank + 1,
        },
      };
    }

    case UPDATE_USER_DESCRIPTION: {
      const { newDescription } = action.payload;

      return {
        ...state,
        credentials: {
          ...state.credentials,
          description: newDescription,
        },
      };
    }

    default:
      return state;
  }
};
