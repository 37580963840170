import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import TextField from "../../../../common/components/TextField/TextField";
import { postUserDescription } from "../../../../Api/user/postUserDescription";

const Description = (props) => {
  const { description, editModeAvaliable, userEmail, updateReduxState } = props;
  const [editMode, setEditMode] = useState(false);
  const [descriptionCurrent, setDescriptionCurrent] = useState();
  const [error, setError] = useState(false);

  useEffect(() => {
    setDescriptionCurrent(description);
  }, []);

  const validate = (descriptionTrimed) => {
    setDescriptionCurrent(descriptionTrimed);
    return descriptionTrimed.length <= 300;
  };

  const handleEditClick = () => {
    if (editMode === false) {
      setEditMode(true);
    } else {
      const descriptionTrimed = descriptionCurrent.replace(/ +(?= )/g, "");
      if (validate(descriptionTrimed)) {
        postUserDescription(descriptionTrimed, userEmail);
        updateReduxState(descriptionTrimed);
        setEditMode(false);
        setError(false);
      } else setError(true);
    }
  };

  return (
    <>
      {editMode ? (
        <TextField
          placeholder="Twój opis"
          rowSize={3}
          className={"mt-2"}
          value={descriptionCurrent}
          onChange={(e) => setDescriptionCurrent(e.target.value)}
        />
      ) : (
        <div className="profile__main-block__content__description-block mb-4">
          <h3 className="profile__main-block__content__description-block__header">
            Opis:
          </h3>
          <div className="profile__main-block__content__description-block__text">
            {descriptionCurrent}
          </div>
        </div>
      )}
      {editMode && (
        <div className={`mt-2 ${error ? "c-red" : "c-gray"}`}>
          *Opis może zawierać maksymalnie 300 znaków.
        </div>
      )}

      {editModeAvaliable && (
        <CenterContent className="mt-2">
          <Button onClick={handleEditClick}>
            {editMode ? "Zapisz zmiany" : "Edytuj opis"}
          </Button>
        </CenterContent>
      )}
    </>
  );
};

Description.propTypes = {
  description: PropTypes.string,
  editModeAvaliable: PropTypes.bool,
  userEmail: PropTypes.string,
  updateReduxState: PropTypes.func,
};

export default memo(Description);
