import axios from "axios";
import { urls } from "../../Router/urls";
import store from "../../Redux/store";
import { leagues } from "../../common/variables/leagues/leagues";
import { levelUpAction } from "../../Redux/actions/userActions";
import { getUserRank } from "../user/getUserRank";

export const postReview = (reviewData) => {
  const reduxState = store.getState();
  const userEmail = reduxState.user.credentials.email;

  return getUserRank(userEmail)
    .then((data) => {
      const userPoints = data.points;
      const userRank = data.rank;

      const minPointsInNextLeague = leagues[userRank + 1].minPoints;
      const newLvl = userPoints + 10 >= minPointsInNextLeague;

      const objectForPost = {
        ...reviewData,
        newLvl,
      };
      return axios
        .post(`${urls.base}/api/send-review`, objectForPost)
        .then((res) => {
          if (newLvl) {
            store.dispatch(levelUpAction());
          }
          return res.data.data;
        })
        .catch((error) => {
          console.log(error);
          return error.response;
        });
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};
