import React, { memo, useState, useRef, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Menu } from "antd";
import { useOnClickOutside } from "../../../common/hooks/useOnClickOutside";
import InputField from "../InputField/InputField";
import { SearchOutlined, MenuOutlined } from "@ant-design/icons";
import Btn from "../Btn/Btn";
import { HashLink } from "react-router-hash-link";
import { urls } from "../../../Router/urls";
import { Form } from "antd";
import AccountBlockPhone from "./components/AccountBlock/AccountBlockPhone";
import { GAeventSearch } from "../../../GAevents/GAevents";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";

const NavigationPhone = (props) => {
  const { user } = props;
  const [current, setCurrent] = useState("/");
  const [state, setState] = useState("none"); //none, menu, search

  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    setCurrent(location.pathname);
    setState("none");
  }, [location]);

  const activeClass = "navigation-mobile-active";
  let registerActive = current === urls.register ? activeClass : "";
  let loginActive = current === urls.login ? activeClass : "";

  const [form] = Form.useForm();
  const searchHandler = (value) => {
    if (value.search) {
      history.push("/search" + "?q=" + value.search);
      GAeventSearch(value.search);
    }
    form.resetFields();
  };

  const onBurgerIconClickHandler = () => setState("menu");
  const onCloseHandler = () => setState("none");

  return (
    <>
      {state === "none" && (
        <>
          <Btn className="burger" onClick={onBurgerIconClickHandler}>
            <MenuOutlined />
          </Btn>
        </>
      )}
      {
        <div className="navigation-mobile__search">
          <Form form={form} onFinish={searchHandler}>
            <Form.Item name="search">
              <InputField
                placeholder="Szukaj..."
                icon={<SearchOutlined />}
                onIconClick={() => searchHandler(form.getFieldsValue())}
              />
            </Form.Item>
          </Form>
        </div>
      }
      {state === "menu" && (
        <nav className="navigation-mobile">
          <CloseOutlined
            onClick={onCloseHandler}
            className="navigation-mobile__close"
          />
          <Menu
            selectedKeys={[current]}
            mode="inline"
            className="navigation-mobile__menu"
          >
            <Menu.Item key={urls.homePage}>
              <Link to={urls.homePage}>Główna</Link>
            </Menu.Item>
            <Menu.Item key={urls.articles}>
              <Link to={urls.articles}>Artykuły</Link>
            </Menu.Item>
            <Menu.SubMenu title="Ranking">
              <Menu.Item key={urls.myList}>
                <Link to={user.credentials.email ? urls.myList : urls.login}>
                  Moja lista
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.rankingPopular}>
                <Link to={urls.rankingPopular}>Popularności</Link>
              </Menu.Item>
              <Menu.Item key={urls.rankingAlbums}>
                <Link to={urls.rankingAlbums}>Albumów</Link>
              </Menu.Item>
              <Menu.Item key={urls.rankingList}>
                {/* to={urls.rankingList} */}
                <Link>Utworów (wkrótce)</Link>
              </Menu.Item>
              <Menu.Item key={urls.rankingCritics}>
                <Link to={urls.rankingCritics}>Krytyków</Link>
              </Menu.Item>
            </Menu.SubMenu>
            <Menu.Item key={urls.points}>
              <HashLink to={urls.points}>Punktacja</HashLink>
            </Menu.Item>
          </Menu>
          <AccountBlockPhone
            setCurrent={setCurrent}
            registerActive={registerActive}
            loginActive={loginActive}
          />
        </nav>
      )}
    </>
  );
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(NavigationPhone));
