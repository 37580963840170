import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Tile from "../../../../common/components/Tile/Tile";
import Btn from "../../../../common/components/Btn/Btn";
import { StarFilled } from "@ant-design/icons";

const SingleRecentlySong = (props) => {
  const {
    albumIndex,
    id,
    name,
    author,
    album,
    imageUrl,
    additionalArtists,
  } = props.songData;

  return (
    <Link to={`/song/${id}`} className="recently-added-carousel__item__tile">
      <Tile>
        <div className="recently-added-carousel__item__tile__content">
          <div className="recently-added-carousel__item__tile__content__frame">
            <img
              className="recently-added-carousel__item__tile__content__frame__img"
              src={imageUrl}
              alt="okładka płyty"
            />
          </div>
          <div className="recently-added-carousel__item__tile__content__description">
            <h2 className="recently-added-carousel__item__tile__content__description__title">
              {name}
            </h2>
            <div className="recently-added-carousel__item__tile__content__description__property">
              <span className="recently-added-carousel__item__tile__content__description__property__label">
                Wykonawca:
              </span>
              <span>{author}</span>
            </div>
            {additionalArtists && additionalArtists.length > 0 && (
              <div className="recently-added-carousel__item__tile__content__description__property">
                <span className="recently-added-carousel__item__tile__content__description__property__label">
                  Feat:
                </span>
                <span>{additionalArtists.join(", ")}</span>
              </div>
            )}
            <div className="recently-added-carousel__item__tile__content__description__property">
              {albumIndex ? (
                <>
                  <span className="recently-added-carousel__item__tile__content__description__property__label">
                    Album:
                  </span>
                  <span>{album}</span>
                </>
              ) : (
                <span className="recently-added-carousel__item__tile__content__description__property__label">
                  Singiel
                </span>
              )}
            </div>
          </div>
          <Btn>
            <StarFilled />
            Oceń utwór
          </Btn>
        </div>
      </Tile>
    </Link>
  );
};

SingleRecentlySong.propTypes = {
  songData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    author: PropTypes.string,
    album: PropTypes.string,
    imageUrl: PropTypes.string,
    albumIndex: PropTypes.number,
  }),
};

export default memo(SingleRecentlySong);
