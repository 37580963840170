import React, { memo } from "react";
import PropTypes from "prop-types";
import SingleResult from "../SingleResult/SingleResult";
import Position from "../Position/Position";

const ResultList = (props) => {
  const { className, songRankingData, showPosition, query } = props;
  return (
    <>
      <ul className={`result-list ${className ?? ""}`}>
        {songRankingData.map((songData, index) => (
          <>
            {showPosition && <Position position={index + 1} />}
            <SingleResult
              songData={songData}
              key={songData.key}
              query={query}
            />
          </>
        ))}
      </ul>
    </>
  );
};

ResultList.propTypes = {
  className: PropTypes.string,
  songRankingData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      author: PropTypes.string,
      album: PropTypes.string,
      releaseDate: PropTypes.string,
    })
  ),
  showPosition: PropTypes.bool,
  query: PropTypes.string,
};

export default memo(ResultList);
