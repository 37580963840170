import React, { memo, useState, useEffect } from "react";
import TextField from "../../../../common/components/TextField/TextField";
import Btn from "../../../../common/components/Btn/Btn";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { Form } from "antd";
import { postReview } from "../../../../Api/reviews/postReview";
import { useLocation, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { getSingleReview } from "../../../../Api/reviews/getSingleReview";
import { updateReview } from "../../../../Api/reviews/updateReview";
import Spinner from "../../../../common/components/Spinner/Spinner";
import { addTenForUser } from "../../../../Redux/actions/userActions";
import { getUserLikedYourReviewList } from "../../../../Api/reviews/getUserLikedYourReviewList";
import UserLikedList from "./UserLikedList/UserLikedList";
import { urls } from "../../../../Router/urls";

const ReviewSong = (props) => {
  const { userAuthentiacated, userCredentials, addTenForUser } = props;
  const location = useLocation();
  const history = useHistory();
  const [form] = Form.useForm();
  const [reviewDescriptionColor, setReviewDescriptionColor] = useState(
    "c-gray"
  );
  const idFromUrl = location.pathname.split("/")[2];
  const [alreadyReviewed, setAlreadyReviewed] = useState(false);
  const [clickedEdit, setClickedEdit] = useState(false);
  const [numberOfLikes, setNumberOfLikes] = useState();
  const [loading, setLoading] = useState(false);
  const [usersLikedLoading, setUsersLikedLoading] = useState(false);
  const [usersLikedList, setUsersLikedList] = useState(false);

  useEffect(() => {
    if (userAuthentiacated && userCredentials.email) {
      getSingleReview(idFromUrl, userCredentials.email).then((data) => {
        if (data.singleReview) {
          setAlreadyReviewed(true);
          setNumberOfLikes(data.singleReview.likes);
          form.setFieldsValue({ review: data.singleReview.reviewText });
        }
      });
    }
  }, [userAuthentiacated, userCredentials]);

  const onFinish = (data) => {
    if (userCredentials.email) {
      setLoading(true);
      setReviewDescriptionColor("c-gray");
      if (alreadyReviewed) {
        updateReview({
          email: userCredentials.email,
          id: idFromUrl,
          reviewText: data.review,
        }).then(() => {
          setLoading(false);
          window.location.reload();
        });
      } else {
        postReview({
          email: userCredentials.email,
          nickName: userCredentials.nickName,
          id: idFromUrl,
          reviewText: data.review,
        }).then(() => {
          setLoading(false);
          addTenForUser();
          setAlreadyReviewed(true);
        });
      }
    } else {
      history.push(urls.login);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (
      errorInfo.errorFields.find((errorField) =>
        errorField.name.includes("review")
      )
    )
      setReviewDescriptionColor("c-red");
  };

  const checkWhoHandler = () => {
    setUsersLikedLoading(true);
    getUserLikedYourReviewList(idFromUrl, userCredentials.email)
      .then((res) => {
        setUsersLikedLoading(false);
        setUsersLikedList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {alreadyReviewed && !clickedEdit ? (
        <div className="alredy-reviewed-baner">
          <h2>Dziękujemy za dodaną recenzję.</h2>
          <Btn className="mt-2 mb-10" onClick={() => setClickedEdit(true)}>
            Edytuj swoją recenzję
          </Btn>
          <h2>
            {numberOfLikes > 0 && (
              <span>
                <strong className="c-yellow">{numberOfLikes}</strong>
                <span>
                  {" "}
                  użytkowników uważa, że twoja recenzja jest świetna!
                </span>
              </span>
            )}
          </h2>
          {numberOfLikes > 0 && !usersLikedLoading && !usersLikedList && (
            <Btn className="mt-2 mb-4" onClick={checkWhoHandler}>
              Sprawdź kto!
            </Btn>
          )}
          {usersLikedLoading && <Spinner />}
          {usersLikedList.length > 0 && !usersLikedLoading && (
            <UserLikedList userList={usersLikedList} className="mt-2 mb-2" />
          )}
        </div>
      ) : (
        <>
          <h2>Twoja recenzja</h2>
          <Form
            form={form}
            className="review-song mb-6"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className={`pb-2 ${reviewDescriptionColor}`}>
              *Powinna zawierać minimum 25 znaków
            </div>
            <Form.Item
              name="review"
              rules={[
                { min: 25, message: " " },
                { required: true, message: " " },
              ]}
              validateTrigger="onSubmit"
            >
              <TextField
                placeholder="Napisz swoją recenzję utworu"
                className="mb-4"
                rowSize={3}
              />
            </Form.Item>
            <Form.Item>
              <CenterContent>
                {loading ? (
                  <Spinner />
                ) : (
                  <Btn type="primary" htmlType="submit">
                    {alreadyReviewed
                      ? "Edytuj swoją recenzję"
                      : "Prześlij rezenzję"}
                  </Btn>
                )}
              </CenterContent>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

ReviewSong.propTypes = {};

const mapStatetoProps = (state) => ({
  userAuthentiacated: state.user.authenticated,
  userCredentials: state.user.credentials,
});

const mapDispatchToProps = {
  addTenForUser,
};

export default memo(connect(mapStatetoProps, mapDispatchToProps)(ReviewSong));
