import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import { getArticles } from "../../../Api/articles/getArticles";
import { useLocation } from "react-router-dom";
import ArticleTile from "./ArticleTile";
import ArticleTileSkeleton from "./ArticleTileSkeleton";
import CenterContent from "../../../common/components/CenterContent/CenterContent";

const ArticlesContainer = () => {
  const location = useLocation();

  const [results, _setResults] = useState();
  const [lastSongId, _setLastSongId] = useState("");
  const [lastSentQuerySongId, _setLastSentQuerySongId] = useState("");
  const [loading, _setLoading] = useState(true);
  const [endOfResults, _setEndOfResults] = useState(false);

  const resultsRef = useRef(results);
  const setResults = (data) => {
    resultsRef.current = data;
    _setResults(data);
  };

  const lastSongIdRef = useRef(lastSongId);
  const setLastSongId = (data) => {
    lastSongIdRef.current = data;
    _setLastSongId(data);
  };

  const lastSentQuerySongIdRef = useRef(lastSentQuerySongId);
  const setLastSentQuerySongId = (data) => {
    lastSentQuerySongIdRef.current = data;
    _setLastSentQuerySongId(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  };

  const endOfResultsRef = useRef(endOfResults);
  const setEndOfResults = (data) => {
    endOfResultsRef.current = data;
    _setEndOfResults(data);
  };

  const lazyLoad = useCallback(() => {
    const scrollNearTheBottom =
      document.documentElement.scrollHeight - window.innerHeight - 100 <=
      Math.trunc(window.scrollY);
    if (
      scrollNearTheBottom &&
      lastSentQuerySongIdRef.current !== lastSongIdRef.current &&
      !loadingRef.current
    ) {
      setLoading(true);
      getArticles(lastSongIdRef.current)
        .then((res) => {
          if (res.length > 0) {
            const newResults = res;
            setLastSentQuerySongId(lastSongIdRef.current);
            setResults([...resultsRef.current, ...newResults]);
            setLoading(false);
          } else {
            setLastSentQuerySongId(lastSongIdRef.current);
            setEndOfResults(true);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", lazyLoad);

    return () => {
      window.removeEventListener("scroll", lazyLoad);
    };
  }, [location]);

  //LOAD CONTENT ON MOUNT
  useEffect(() => {
    setResults([]);
    setLoading(true);
    setEndOfResults(false);

    setLastSentQuerySongId(undefined);
    getArticles()
      .then((res) => {
        setResults(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    if (results !== undefined && results.length > 0) {
      setLastSongId(results[results.length - 1].id);
    }
  }, [results]);

  const skeletonList = [1, 2];
  return (
    <section>
      {results &&
        results.map((article) => <ArticleTile articleData={article} />)}
      {loading && skeletonList.map(() => <ArticleTileSkeleton />)}
      {endOfResults && <CenterContent>Koniec wyników</CenterContent>}
    </section>
  );
};

export default memo(ArticlesContainer);
