import React, { memo } from "react";
import Tile from "../../../Tile/Tile";
import { StarFilled } from "@ant-design/icons";

const SingleResultSkeleton = (props) => {
  const { showPosition } = props;
  const showPositionClass = showPosition ? "showPosition" : "";
  return (
    <li className={"single-result-skeleton " + showPositionClass}>
      <Tile>
        <div className="single-result-skeleton__content">
          <div className="single-result-skeleton__content__frame"></div>
          <div className="single-result-skeleton__content__description">
            <h2 className="single-result-skeleton__content__description__title skeleton"></h2>
            <div className="single-result-skeleton__content__description__property skeleton"></div>
            <div className="single-result-skeleton__content__description__property skeleton shorter"></div>
            <div className="single-result-skeleton__content__description__property skeleton"></div>
          </div>
          <div className="single-result-skeleton__content__rate">
            <div className="single-result-skeleton__content__rate__block">
              <StarFilled
                className={"single-result-skeleton__content__rate__block__star"}
              />
            </div>
            <div className="single-result-skeleton__content__rate__votes"></div>
          </div>
        </div>
      </Tile>
    </li>
  );
};

export default memo(SingleResultSkeleton);
