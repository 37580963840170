import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  CloseCircleOutlined,
  WarningOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const Message = (props) => {
  const { children, className, type } = props;
  const icon =
    type === "error" ? (
      <CloseCircleOutlined />
    ) : type === "warn" ? (
      <WarningOutlined />
    ) : (
      <InfoCircleOutlined />
    );
  return (
    <div className={`message--${type} ${className ?? ""}`}>
      {icon} {children}
    </div>
  );
};

Message.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  type: PropTypes.oneOf(["error", "warning", "info"]).isRequired,
};

export default memo(Message);
