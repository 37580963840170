import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { ArrowDownOutlined } from "@ant-design/icons";

const SongLyricsDisplay = (props) => {
  const { parts, numberOfWords } = props;

  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`lyrics-display mt-6`}>
      <h2 className="lyrics-display__title">Słowa utworu</h2>
      {numberOfWords && (
        <h4 className="lyrics-display__words-number">
          Ilość słów: {numberOfWords}
        </h4>
      )}
      <div
        className={`lyrics-display__external-html ${
          expanded ? "expanded" : ""
        }`}
      >
        {parts &&
          parts.map((part) => (
            <div
              dangerouslySetInnerHTML={{ __html: part }}
              className="lyrics-display__external-html__block"
            />
          ))}
      </div>

      {!expanded && (
        <CenterContent
          className="lyrics-display__show-more"
          onClick={() => setExpanded(true)}
        >
          {"pokaż cały"}
          <ArrowDownOutlined className="ml-1" />
        </CenterContent>
      )}
    </div>
  );
};

SongLyricsDisplay.propTypes = {
  parts: PropTypes.arrayOf(PropTypes.string),
  numberOfWords: PropTypes.number,
};

export default memo(SongLyricsDisplay);
