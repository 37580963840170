import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Tile from "../../../../common/components/Tile/Tile";
import Btn from "../../../../common/components/Btn/Btn";
import { StarFilled } from "@ant-design/icons";

const SingleRecentlyAlbum = (props) => {
  const { id, name, author, imageUrl } = props.albumData;

  return (
    <Link to={`/album/${id}`} className="recently-added-carousel__item__tile">
      <Tile>
        <div className="recently-added-carousel__item__tile__content">
          <div className="recently-added-carousel__item__tile__content__frame">
            <img
              className="recently-added-carousel__item__tile__content__frame__img"
              src={imageUrl}
              alt="okładka płyty"
            />
          </div>
          <div className="recently-added-carousel__item__tile__content__description">
            <h2 className="recently-added-carousel__item__tile__content__description__title">
              {name}
            </h2>
            <div className="recently-added-carousel__item__tile__content__description__property">
              <span className="recently-added-carousel__item__tile__content__description__property__label">
                Wykonawca:
              </span>
              <span>{author}</span>
            </div>
          </div>
          <Btn>
            <StarFilled />
            Oceń album
          </Btn>
        </div>
      </Tile>
    </Link>
  );
};

SingleRecentlyAlbum.propTypes = {
  albumData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    author: PropTypes.string,
    imageUrl: PropTypes.string,
  }),
};

export default memo(SingleRecentlyAlbum);
