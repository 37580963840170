import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { urls } from "../../../Router/urls";

const PointsSection = (props) => {
  const { className } = props;

  return (
    <section autoplay className="points-section" id="points-section">
      <h2>PUNKTACJA</h2>
      <div className="points-section__main">
        <p className="mb-2 mt-2">
          Dzięki zdobytym punktom możesz <strong> awansować </strong> do
          kolejnych rangi i <strong> rywalizować </strong> z innymi w
          <Link to={urls.rankingCritics}>rankingu</Link> rap krytyków.
        </p>
        <p>Punkty dostajesz za:</p>
        <ul className="points-section__main__list">
          <li className="points-section__main__item">
            Dodanie <strong>opinii</strong>.
            <span className="points-section__main__list__item__points">
              <PlusOutlined /> 5pkt.
            </span>
          </li>
          <li>
            Dodanie <strong>recenzji</strong>.
            <span className="points-section__main__list__item__points">
              <PlusOutlined /> 10pkt.
            </span>
          </li>
          <li>
            Dodanie <strong>singla</strong> lub <strong>albumu</strong>.
            <span className="points-section__main__list__item__points">
              <PlusOutlined /> 20pkt.
            </span>
          </li>
        </ul>
      </div>
    </section>
  );
};

PointsSection.propTypes = {
  className: PropTypes.string,
};

export default memo(PointsSection);
