import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "../../../../common/components/Tile/Tile";
import { Link } from "react-router-dom";
import Number from "../../../../common/components/Number/Number";
import { StarFilled } from "@ant-design/icons";

const SingleRateCard = (props) => {
  const { className } = props;
  const {
    id,
    name,
    sound,
    cohesion,
    bit,
    lirycs,
    vocal,
    overall,
  } = props.rateData;

  return (
    <>
      {id.split("_").length > 2 ? (
        <Tile className={`rate-tabs__single mb-6 ${className}`}>
          <Link to={`/song/${id}`}>
            <div className="rate-tabs__single__left">
              <h2 className="rate-tabs__single__left__title">Utwór: {name}</h2>
            </div>
            <div className="rate-tabs__single__right">
              <div className="rate-tabs__single__right__rate--normal">
                <div className="rate-tabs__single__right__rate--normal__label">
                  Bit
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--normal__value">
                    {bit}
                  </Number>
                </div>
              </div>
              <div className="rate-tabs__single__right__rate--normal">
                <div className="rate-tabs__single__right__rate--normal__label">
                  Słowa
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--normal__value">
                    {lirycs}
                  </Number>
                </div>
              </div>
              <div className="rate-tabs__single__right__rate--normal">
                <div className="rate-tabs__single__right__rate--normal__label">
                  Wokal
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--normal__value">
                    {vocal}
                  </Number>
                </div>
              </div>
              <div className="rate-tabs__single__right__rate--main">
                <div className="rate-tabs__single__right__rate--main__label">
                  Średnia
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--main__value">
                    {overall}
                    <StarFilled />
                  </Number>
                </div>
              </div>
            </div>
          </Link>
        </Tile>
      ) : (
        <Tile className={`rate-tabs__single mb-6 ${className}`}>
          <Link to={`/album/${id}`}>
            <div className="rate-tabs__single__left">
              <h2 className="rate-tabs__single__left__title">Album: {name}</h2>
            </div>
            <div className="rate-tabs__single__right">
              <div className="rate-tabs__single__right__rate--normal">
                <div className="rate-tabs__single__right__rate--normal__label">
                  Brzmienie
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--normal__value">
                    {sound}
                  </Number>
                </div>
              </div>
              <div className="rate-tabs__single__right__rate--normal">
                <div className="rate-tabs__single__right__rate--normal__label">
                  Słowa
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--normal__value">
                    {lirycs}
                  </Number>
                </div>
              </div>
              <div className="rate-tabs__single__right__rate--normal">
                <div className="rate-tabs__single__right__rate--normal__label">
                  Spójność
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--normal__value">
                    {cohesion}
                  </Number>
                </div>
              </div>
              <div className="rate-tabs__single__right__rate--main">
                <div className="rate-tabs__single__right__rate--main__label">
                  Średnia
                </div>
                <div>
                  <Number className="rate-tabs__single__right__rate--main__value">
                    {overall}
                    <StarFilled />
                  </Number>
                </div>
              </div>
            </div>
          </Link>
        </Tile>
      )}
    </>
  );
};

SingleRateCard.propTypes = {
  className: PropTypes.string,
  rateData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    cohesion: PropTypes.number,
    overall: PropTypes.number,
    sound: PropTypes.number,
    bit: PropTypes.number,
    lirycs: PropTypes.number,
    type: PropTypes.string,
    vocal: PropTypes.number,
  }),
};

export default memo(SingleRateCard);
