import axios from "axios";
import { urls } from "../../Router/urls";

export const rejectRequest = (requestId, rejectionReason, photoExtension) => {
  const objectForPut = { id: requestId, rejectionReason: rejectionReason };
  return axios
    .put(`${urls.base}/api/request-reject`, objectForPut)
    .then((rejectionResponse) => {
      console.log(rejectionResponse);
      return axios
        .delete(`${urls.base}/api/image-delete`, {
          params: { id: requestId, extension: photoExtension },
        })
        .then((res) => res)
        .catch((err) => console.log(err));
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};
