import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from "../types/notificationActionTypes";

const initialState = {
  title: "",
  message: "",
  icon: "",
  duration: 0,
  link: "",
  show: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION: {
      const { title, message, imgUrl, duration, link, icon } = action.payload;
      return {
        title,
        message,
        imgUrl,
        duration,
        icon,
        link,
        show: true,
      };
    }

    case HIDE_NOTIFICATION: {
      return {
        ...state,
        show: false,
      };
    }

    default:
      return state;
  }
};
