import React, { memo, useState, useEffect } from "react";
import Spinner from "../../../common/components/Spinner/Spinner";
import { getAllSongRequests } from "../../api/getAllSongRequests";
import BOsongRequestsItem from "./components/BOsongRequestsItem";
import { acceptSong } from "../../api/acceptSong";
import { acceptAlbum } from "../../api/acceptAlbum";
import { rejectRequest } from "../../api/rejectRequest";
import { useHistory } from "react-router-dom";
import { urls } from "../../../Router/urls";
import { connect } from "react-redux";

const BOsongRequestPage = (props) => {
  const { user } = props;
  const [requests, setRequests] = useState();
  const history = useHistory();

  useEffect(() => {
    if (user.credentials.type) {
      getAllSongRequests().then((res) => {
        setRequests(res);
      });
    }
  }, [user]);

  useEffect(() => {
    if (
      user.credentials.type !== "admin" &&
      user.credentials.type !== undefined
    ) {
      history.push(urls.homePage);
    }
  }, [user.credentials.type]);

  const addSingiel = (itemId) => {
    const itemToSend = requests.find((req) => req.id === itemId);
    const objectToSend = {
      artist: itemToSend.artist,
      additionalArtists: itemToSend.additionalArtists,
      name: itemToSend.name,
      origin: itemToSend.origin,
      year: itemToSend.year,
      ytLink: itemToSend.ytLink,
      photoExtension: itemToSend.photoExtension,
    };
    acceptSong(objectToSend).then((res) => {
      console.log(res);
      const newRequests = requests.filter((req) => req.id !== itemId);
      setRequests(newRequests);
    });
  };

  const addAlbum = (albumId) => {
    const itemToSend = requests.find((req) => req.id === albumId);
    const objectToSend = {
      artist: itemToSend.artist,
      album: itemToSend.name,
      origin: itemToSend.origin,
      year: itemToSend.year,
      ytLink: itemToSend.ytLink,
      photoExtension: itemToSend.photoExtension,
      songList: itemToSend.songList,
    };
    acceptAlbum(objectToSend).then((res) => {
      const newRequests = requests.filter((req) => req.id !== albumId);
      setRequests(newRequests);
    });
  };

  const reject = (reqId, rejectionReason, photoExtension) => {
    rejectRequest(reqId, rejectionReason, photoExtension).then((res) => {
      const newRequests = requests.filter((req) => req.id !== reqId);
      setRequests(newRequests);
    });
  };

  return (
    <section className="bo__song-req">
      {requests ? (
        requests.map((request) => (
          <BOsongRequestsItem
            request={request}
            addSingiel={addSingiel}
            addAlbum={addAlbum}
            reject={reject}
          />
        ))
      ) : (
        <Spinner />
      )}
    </section>
  );
};

BOsongRequestPage.propTypes = {};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(BOsongRequestPage));
