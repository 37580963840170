import React, { memo } from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

const Btn = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Button className={`btn ${className ?? ""}`} type="primary" {...rest}>
      {children}
    </Button>
  );
};

Btn.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default memo(Btn);
