import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import InputField from "../../common/components/InputField/InputField";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import Btn from "../../common/components/Btn/Btn";
import { Form } from "antd";
import CenterContent from "../../common/components/CenterContent/CenterContent";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  GoogleCircleFilled,
  FacebookFilled,
} from "@ant-design/icons";
import RegisterAdvantageBaner from "../RegisterPage/components/RegisterAdvantageBaner/RegisterAdvantageBaner";
import { urls } from "../../Router/urls";
import { Link } from "react-router-dom";
import Message from "../../common/components/Message/Message";
import { connect } from "react-redux";
import {
  loginWithGoogleAction,
  loginWithFacebookAction,
  loginUserAction,
} from "../../Redux/actions/userActions";
import Spinner from "../../common/components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import MetaData from "../../common/components/MetaData/MetaData";

const LoginPage = (props) => {
  const {
    className,
    user,
    loginUserAction,
    loginWithGoogleAction,
    loginWithFacebookAction,
  } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [generalError, setGeneralError] = useState("");

  const loginHandler = (loginData) => {
    loginUserAction(loginData);
  };

  useEffect(() => {
    if (user.authenticated && user.loading === false && user.credentials.email)
      history.push("/");
  }, [user.credentials]);

  useEffect(() => {
    if (user.errorsBE.general) setGeneralError(user.errorsBE.general);
    else setGeneralError("");
  }, [user.errorsBE]);

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Logowanie - Rap Krytyk"}
        description="Zaloguj się, aby mieć możliwość dodawania ocen, recenzji i utworów. Rap Krytyk to dobra zabawa! Odkrywaj playlisty, zdobywaj punkty by stać się najlepszym rapkrytykiem."
      />
      <PageContainer className={"login-page"}>
        <PageTitle>Logowanie</PageTitle>

        <Form form={form} className="login-page__login" onFinish={loginHandler}>
          <Form.Item
            name="email"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField label="Email*" className="mb-4" type="email" required />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField
              label="Hasło*"
              type={showPassword ? "text" : "password"}
              icon={showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
              onIconClick={() => setShowPassword((prev) => !prev)}
            />
          </Form.Item>
          {user.loading ? (
            <Spinner />
          ) : (
            <>
              <Form.Item>
                <div className="mt-6">
                  {generalError !== "" && (
                    <CenterContent className="mb-4">
                      <Message type="error">
                        <div>{generalError}</div>
                      </Message>
                    </CenterContent>
                  )}
                  <CenterContent>
                    <Btn
                      type="primary"
                      htmlType="submit"
                      className="login-page__login__submit"
                    >
                      Zaloguj
                    </Btn>
                  </CenterContent>
                </div>
              </Form.Item>
              <CenterContent className="mt-4">
                <Btn
                  type="primary"
                  className="login-page__login__submit"
                  onClick={loginWithGoogleAction}
                >
                  <GoogleCircleFilled />
                  Zaloguj przez Google
                </Btn>
              </CenterContent>
              <CenterContent className="mt-4">
                <Btn
                  type="primary"
                  className="login-page__login__submit"
                  onClick={loginWithFacebookAction}
                >
                  <FacebookFilled />
                  Zaloguj przez Facebook
                </Btn>
              </CenterContent>
              <CenterContent className="mt-3">
                <Link to={urls.forgotPassword}>Nie pamietasz hasła?</Link>
              </CenterContent>
            </>
          )}
        </Form>
        <div className="login-page__register-block">
          Nie posiadasz jeszcze konta?
          <Link to={urls.register}>Zarejestruj się!</Link>
        </div>
        <RegisterAdvantageBaner />
      </PageContainer>
    </section>
  );
};

LoginPage.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  loginUserAction,
  loginWithGoogleAction,
  loginWithFacebookAction,
};

export default memo(connect(mapStatetoProps, mapDispatchToProps)(LoginPage));
