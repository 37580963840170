import React, { memo } from "react";
import PropTypes from "prop-types";
import {
  PlusOutlined,
  BarChartOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";

const RegisterAdvantageBaner = (props) => {
  const { children, className } = props;
  return (
    <div className={`registration-advantage-baner ${className ?? ""}`}>
      <div className="registration-advantage-baner__item">
        <OrderedListOutlined />
        <h2>Odkrywaj playlisty</h2>
        <p>utworzone na bazie rankingów.</p>
      </div>
      <div className="registration-advantage-baner__item">
        <BarChartOutlined />
        <h2>Zdobywaj punkty</h2>
        <p>Kalsyfikuj się w lidze i rywalizuj.</p>
      </div>
      <div className="registration-advantage-baner__item">
        <PlusOutlined />
        <h2>Dodawaj utwory</h2>
        <p>Jeśli nie ma utwory, który poszukjesz możesz go dodać.</p>
      </div>
    </div>
  );
};

RegisterAdvantageBaner.propTypes = {
  className: PropTypes.string,
};

export default memo(RegisterAdvantageBaner);
