import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserRates } from "../../../../Api/rate/getUserRates";
import Tile from "../../../../common/components/Tile/Tile";
import SingleRateCard from "./SingleRateCard";
import SingleRateCardSkeleton from "./SingleRateCardSkeleton";
import NoResultsSearch from "../../../../common/components/NoResultsSearch/NoResultsSearch";

const RatesTab = (props) => {
  const { className, user } = props;
  const [rateData, setRateData] = useState();
  const [loading, setLoading] = useState();

  useEffect(() => {
    if (user.credentials.email) {
      setLoading(true);
      getUserRates(user.credentials.email).then((res) => {
        setRateData(res.reverse());
        setLoading(false);
      });
    }
  }, [user]);

  const arrayOfSkeletons = [1, 2, 3];

  return (
    <section className={`${className ?? "rate-tabs"}`}>
      {loading ? (
        arrayOfSkeletons.map((skeleton) => <SingleRateCardSkeleton />)
      ) : (
        <>
          {rateData &&
            rateData.map((rate) => <SingleRateCard rateData={rate} />)}
        </>
      )}
      {!loading && rateData && rateData.length == 0 && (
        <NoResultsSearch
          hideButton={true}
          diffText={"Jeszcze nie dodałeś żadnej oceny"}
        />
      )}
    </section>
  );
};

RatesTab.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(RatesTab));
