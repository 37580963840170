import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { getArticles } from "../../../../Api/articles/getArticles";
import { useLocation } from "react-router-dom";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { ArrowDownOutlined } from "@ant-design/icons";
import SingleRecomendedSelectorTile from "./SingleRecomendedSelectorTile";

const RecomendedSelector = (props) => {
  const { recomendedList, updateRecomendedList, error } = props;
  const location = useLocation();

  const [results, setResults] = useState();
  const [lastId, setLastId] = useState("");
  const [lastSentQueryId, setLastSentQueryId] = useState("");
  const [loading, setLoading] = useState(true);
  const [endOfResults, setEndOfResults] = useState(false);

  const loadMore = () => {
    if (lastSentQueryId !== lastId && !loading) {
      setLoading(true);
      getArticles(lastId)
        .then((res) => {
          if (res.length > 0) {
            const newResults = res;
            setLastSentQueryId(lastId);
            setResults([...results, ...newResults]);
            setLoading(false);
          } else {
            setLastSentQueryId(lastId);
            setEndOfResults(true);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  //LOAD CONTENT ON MOUNT
  useEffect(() => {
    setResults([]);
    setLoading(true);
    setEndOfResults(false);

    setLastSentQueryId(undefined);
    getArticles()
      .then((res) => {
        setResults(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  }, []);

  useEffect(() => {
    if (results !== undefined && results.length > 0) {
      setLastId(results[results.length - 1].id);
    }
  }, [results]);

  const selectHandler = (id) => {
    //delete on second click
    if (recomendedList.includes(id)) {
      const newArray = [...recomendedList.filter((itemId) => itemId !== id)];
      updateRecomendedList(newArray);
    }

    //add to list
    else if (recomendedList.length < 3) {
      const newArray = [...recomendedList, id];
      updateRecomendedList(newArray);
    }
  };

  return (
    <div className={`recomended-selecetor ${error ? `error` : ``}`}>
      {results &&
        results.map((article) => (
          <SingleRecomendedSelectorTile
            articleData={article}
            selectedList={recomendedList}
            selectHandler={selectHandler}
          />
        ))}
      {!endOfResults && (
        <CenterContent>
          <span onClick={loadMore} className="recomended-selecetor__show-more">
            POKAŻ WIĘCEJ <ArrowDownOutlined />
          </span>
        </CenterContent>
      )}
    </div>
  );
};

RecomendedSelector.propTypes = {
  recomendedList: PropTypes.arrayOf(PropTypes.string),
  updateRecomendedList: PropTypes.func,
  error: PropTypes.bool,
};

export default memo(RecomendedSelector);
