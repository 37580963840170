import React, { memo } from "react";
import RecommendedArticleTileSkeleton from "./RecommendedArticleTileSkeleton";

const ArticleRecommendationSkeleton = () => {
  const arr = [1, 2, 3];
  return (
    <section className="w100">
      <div className="recomended-title-skeleton skeleton mb-2"></div>
      {arr.map(() => (
        <RecommendedArticleTileSkeleton />
      ))}
    </section>
  );
};

export default memo(ArticleRecommendationSkeleton);
