import React, { memo } from "react";
import AboutSong from "./components/AboutSong/AboutSong";
import ReviewSong from "./components/ReviewSong/ReviewSong";
import ReviewOverview from "./components/ReviewOverview/ReviewOverview";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import LyricsContainer from "./components/SongLyricsDisplay/LyricsContainer";

const SingleSongPage = () => {
  return (
    <PageContainer>
      <AboutSong />
      <LyricsContainer />
      <div className="pt-8"></div>
      <ReviewSong className="mb-6" />
      <ReviewOverview className="mb-6" />
    </PageContainer>
  );
};

export default memo(SingleSongPage);
