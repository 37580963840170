import React, { memo } from "react";
import ResultList from "./components/ResulsList/ResultList";
import PropTypes from "prop-types";
import PageTitle from "../PageTitle/PageTitle";
import SingleResultSkeleton from "./components/SingleResult/SingleResultSkeleton";
import CenterContent from "../CenterContent/CenterContent";
import NoResultsSearch from "../NoResultsSearch/NoResultsSearch";

const SongRanking = (props) => {
  const {
    showPosition,
    title,
    songRankingData,
    query,
    loading,
    endOfResults,
  } = props;

  return (
    <section className={`song-ranking-page`}>
      <PageTitle>{title}</PageTitle>
      {songRankingData && songRankingData.length > 0 && (
        <ResultList
          songRankingData={songRankingData}
          showPosition={showPosition}
          query={query}
        />
      )}
      {songRankingData && songRankingData.length == 0 && !loading && (
        <NoResultsSearch />
      )}
      {loading && !endOfResults && (
        <ul>
          <SingleResultSkeleton showPosition={showPosition} />
        </ul>
      )}
      {endOfResults && <CenterContent>Koniec wyników</CenterContent>}
    </section>
  );
};

SongRanking.propTypes = {
  showPosition: PropTypes.bool,
  title: PropTypes.string.isRequired,
  songRankingData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      author: PropTypes.string,
      album: PropTypes.string,
      releaseDate: PropTypes.string,
      rate: PropTypes.number,
      numberOfVotes: PropTypes.number,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  query: PropTypes.string,
  loading: PropTypes.bool,
  endOfResults: PropTypes.bool,
};

export default memo(SongRanking);
