import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "../../../Tile/Tile";
import { Link } from "react-router-dom";

const SingleArtistResult = (props) => {
  const { author, imageUrl, redirectUrl } = props.artistRankingData;
  return (
    <li className="single-artist-result" key={author}>
      <Link
        to={
          redirectUrl
            ? redirectUrl
            : `/artist/${author
                .replace(/[^\w\s]/gi, "-") //special characters
                .replace(/\s/g, "") //spaces
                .replace(/_/g, "-") //underscore
                .toLowerCase()}`
        }
      >
        <Tile>
          <div className="single-artist-result__content">
            <div className="single-artist-result__content__frame">
              {imageUrl && (
                <img
                  className="single-artist-result__content__frame__img"
                  src={imageUrl}
                  alt="okładka płyty"
                />
              )}
            </div>
            <h2 className="single-artist-result__content__title">{author}</h2>
          </div>
        </Tile>
      </Link>
    </li>
  );
};

SingleArtistResult.propTypes = {
  className: PropTypes.string,
  artistRankingData: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      imageUrl: PropTypes.string,
      redirectUrl: PropTypes.string,
    })
  ),
  showPosition: PropTypes.bool,
  query: PropTypes.string,
};

export default memo(SingleArtistResult);
