import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import InputField from "../../../../common/components/InputField/InputField";
import Btn from "../../../../common/components/Btn/Btn";
import { Form } from "antd";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  GoogleCircleFilled,
  FacebookFilled,
} from "@ant-design/icons";
import { registerApiCall } from "../../../../Api/authentication/registerApiCall";
import { setErrorsFromBE } from "../../../../common/functions/setErrorsFromBE";
import {
  loginWithFacebookPopup,
  loginWithGooglePopup,
} from "../../../../common/functions/loginWithFunction";
import Spinner from "../../../../common/components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { urls } from "../../../../Router/urls";
import { showNotificationAction } from "../../../../Redux/actions/notificationActions";
import { connect } from "react-redux";

const RegisterForm = (props) => {
  const { className, showNotificationAction } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [loading, setLoading] = useState(false);

  const registerHandler = (registerData) => {
    setLoading(true);
    registerApiCall({ ...registerData, type: "user" }).then((res) => {
      if (res.token) {
        setLoading(false);
        showNotificationAction(
          {
            title: "OSTATNI KROK REJESTRACJI",
            message:
              "Potwierdzenie email. Proszę kliknij w link, który został przesłany na Twoją skrzynkę pocztową.",
            icon: "email",
          },
          60
        );
        history.push(urls.homePage);
      } else {
        setLoading(false);
        setErrorsFromBE(form, res);
      }
    });
  };

  const loginWithGoogleHandler = () => {
    setLoading(true);

    loginWithGooglePopup().then((res) => {
      localStorage.setItem("token", res.token);
      setLoading(false);
    });
  };

  const loginWithFacebookHandler = () => {
    setLoading(true);

    loginWithFacebookPopup().then((res) => {
      localStorage.setItem("token", res.token);
      setLoading(false);
    });
  };
  const requiredRule = { required: true, message: "Proszę uzupełnij te pole." };

  return (
    <Form
      form={form}
      className={`register-page__register ${className ?? ""}`}
      onFinish={registerHandler}
    >
      <div className="mb-4">
        <Form.Item name="nickName" rules={[requiredRule]}>
          <InputField label="Pseudonim*" maxLength={25} />
        </Form.Item>
      </div>

      <div className="mb-4">
        <Form.Item name="email" rules={[requiredRule]}>
          <InputField label="Email*" type="email" requierd />
        </Form.Item>
      </div>
      <div className="mb-4">
        <Form.Item
          name="password"
          rules={[
            requiredRule,
            {
              pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/,
              validateTrigger: "onSubmit",
              message:
                "Hasło musi zawierać minimum: 8 znaków, jedną wileką literę (A-Z) oraz cyfrę (0-9).",
            },
          ]}
        >
          <InputField
            label="Hasło*"
            type={showPassword ? "text" : "password"}
            icon={showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            onIconClick={() => setShowPassword((prev) => !prev)}
          />
        </Form.Item>
      </div>
      <div className="mb-6">
        <Form.Item
          name="confimedPassword"
          rules={[
            requiredRule,
            {
              validator: (_, value) => {
                if (value === form.getFieldValue("password"))
                  return Promise.resolve();
                else return Promise.reject("error");
              },
              validateTrigger: "onSubmit",
              message: "Podane hasła nie są takie same.",
            },
          ]}
        >
          <InputField
            label="Powtórz hasło*"
            type={showPasswordRepeat ? "text" : "password"}
            icon={
              showPasswordRepeat ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
            onIconClick={() => setShowPasswordRepeat((prev) => !prev)}
          />
        </Form.Item>
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Form.Item>
            <CenterContent>
              <Btn
                type="primary"
                htmlType="submit"
                className="register-page__register__submit"
              >
                Zarejestruj
              </Btn>
            </CenterContent>
          </Form.Item>
          <CenterContent className="mt-4">
            <Btn
              type="primary"
              className="login-page__login__submit"
              onClick={loginWithGoogleHandler}
            >
              <GoogleCircleFilled />
              Zarejestruj przez Google
            </Btn>
          </CenterContent>
          <CenterContent className="mt-4">
            <Btn
              type="primary"
              className="login-page__login__submit"
              onClick={loginWithFacebookHandler}
            >
              <FacebookFilled />
              Zarejestruj przez Facebook
            </Btn>
          </CenterContent>
        </>
      )}
    </Form>
  );
};

RegisterForm.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = {
  showNotificationAction,
};

export default memo(connect(null, mapDispatchToProps)(RegisterForm));
