import React, { memo } from "react";

const ArticleBodySkelton = () => {
  return (
    <div className="article-body-skeleton">
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__semi-line skeleton"></div>
      <div className="article-body-skeleton__line"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__semi-line skeleton"></div>
      <div className="article-body-skeleton__line"></div>
      <div className="article-body-skeleton__line skeleton"></div>
      <div className="article-body-skeleton__line skeleton"></div>
    </div>
  );
};

export default memo(ArticleBodySkelton);
