import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Number from "../../../../common/components/Number/Number";
import { Tooltip } from "antd";
import { StarFilled, StarOutlined, LinkOutlined } from "@ant-design/icons";
import { postArticleLike } from "../../../../Api/articles/postArticleLike";
import { postArticleDisLike } from "../../../../Api/articles/postArticleDislike";
import { getIfUserLikedArticle } from "../../../../Api/articles/getIfUserLikedArticle";

const RateArticleBlock = (props) => {
  const { id, link, user, likes, type } = props;
  const [clicked, setClicked] = useState();
  const [numberOfLikes, setNumberOfLikes] = useState();

  useEffect(() => {
    console.log(likes);
    setNumberOfLikes(likes);
  }, [likes]);

  useEffect(() => {
    if (user && user.credentials.email) {
      getIfUserLikedArticle(id, type, user.credentials.email).then((res) => {
        if (res.liked) {
          setClicked(true);
        } else {
          setClicked(false);
        }
      });
    } else {
      setClicked(false);
    }
  }, [user, likes]);

  const copyLinkHandler = (url) => {
    let textArea = document.createElement("textarea");
    textArea.value = `https://rapkrytyk.pl/${url}`;
    console.log(url);

    document.body.appendChild(textArea);

    textArea.select();
    textArea.focus();

    /* Copy the text inside the text field */
    document.execCommand("copy");
    textArea.style.display = "none";
  };

  const onLikeClickHandler = () => {
    console.log("HELLO");
    if (clicked) {
      setNumberOfLikes(numberOfLikes - 1);
      setClicked(false);
      postArticleDisLike(id, type, user.credentials.email);
    } else {
      setNumberOfLikes(numberOfLikes + 1);
      setClicked(true);
      postArticleLike(id, type, user.credentials.email);
    }
  };

  return (
    <div className="rate-block">
      {link && (
        <div className="rate-block__coppy">
          <Tooltip title="Skopiuj link artykułu">
            <LinkOutlined onClick={() => copyLinkHandler(link)} />
          </Tooltip>
        </div>
      )}
      {numberOfLikes != undefined && clicked != undefined && (
        <div className="rate-block__star-part">
          <Number className="rate-block__star-part__number">
            {numberOfLikes}
          </Number>
          <Tooltip
            className={`like`}
            key="comment-basic-like"
            title="Dobre gówno!"
          >
            <span onClick={onLikeClickHandler}>
              {clicked ? <StarFilled /> : <StarOutlined />}
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

RateArticleBlock.propTypes = {
  id: PropTypes.string,
  link: PropTypes.string,
  likes: PropTypes.number,
  type: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(RateArticleBlock));
