import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { Tabs } from "antd";
import UserReviewsTab from "./components/UserReviewsTab/UserReviewsTab";
import UserBio from "./components/UserBio/UserBio";
import { getUserData } from "../../Api/user/getUserData";
import { useLocation } from "react-router-dom";
import Spinner from "../../common/components/Spinner/Spinner";

const { TabPane } = Tabs;

const DiffUserAccountPage = (props) => {
  const { className } = props;
  const location = useLocation();
  const [userData, setUserData] = useState();

  useEffect(() => {
    const emailFromUrl = location.pathname.split("/")[2];
    getUserData(emailFromUrl).then((res) => {
      if (res.userData) setUserData({ credentials: { ...res.userData } });
    });
  }, []);

  return (
    <section className={`${className ?? ""}`}>
      <PageContainer className="add-song-page">
        {userData && (
          <PageTitle>Użytkownik {userData.credentials.nickName}</PageTitle>
        )}
        {userData && (
          <Tabs defaultActiveKey={"song"}>
            <TabPane tab={"Bio"} key={"bio"}>
              <UserBio user={userData} />
            </TabPane>
            <TabPane tab={"Recenzje"} key={"reviewsTab"}>
              <UserReviewsTab userVisiting={userData} />
            </TabPane>
          </Tabs>
        )}
        {!userData && <Spinner />}
      </PageContainer>
    </section>
  );
};

DiffUserAccountPage.propTypes = {
  className: PropTypes.string,
};

export default memo(DiffUserAccountPage);
