import React, { memo } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";

const Like = (props) => {
  const { clicked, number, onLikeClick, tooltipText, className } = props;
  return (
    <Tooltip
      className={`like ${className ?? ""}`}
      key="comment-basic-like"
      title={tooltipText ? tooltipText : "Świetna recenzja!"}
    >
      <span onClick={onLikeClick}>
        {clicked ? <StarFilled /> : <StarOutlined />}
        <span className="like__number">{number}</span>
      </span>
    </Tooltip>
  );
};

Like.propTypes = {
  number: PropTypes.number.isRequired,
  clicked: PropTypes.string.isRequired,
  onLikeClick: PropTypes.func,
  tooltipText: PropTypes.string,
};

export default memo(Like);
