import React, { memo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import MetaData from "../../common/components/MetaData/MetaData";
import { EllipsisOutlined, FileSyncOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { urls } from "../../Router/urls";
import Btn from "../../common/components/Btn/Btn";

const AddSongPage = (props) => {
  return (
    <section>
      <MetaData
        title={"Zmień przeglądarkę - Rap Krytyk"}
        description="Proszę zmień przeglądarkę - niektóre funkcjonalności będą źle działać."
      />
      <PageContainer className="external-browser">
        <PageTitle>Zmień proszę przeglądarkę</PageTitle>
        <div className="center-content-column">
          <FileSyncOutlined
            style={{
              fontSize: "30px",
            }}
          />
          <div className="mb-6 c-gray text-center">
            Nie wszystkie funkcjonalności będą dostępne jeśli nie zmienisz
            przeglądarki.
          </div>

          <h2 className="mb-2">Jak to zrobić?</h2>
          <ol style={{ paddingLeft: "12px" }}>
            <li className="mb-4">
              <EllipsisOutlined
                style={{
                  transform: "rotate(90deg)",
                  fontSize: "20px",
                }}
              />
              Naciśnij 3 kropki w prawym górnym rogu
            </li>
            <li className="mb-4">
              Wybierz "Otwórz w aplkiacji Chrome / Safari"
            </li>
          </ol>
          <Link to={urls.homePage}>
            <Btn>Przejdź do strony</Btn>
          </Link>
        </div>
      </PageContainer>
    </section>
  );
};

AddSongPage.propTypes = {
  className: PropTypes.string,
};

export default memo(AddSongPage);
