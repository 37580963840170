import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment-with-locales-es6";
import { Link } from "react-router-dom";

const ArticleTile = (props) => {
  const {
    id,
    title,
    headerImg,
    author,
    teaserText,
    tags,
    timeStamp,
    link,
  } = props.articleData;

  return (
    <Link to={link}>
      <div className="article-tile" key={id}>
        <div className="article-tile__img-container">
          <img src={headerImg} alt="article-header" />
          <div className="article-tile__img-container__date">
            {moment(timeStamp).format("DD.MM.YYYY")}
          </div>
        </div>
        <div className="article-tile__text">
          <h2 className="article-tile__text__title">{title}</h2>
          <div className="article-tile__text__teaser">{teaserText}</div>
        </div>
        <div className="article-tile__tags">
          Tagi:{" "}
          {tags.map((tagText) => (
            <strong className="article-tile__tags__single-tag">
              {tagText}
            </strong>
          ))}
        </div>
      </div>
    </Link>
  );
};

ArticleTile.propTypes = {
  articleData: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
    headerImg: PropTypes.string,
    id: PropTypes.string,
    link: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    teaserText: PropTypes.string,
    timeStamp: PropTypes.string,
  }).isRequired,
};

export default memo(ArticleTile);
