import axios from "axios";
import { urls } from "../../Router/urls";

export const getReviewsAnonymous = (id, lastDocId) => {
  return axios
    .get(`${urls.base}/api/get-review-list?id=${id}&lastDocId=${lastDocId}`)
    .then((res) => {
      return res.data.reviews;
    })
    .catch((error) => {
      return error.response;
    });
};
