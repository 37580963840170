import React, { memo } from "react";
import PropTypes from "prop-types";

const Number = (props) => {
  const { children, className } = props;
  return <span className={`number ${className ?? ""}`}>{children}</span>;
};

Number.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default memo(Number);
