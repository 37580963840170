import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "../../../../common/components/Tile/Tile";
import Number from "../../../../common/components/Number/Number";
import { StarFilled } from "@ant-design/icons";

const SingleRateCardSkeleton = (props) => {
  const { className } = props;

  return (
    <Tile className={`rate-tabs__single-skeleton mb-6 ${className}`}>
      <div className="rate-tabs__single-skeleton__left">
        <h2 className="rate-tabs__single-skeleton__left__title skeleton"></h2>
      </div>
      <div className="rate-tabs__single-skeleton__right">
        <div className="rate-tabs__single-skeleton__right__rate--normal">
          <div className="rate-tabs__single-skeleton__right__rate--normal__label skeleton"></div>
          <div>
            <Number className="rate-tabs__single-skeleton__right__rate--normal__value skeleton"></Number>
          </div>
        </div>
        <div className="rate-tabs__single-skeleton__right__rate--normal">
          <div className="rate-tabs__single-skeleton__right__rate--normal__label skeleton"></div>
          <div>
            <Number className="rate-tabs__single-skeleton__right__rate--normal__value skeleton"></Number>
          </div>
        </div>
        <div className="rate-tabs__single-skeleton__right__rate--normal">
          <div className="rate-tabs__single-skeleton__right__rate--normal__label skeleton"></div>
          <div>
            <Number className="rate-tabs__single-skeleton__right__rate--normal__value skeleton"></Number>
          </div>
        </div>
        <div className="rate-tabs__single-skeleton__right__rate--main">
          <div className="rate-tabs__single-skeleton__right__rate--main__label skeleton"></div>
          <div>
            <Number className="rate-tabs__single-skeleton__right__rate--main__value">
              <span className="rate-tabs__single-skeleton__right__rate--main__value__number skeleton"></span>
              <StarFilled />
            </Number>
          </div>
        </div>
      </div>
    </Tile>
  );
};

SingleRateCardSkeleton.propTypes = {
  className: PropTypes.string,
};

export default memo(SingleRateCardSkeleton);
