import React, { memo } from "react";
import MetaData from "../../common/components/MetaData/MetaData";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";

const AboutUsPage = () => {
  return (
    <section>
      <MetaData
        title={"O nas - Rap Krytyk"}
        description="Rap Krytyk, o nas: Pomysł na stworzenie RapKrytyka
        powstał, gdy odkryłem, że brakuje miejsca w sieci, w którym mógłbym
        poznawać nowe utwory nie kierując się tylko ilością wyświetleń lub
        lajków. Zachęcam do dzielenia się swoimi opiniami jak i do ciekawych
        dyskusji."
      />
      <PageContainer>
        <PageTitle>O nas</PageTitle>
        <div className="mb-10 text-justify">
          W zasadzie to o mnie :) - jest to projekt napisany przez jedną osobę,
          chociaż musicie mi przyznać, że „o nas” brzmi profejsonalniej. Mam na
          imię Kuba, 21 lat i na codzień pracuję jako Frontend Developer w
          jednej z wodzących korporacji. Nigdy nie byłem na studiach, bo skupiam
          się na praktycznej wiedzy. Zajawkę do rap’u złapałem dość późno, bo
          dopiero w liceum, a wszystko dzięki moim dobyrm przyjaciołom, którzy
          zrazili mnie tą muzyką / kulturą. Pomysł na stworzenie RapKrytyka
          powstał, gdy odkryłem, że brakuje miejsca w sieci, w którym mógłbym
          poznawać nowe utwory nie kierując się tylko ilością wyświetleń lub
          lajków. Zachęcam do dzielenia się swoimi opiniami jak i do ciekawych
          dyskusji. Bardzo mi miło, że tu jesteś, DZIĘKUJĘ.
        </div>
      </PageContainer>
    </section>
  );
};

export default memo(AboutUsPage);
