import React, { memo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import HomeCarousel from "./components/HomeCarousel";
import RandomSection from "./components/RandomSection";
import RecentlyAddedCarousel from "./components/RecentlyAddedCarousel/RecentlyAddedCarousel";
import MetaData from "../../common/components/MetaData/MetaData";
import RecomendedCarousel from "./components/RecomendedCarousel/RecomendedCarousel";
import RecentylAddedAlbums from "./components/RecentlyAddedAlbums/RecentylAddedAlbums";
import ContestSection from "./components/ContestSection";
import CountSection from "./components/CountSection";
import YouHaveVoteSection from "./components/YouHaveVoteSection";
import AddSongSection from "./components/AddSongSection";
import RivalrySection from "./components/RivalrySection";

const HomePage = (props) => {
  const { className } = props;

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Rap Krytyk"}
        description="Rap Krytyk - oceniaj rapowe hity, odkryaj playlisty, zdobywaj punkty i rywalizuj z innymi krytykami. Dodając rezencję, utwór lub oceniając możesz zdobywać punkty."
      />
      <HomeCarousel />
      <PageContainer className="home-page">
        <RandomSection />
        <RecentlyAddedCarousel />
        <CountSection />
        <AddSongSection />

        <RecomendedCarousel />
        <YouHaveVoteSection />

        <RecentylAddedAlbums />
        <RivalrySection />
      </PageContainer>
    </section>
  );
};

HomePage.propTypes = {
  className: PropTypes.string,
};

export default memo(HomePage);
