import axios from "axios";
import { urls } from "../../Router/urls";

export const postComment = (commentData) => {
  return axios
    .post(`${urls.base}/api/send-article-comment`, commentData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
