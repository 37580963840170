import React, { memo } from "react";
import PropTypes from "prop-types";
import Btn from "../../../common/components/Btn/Btn";
import { Link } from "react-router-dom";
import { PlusOutlined, FileAddOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import sectionImage from "../../../images/hp-section-02.jpg";
import { urls } from "../../../Router/urls";
import Slide from "react-reveal/Slide";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../common/variables/breakpoints/breakpoints";

const AddSongSection = (props) => {
  const { className, user } = props;

  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  return (
    <section className={`add-song-section ${className ?? ""}`}>
      <div className="add-song-section__left">
        <h2 className="add-song-section__left__title">DODAWAJ MUZYKĘ</h2>
        <FileAddOutlined className="add-song-section__left__icon" />
        <Slide left={isDesktop}>
          <p className="add-song-section__left__text">
            Jeśli nie możesz czegoś znaleźć w naszej bazie danych - to poprostu
            to
            <strong> dodaj</strong>. W zamian otrzymasz <strong> punkty</strong>
            , które pozwolą Ci wbijać kolejne lepsze <Link>rangi</Link>.
          </p>
          <Link
            to={user.credentials.email ? urls.addContent : urls.login}
            className="add-song-section__left__link"
          >
            <Btn>
              <PlusOutlined /> Dodaj utwór / album
            </Btn>
          </Link>
        </Slide>
      </div>

      <div className="add-song-section__right">
        <img
          src={sectionImage}
          alt="Odkrywaj muzykę"
          className="add-song-section__right__img"
        />
      </div>
    </section>
  );
};

AddSongSection.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(AddSongSection));
