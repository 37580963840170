import axios from "axios";
import { urls } from "../../Router/urls";

export const postArticle = (articleObject) => {
  return axios
    .post(`${urls.base}/api/send-article`, articleObject)
    .then((articleResponse) => {
      return articleResponse.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};
