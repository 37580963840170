import React, { memo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import AddSongForm from "./components/AddSongForm/AddSongForm";
import { Tabs } from "antd";
import AddAlbumContainer from "./components/AddAlbum/AddAlbumContainer";
import MetaData from "../../common/components/MetaData/MetaData";

const { TabPane } = Tabs;

const AddSongPage = (props) => {
  const { className } = props;

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Dodaj utwór - Rap Krytyk"}
        description="Rap Krytyk - dodaj utwór lub album. Zdobędziesz za to punkty, a my stworzymy dla Ciebie najlepsze rankingi."
      />
      <PageContainer className="add-song-page">
        <PageTitle>Dodaj piosenkę lub album</PageTitle>
        <Tabs defaultActiveKey={"song"}>
          <TabPane tab={"Singiel"} key={"song"}>
            <AddSongForm />
          </TabPane>
          <TabPane tab={"Album"} key={"album"}>
            <AddAlbumContainer />
          </TabPane>
        </Tabs>
      </PageContainer>
    </section>
  );
};

AddSongPage.propTypes = {
  className: PropTypes.string,
};

export default memo(AddSongPage);
