import axios from "axios";
import { urls } from "../../Router/urls";

export const updateUserComment = (reviewData) => {
  return axios
    .post(`${urls.base}/api/update-comment`, reviewData)
    .then((res) => {
      return res.data.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};
