import React, { memo } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { Tabs } from "antd";
import MyProfileBio from "./Components/MyProfileBio/MyProfileBio";
import RequestsHistory from "./Components/RequestsHistory/RequestsHistory";
import RatesTab from "./Components/RatesTab/RatesTab";
import ReviewsTab from "./Components/ReviewsTab/ReviewsTab";
import MetaData from "../../common/components/MetaData/MetaData";

const { TabPane } = Tabs;

const MyAccountPage = (props) => {
  const { className } = props;

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Mój profil - Rap Krytyk"}
        description="Na stronie swojego profilu możesz personalizować konto jak i zobaczyć historię ocen, recenzji, próśb."
      />
      <PageContainer className="add-song-page">
        <PageTitle>Mój profil</PageTitle>
        <Tabs defaultActiveKey={"song"}>
          <TabPane tab={"Bio"} key={"bio"}>
            <MyProfileBio />
          </TabPane>
          <TabPane tab={"Historia próśb"} key={"reqHistory"}>
            <RequestsHistory />
          </TabPane>
          <TabPane tab={"Moje oceny"} key={"ratesTab"}>
            <RatesTab />
          </TabPane>
          <TabPane tab={"Moje recenzje"} key={"reviewsTab"}>
            <ReviewsTab />
          </TabPane>
        </Tabs>
      </PageContainer>
    </section>
  );
};

MyAccountPage.propTypes = {
  className: PropTypes.string,
};

export default memo(MyAccountPage);
