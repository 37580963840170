import React, { memo } from "react";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import PageTitle from "../../../common/components/PageTitle/PageTitle";
import { List } from "antd";
import CoverPhotoSkeleton from "../../../common/components/CoverPhoto/CoverPhotoSkeleton";

const AboutArtistSkeleton = (props) => {
  const dumyRows = [0, 1, 2, 3, 4];
  return (
    <>
      <PageTitle>
        <div className="about-artist-skeleton__title skeleton"></div>
      </PageTitle>
      <CenterContent>
        <CoverPhotoSkeleton />
      </CenterContent>

      <div className="mb-6">
        <List
          className="about-artist-skeleton__list"
          size="large"
          header={
            <div className="about-artist-skeleton__list__title skeleton"></div>
          }
          bordered
          dataSource={dumyRows}
          renderItem={(row) => (
            <List.Item
              className="about-artist-skeleton__list__item"
              actions={[]}
              key={row}
            >
              <div className="skeleton"></div>
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default memo(AboutArtistSkeleton);
