import React, { memo } from "react";
import PropTypes from "prop-types";
import CenterContent from "../CenterContent/CenterContent";

const CoverPhoto = (props) => {
  const { imageSrc, className } = props;
  return (
    <CenterContent className="mb-6">
      <div className={`${className} cover-photo__frame`}>
        <img
          className="cover-photo__frame__img"
          src={imageSrc}
          alt="okładka płyty"
        />
      </div>
    </CenterContent>
  );
};

CenterContent.propTypes = {
  imageSrc: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default memo(CoverPhoto);
