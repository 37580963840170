export const leagues = [
  { name: "", minPoints: -1 },
  { name: "Klasyczna płyta", minPoints: 0 },
  { name: "Podwójny klasyk", minPoints: 50 },
  { name: "Potrójny klasyk", minPoints: 100 },
  { name: "Broązowa płyta", minPoints: 150 },
  { name: "Podwójny brąz", minPoints: 250 },
  { name: "Potrójny brąz", minPoints: 350 },
  { name: "Srebrna płyta", minPoints: 550 },
  { name: "Podwójne srebro", minPoints: 750 },
  { name: "Potrójne srebro", minPoints: 950 },
  { name: "Złota płyta", minPoints: 1200 },
  { name: "Podwójne złoto", minPoints: 1500 },
  { name: "Potrójne złoto", minPoints: 1800 },
  { name: "Rubinowa płyta", minPoints: 2200 },
  { name: "Podwójny rubin", minPoints: 2600 },
  { name: "Potrójny rubin", minPoints: 3500 },
];
