import axios from "axios";
import { urls } from "../../Router/urls";
import store from "../../Redux/store";

export const disLike = (reviewData) => {
  const reduxState = store.getState();

  const dataForDelete = {
    ...reviewData,
    currentUserEmail: reduxState.user.credentials.email,
  };

  return axios
    .post(`${urls.base}/api/dislike`, dataForDelete)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};
