import React, { memo } from "react";
import CoverPhotoSkeleton from "../../../../../common/components/CoverPhoto/CoverPhotoSkeleton";
import PageTitle from "../../../../../common/components/PageTitle/PageTitle";
import { YoutubeFilled, StarFilled } from "@ant-design/icons";

const AboutSongStaticSkeleton = (props) => {
  const { title, songData } = props;
  return (
    <>
      <PageTitle>
        <div class="about-song-skeleton__title skeleton"></div>
      </PageTitle>
      <div className="about-song-skeleton__wrapper">
        <div className="about-song-skeleton__wrapper__info-block">
          <div className="about-song-skeleton__wrapper__info-block__info mb-2">
            <div className="skeleton"></div>
          </div>
          <div className="about-song-skeleton__wrapper__info-block__info mb-2">
            <div className="skeleton"></div>
          </div>
          <div className="about-song-skeleton__wrapper__info-block__info mb-2">
            <div className="skeleton shorter"></div>
            <a className="about-song-skeleton__wrapper__info-block__link">
              <YoutubeFilled />
            </a>
          </div>
          <div className="about-song-skeleton__wrapper__info-block__rate mb-2">
            <div className="skeleton"></div>
            <StarFilled />
          </div>
        </div>
        <CoverPhotoSkeleton />
      </div>
    </>
  );
};

export default memo(AboutSongStaticSkeleton);
