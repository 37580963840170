import React, { memo } from "react";
import RecommendedArticleTileSkeleton from "./RecommendedArticleTileSkeleton";

const ArticleMonthSummaryRecomendationSkeleton = () => {
  return (
    <section className="w100">
      <div className="recomended-title-skeleton skeleton mb-2"></div>
      <RecommendedArticleTileSkeleton />
    </section>
  );
};

export default memo(ArticleMonthSummaryRecomendationSkeleton);
