import React, { memo } from "react";
import ReviewSong from "../SingleSongPage/components/ReviewSong/ReviewSong";
import ReviewOverview from "../SingleSongPage/components/ReviewOverview/ReviewOverview";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import AboutAlbum from "./components/AboutAlbum";

const SingleAlbumPage = () => {
  return (
    <PageContainer>
      <AboutAlbum />
      <div className="pt-6 mb-10"></div>
      <ReviewSong className="mb-6" />
      <ReviewOverview className="mb-6" />
    </PageContainer>
  );
};

export default memo(SingleAlbumPage);
