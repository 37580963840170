import React, { memo, useState, useEffect } from "react";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import RateStars from "../../../../common/components/RateStars/RateStars";
import Btn from "../../../../common/components/Btn/Btn";
import { useForm, Controller } from "react-hook-form";
import RadioGroup from "../../../../common/components/RadioGroup/RadioGroup";
import Message from "../../../../common/components/Message/Message";
import { getSingleSong } from "../../../../Api/songs/getSingleSong";
import { useLocation, useHistory } from "react-router-dom";
import AboutSongStatic from "./AboutSongStatic/AboutSongStatic";
import AboutSongStaticSkeleton from "./AboutSongStatic/AboutSongStaticSkeleton";
import DetailRates from "../../../../common/components/DetailRate/DetailRates";
import { postSongRate } from "../../../../Api/rate/postSongRate";
import YourOverallRate from "../../../../common/components/YourOverallRate/YourOverallRate";
import Spinner from "../../../../common/components/Spinner/Spinner";
import { addFiveForUser } from "../../../../Redux/actions/userActions";
import { connect } from "react-redux";
import { urls } from "../../../../Router/urls";
import { getSingleUserRate } from "../../../../Api/rate/getSingleUserRate";
import UserRate from "./AboutSongStatic/UserRate";
import MetaData from "../../../../common/components/MetaData/MetaData";

const AboutSong = (props) => {
  const { user, addFiveForUser } = props;
  const location = useLocation();
  const history = useHistory();
  const songId = location.pathname.split("/")[2];

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    getValues,
  } = useForm();
  const [overall, setOverall] = useState();
  const [songData, setSongData] = useState();
  const [detailData, setDetailData] = useState();
  const [loading, setLoading] = useState(false);
  const [userRateData, setUserRateData] = useState();

  useEffect(() => {
    getSingleSong(songId).then((res) => {
      setSongData({
        id: res.id,
        name: res.name,
        album: res.album,
        artist: res.artist,
        additionalArtists: res.additionalArtists,
        imageUrl: res.imageUrl,
        origin: res.origin,
        year: res.year,
        ytLink: res.ytLink,
        avarageRate: res.avarageRate,
        numberOfRates: res.numberOfRates,
      });

      setDetailData({
        bitAvarage: {
          rate: res.bitAvarage,
          label: "Bit średnia",
        },
        lirycsAvarage: {
          rate: res.lirycsAvarage,
          label: "Słowa średnia",
        },
        vocalAvarage: {
          rate: res.vocalAvarage,
          label: "Vocal średnia",
        },
      });
    });
  }, [location]);

  useEffect(() => {
    if (user.credentials.email)
      getSingleUserRate(songId).then((res) => {
        if (res.overall) setUserRateData(res);
      });
  }, [user.credentials.email]);

  const onSubmit = (data) => {
    let formValid = true;
    const validateField = (fieldName) => {
      const requiredMessage = "Przed zatwierdzeniem proszę uzupełnij te pole.";
      if (data[fieldName] === undefined || data[fieldName] === 0) {
        setError(fieldName, {
          type: "required",
          message: requiredMessage,
        });
        formValid = false;
      }
    };

    //unfortunetly using rc-rate I can't validate with ref
    validateField("bit");
    validateField("lirycs");
    validateField("vocal");
    validateField("type");

    if (formValid) {
      setLoading(true);

      postSongRate({
        id: songData.id,
        name: songData.name,
        ...data,
        overall: overall,
      }).then((res) => {
        setSongData({
          ...songData,
          numberOfRates: songData.numberOfRates + 1,
          avarageRate: res.avarageRate,
        });

        setDetailData({
          bitAvarage: {
            rate: res.bitAvarage,
            label: "Bit średnia",
          },
          lirycsAvarage: {
            rate: res.lirycsAvarage,
            label: "Słowa średnia",
          },
          vocalAvarage: {
            rate: res.vocalAvarage,
            label: "Vocal średnia",
          },
        });
        setUserRateData({
          ...data,
          overall: overall,
        });
        setLoading(false);
        addFiveForUser();
      });
    }
  };

  const checkIfAllRatesAreComplete = () => {
    if (user.credentials.email) {
      const valuesObject = getValues(["bit", "lirycs", "vocal"]);
      const bit = valuesObject.bit;
      const lirycs = valuesObject.lirycs;
      const vocal = valuesObject.vocal;

      if (bit && lirycs && vocal) {
        const overallNewValue = ((bit + lirycs + vocal) / 3).toFixed(1);
        setOverall(overallNewValue);
      } else setOverall(null);
    } else {
      history.push(urls.login);
    }
  };
  return (
    <heading className="about-song">
      {songData && (
        <MetaData
          title={`${songData.name} - Oceniaj i recenzuj Rap Krytyk`}
          description={`${songData.name} - ${songData.artist}. Oceniaj, recenzuj, zdobywaj punkty.Na rapkrytyk.pl sprawdzaj opinie użytkowników i baw się dobrze słuchając rapu.`}
        />
      )}
      {songData ? (
        <AboutSongStatic
          title={`Utwór: ${songData.name}`}
          songData={songData}
        />
      ) : (
        <AboutSongStaticSkeleton />
      )}
      <div>
        {userRateData ? (
          <UserRate userRateData={userRateData} />
        ) : (
          <form className="rate-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="rate-form__single-rate mb-4">
              <Controller
                control={control}
                name={"bit"}
                render={({ onChange, onBlur, value }) => (
                  <>
                    <RateStars
                      onChange={(newVal) => {
                        onChange(newVal);
                        checkIfAllRatesAreComplete();
                      }}
                      label={"Bit / Podkład muzyczny"}
                      value={value}
                      ref={register({ required: true })}
                    />
                    {errors.bit && (
                      <CenterContent>
                        <Message type="error">{errors.bit.message}</Message>
                      </CenterContent>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mb-4 rate-form__single-rate">
              <Controller
                control={control}
                name={"lirycs"}
                render={({ onChange, onBlur, value }) => (
                  <>
                    <RateStars
                      onChange={(newVal) => {
                        onChange(newVal);
                        checkIfAllRatesAreComplete();
                      }}
                      label={"Słowa / Nawijka"}
                      value={value}
                    />
                    {errors.lirycs && (
                      <CenterContent>
                        <Message type="error">{errors.lirycs.message}</Message>
                      </CenterContent>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mb-4 rate-form__single-rate">
              <Controller
                control={control}
                name={"vocal"}
                render={({ onChange, onBlur, value }) => (
                  <>
                    <RateStars
                      onChange={(newVal) => {
                        onChange(newVal);
                        checkIfAllRatesAreComplete();
                      }}
                      label={"Wokal / Brzmienie głosu"}
                      value={value}
                    />
                    {errors.vocal && (
                      <CenterContent>
                        <Message type="error">{errors.vocal.message}</Message>
                      </CenterContent>
                    )}
                  </>
                )}
              />
            </div>
            <div className="rate-form__type">
              <CenterContent className="mb-1">
                <h3>Typ: </h3>
              </CenterContent>
              <div className="mb-4">
                <Controller
                  control={control}
                  name={"type"}
                  render={({ onChange, onBlur, value }) => (
                    <>
                      <CenterContent>
                        <RadioGroup
                          onChange={onChange}
                          value={value}
                          values={[
                            { value: "funny", label: "Energiczny" },
                            { value: "neutral", label: "Chill" },
                            { value: "sad", label: "Smutny" },
                            { value: "serious", label: "Poważny" },
                            { value: "showOff", label: "Flex" },
                            { value: "motivation", label: "Storytelling" },
                          ]}
                        />
                      </CenterContent>

                      {errors.type && (
                        <CenterContent>
                          <Message type="error">{errors.type.message}</Message>
                        </CenterContent>
                      )}
                    </>
                  )}
                />
              </div>
            </div>

            {overall && <YourOverallRate overall={overall} showWarn={true} />}

            {loading ? (
              <Spinner />
            ) : (
              <CenterContent className="rate-form__btn-container mt-3">
                <Btn type="primary" onClick={handleSubmit(onSubmit)}>
                  Prześlij opinię
                </Btn>
              </CenterContent>
            )}
          </form>
        )}

        {detailData && (
          <DetailRates className="mt-10" detailRates={detailData} />
        )}
      </div>
    </heading>
  );
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  addFiveForUser,
};

export default memo(connect(mapStatetoProps, mapDispatchToProps)(AboutSong));
