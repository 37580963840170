import React, { memo, useEffect, useState } from "react";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PropTypes from "prop-types";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import SimpleTable from "../../common/components/Table/Table";
import Number from "../../common/components/Number/Number";
import { getUserRanking } from "../../Api/rankings/user/getUserRanking";
import CenterContent from "../../common/components/CenterContent/CenterContent";
import Btn from "../../common/components/Btn/Btn";
import Spinner from "../../common/components/Spinner/Spinner";
import MetaData from "../../common/components/MetaData/MetaData";
import { Link } from "react-router-dom";

const CriticRankingPage = (props) => {
  const [displayedData, setDisplayedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [lastEmailList, setLastEmailList] = useState([""]);
  const [endOfResults, setEndOfResults] = useState();

  useEffect(() => {
    setLoading(true);
    updateEmailRanking(lastEmailList[0]);
  }, []);

  useEffect(() => {
    setLoading(true);
    updateEmailRanking(lastEmailList[pageNumber - 1]);
  }, [pageNumber]);

  const updateEmailRanking = (lastEmail) => {
    getUserRanking(lastEmail).then((res) => {
      if (res.length > 0) {
        const newDisplayData = [];
        res.forEach((user, userIndex) => {
          newDisplayData.push({
            position:
              pageNumber === 1
                ? userIndex + 1
                : userIndex + 1 + (pageNumber - 1) * 10,
            name: user.nickName,
            email: user.email,
            points: user.points,
            numberOfRates: user.numberOfRates,
            numberOfReviews: user.numberOfReviews,
            opinions: 22,
            reviews: 12,
          });
        });
        setDisplayedData(newDisplayData);

        const lastDisplayedUserEmail = res[res.length - 1].email;
        if (!lastEmailList.includes(lastDisplayedUserEmail))
          setLastEmailList([...lastEmailList, lastDisplayedUserEmail]);
      } else {
        setEndOfResults(pageNumber - 1);
        setPageNumber(pageNumber - 1);
      }
      setLoading(false);
    });
  };

  const onPrevPageHandler = () => {
    setPageNumber(pageNumber - 1);
  };

  const onNextPageHandler = () => {
    setPageNumber(pageNumber + 1);
  };

  const columns = [
    {
      title: "Pozycja",
      dataIndex: "position",
      key: "position",
      render: (text) => <Number>{text}</Number>,
    },
    {
      title: "Nazwa",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/account/${record.email}`}>{text}</Link>
      ),
    },
    {
      title: "Punkty",
      dataIndex: "points",
      key: "points",
      render: (text) => <Number>{text}</Number>,
    },
    {
      title: "Opinie",
      dataIndex: "numberOfRates",
      key: "numberOfRates",
      render: (text) => <Number>{text}</Number>,
    },
    {
      title: "Recenzje",
      dataIndex: "numberOfReviews",
      key: "numberOfReviews",
      render: (text) => <Number>{text}</Number>,
    },
  ];

  return (
    <section>
      <MetaData
        title={"Ranking krytyków - Rap Krytyk"}
        description={
          "Ranking krytyków - to podium naszych krytyków. Każdy użytkownik walczy, aby stać się najlepszym rapkrytykiem. Zdobywaj punkty, by wzbijać się coraz wyżej w rankingu."
        }
      />
      <PageContainer className={`song-ranking-page`}>
        <PageTitle>{"Ranking krytyków"}</PageTitle>
        {loading ? (
          <Spinner />
        ) : (
          <SimpleTable data={displayedData} headers={columns} />
        )}
        {endOfResults === pageNumber && (
          <CenterContent className="mt-2">Koniec wyników</CenterContent>
        )}
        <CenterContent className="mt-6">
          {pageNumber > 1 && <Btn onClick={onPrevPageHandler}>{"<"}</Btn>}
          <h2 className="mr-4 ml-4">strona {pageNumber}</h2>
          {endOfResults !== pageNumber && (
            <Btn onClick={onNextPageHandler}>{">"}</Btn>
          )}
        </CenterContent>
      </PageContainer>
    </section>
  );
};

CriticRankingPage.propTypes = {};

export default memo(CriticRankingPage);
