import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import Btn from "../../../../common/components/Btn/Btn";
import {
  SmileOutlined,
  YoutubeOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import AddAlbumSongForm from "./AddAlbumSongForm";
import AddAlbumForm from "./AddAlbumForm";
import { postAlbum } from "../../../../Api/songs/postAlbum";
import AddPhoto from "../../../../common/components/AddPhoto/AddPhoto";
import Message from "../../../../common/components/Message/Message";
import Spinner from "../../../../common/components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { urls } from "../../../../Router/urls";
import store from "../../../../Redux/store";
import { showNotificationAction } from "../../../../Redux/actions/notificationActions";

const AddAlbumContainer = (props) => {
  const { className } = props;
  const history = useHistory();

  const [showFirstStep, setShowFirstStep] = useState(true);
  const [songList, setSongList] = useState([]);
  const [albumInfo, setAlbumInfo] = useState({});
  const [album, setAlbum] = useState("");
  const [imageData, setImageData] = useState("");
  const [photoError, setPhotoError] = useState("");
  const [isLodaing, setIsLodaing] = useState(false);
  const [generalError, setGeneralError] = useState("");

  const noDataElemnt = (
    <div style={{ textAlign: "center" }}>
      <SmileOutlined style={{ fontSize: 20 }} />
      <p>Brak utworów</p>
    </div>
  );

  const submitAlbumHandler = (data) => {
    setAlbum(data.album);
    const yearNumber = data.year._d.getYear() + 1900; // JS zapisuje lata od 1900 w zwy
    setAlbumInfo({
      album: data.album,
      artist: data.artist,
      year: yearNumber,
      origin: data.origin,
    });
    setShowFirstStep(false);
    if (songList.length > 0)
      setSongList(
        songList.map((song, index) => {
          return {
            ...song,
            album: data.album,
            artist: data.artist,
            year: yearNumber,
            origin: data.origin,
          };
        })
      );
  };

  const submitSongHandler = (songData) => {
    setSongList([...songList, { ...albumInfo, ...songData }]);
  };

  const sendAlbumToDB = () => {
    if (imageData === "") {
      setPhotoError("Proszę dodaj zdjęcie okładki płyty aby kontynouwać.");
      window.scrollTo(0, 0);
    } else {
      setIsLodaing(true);
      setPhotoError("");
      setGeneralError("");
      const objectToSend = {
        ...albumInfo,
        songList: songList.map((song, index) => {
          return { ...song, albumIndex: index + 1 };
        }),
      };
      postAlbum(objectToSend, imageData).then((res) => {
        setIsLodaing(false);

        if (res.status === 400) {
          setGeneralError(res.data.general);
        } else {
          store.dispatch(
            showNotificationAction(
              {
                title: "PROŚBA DODANA",
                message:
                  "Dzięki, że nas wspierasz! Rozpatrzymy Twoją prośbę w ciągu 24h.",
                icon: "like",
              },
              10
            )
          );
          history.push(urls.homePage);
        }
      });
    }
  };

  return (
    <div className={`add-album-container ${className ?? ""}`}>
      <div className="mb-4">
        <CenterContent>
          <AddPhoto
            id="album"
            btnText="Dodaj okładkę płyty*"
            sendImageDataToParent={setImageData}
          />
        </CenterContent>
        {photoError !== "" && (
          <CenterContent>
            <Message type="error" className="mt-2">
              {photoError}
            </Message>
          </CenterContent>
        )}
      </div>
      {showFirstStep ? (
        <AddAlbumForm
          onSubmitAction={submitAlbumHandler}
          albumInfo={albumInfo}
        />
      ) : (
        <>
          <Btn className="mb-4" onClick={() => setShowFirstStep(true)}>
            <ArrowLeftOutlined /> Wróć
          </Btn>
          <AddAlbumSongForm onSubmitAction={submitSongHandler} />
        </>
      )}
      <CenterContent className="c-gray mt-2">
        Proszę zachowaj kolejność utworów
      </CenterContent>
      <List
        className="add-album-container__list"
        size="large"
        header={<div>{`Utwory albumu${album !== "" ? " " + album : ""}:`}</div>}
        bordered
        dataSource={songList}
        locale={{ emptyText: noDataElemnt }}
        renderItem={(item, index) => (
          <List.Item
            className="add-album-container__list__item"
            actions={[
              <div className={"add-album-container__list__item__feats"}>
                {item.additionalArtists.length > 0 &&
                  `feat: ${item.additionalArtists.join(", ")}`}
              </div>,
              <a href={item.ytLink} target="_blank" rel="noopener noreferrer">
                <YoutubeOutlined />
                YouTube
              </a>,
              <Btn
                onClick={() =>
                  setSongList(
                    songList.filter(
                      (singleData) => singleData.name !== item.name
                    )
                  )
                }
              >
                X
              </Btn>,
            ]}
          >
            <span className="add-album-container__list__item__text">
              {`${index + 1}. `} {item.name}
            </span>
          </List.Item>
        )}
      />
      <CenterContent className="mt-6">
        {isLodaing ? (
          <Spinner />
        ) : (
          <Btn disabled={songList.length < 2} onClick={sendAlbumToDB}>
            Dodaj album
          </Btn>
        )}
      </CenterContent>
      {generalError !== "" && (
        <CenterContent className="mt-2">
          <Message type="error">{generalError}</Message>
        </CenterContent>
      )}
      <p className="pt-4">
        Po naciśnięciu "Dodaj album" twoja porśba zostanie wysłana do
        moderatora. Czas rozpatrzenia prośby wynosi do <strong>24h</strong>.
        Punkty otrzymasz dopiero po akceptacji.
      </p>
    </div>
  );
};

AddAlbumContainer.propTypes = {
  className: PropTypes.string,
};

export default memo(AddAlbumContainer);
