import React, { memo, useEffect, useState } from "react";
import { getSingleArtist } from "../../Api/artists/getSingleArtist";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import AboutArtist from "./components/AboutArtist";
import AboutArtistSkeleton from "./components/AboutArtistSkeleton";
import { useLocation } from "react-router-dom";

const SingleArtistPage = () => {
  let location = useLocation();
  const artistNameFromUrl = location.pathname.split("/")[2];
  const [artistData, setArtistData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getSingleArtist(artistNameFromUrl).then((res) => {
      setArtistData({
        artist: res.artist,
        imageUrl: res.imageUrl,
        albums: res.albums.reverse(),
        singles: res.singles.reverse(),
        appearedIn: res.appearedIn ? res.appearedIn.reverse() : [],
      });
      setLoading(false);
    });
  }, []);
  return (
    <PageContainer>
      {loading ? (
        <AboutArtistSkeleton />
      ) : (
        <AboutArtist artistData={artistData} />
      )}
    </PageContainer>
  );
};

export default memo(SingleArtistPage);
