import React, { memo } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const InputField = (props) => {
  const { className, rowSize, label, icon, onIconClick, ...rest } = props;
  return (
    <div className="input-container">
      <Input
        className={`input-container__field ${className ?? ""}`}
        style={{ paddingRight: icon ? "30px" : "20px" }}
        {...rest}
      />
      <label>{label}</label>
      {icon && (
        <span className="input-container__icon" onClick={onIconClick}>
          {icon}
        </span>
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.element,
  onIconClick: PropTypes.func,
};

export default memo(InputField);
