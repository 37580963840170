import React, { memo } from "react";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { MailOutlined } from "@ant-design/icons";
import MetaData from "../../common/components/MetaData/MetaData";

const ContactPage = () => {
  return (
    <section>
      <MetaData
        title={"Kontakt - Rap Krytyk"}
        description="Skontaktuj sie z nami. Rap Krytyk, email kontaktowy: rapkrytyk.info@gmail.com"
      />
      <PageContainer>
        <PageTitle>Kontakt</PageTitle>
        <div className="contact-page">
          <span className="contact-page__email-icon">
            <MailOutlined />
          </span>
          <h1 className="contact-page__email">rapkrytyk.info@gmail.com</h1>
        </div>
      </PageContainer>
    </section>
  );
};

export default memo(ContactPage);
