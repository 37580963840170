import React, { memo } from "react";
import PropTypes from "prop-types";
import { leagues } from "../../../common/variables/leagues/leagues";
import { Progress } from "antd";
import CenterContent from "../CenterContent/CenterContent";

const LeagueProggressBar = (props) => {
  const { className, userRank, userPoints } = props;
  const currentRank = leagues[userRank];
  const nextRank = leagues[userRank + 1];
  const perecentsOfLeauge =
    ((userPoints - currentRank.minPoints) /
      (nextRank.minPoints - currentRank.minPoints)) *
    100;

  return (
    <div className={`leagues-progress ${className ?? ""}`}>
      {nextRank && (
        <CenterContent>
          {`${userPoints} / ${nextRank.minPoints} pkt.`}
        </CenterContent>
      )}
      <Progress percent={perecentsOfLeauge} status="active" showInfo={false} />
      <CenterContent className="leagues-progress__league">
        {`Ranga: `}
        <strong>{leagues[userRank].name}</strong>
      </CenterContent>
    </div>
  );
};

LeagueProggressBar.propTypes = {
  className: PropTypes.string,
  userRank: PropTypes.number.isRequired,
  userPoints: PropTypes.number.isRequired,
};

export default memo(LeagueProggressBar);
