import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Carousel } from "antd";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../../common/variables/breakpoints/breakpoints";
import SingleRecentlySongSkeleton from "../RecentlyAddedCarousel/SingleRecentlySongSkeleton";
import SingleRecentlySong from "../RecentlyAddedCarousel/SingleRecentlySong";
import { getRecentlyAddedAlbums } from "../../../../Api/songs/getRecentlyAddedAlbums";
import { getSingleAlbum } from "../../../../Api/albums/getSingleAlbum";
import SingleRecentlyAlbum from "./SingleRecentlyAlbum";

const RecomendedCarousel = (props) => {
  const { className } = props;
  const [albumData, setAlbumData] = useState();
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    getRecentlyAddedAlbums()
      .then((res) => {
        let requests = res.map((id) => {
          return getSingleAlbum(id).then((singleResponse) => {
            return {
              id: singleResponse.id,
              name: singleResponse.name,
              author: singleResponse.artist,
              imageUrl: singleResponse.imageUrl,
            };
          });
        });
        Promise.all(requests).then((responses) => {
          setAlbumData(responses);
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const isSmallDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  useEffect(() => {
    let newTiles = [];

    if (albumData) {
      if (isSmallDesktop) {
        for (let i = 0; i < Math.ceil(albumData.length / 3); i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              {albumData.slice(i * 3, (i + 1) * 3).map((singleSongData) => (
                <SingleRecentlyAlbum albumData={singleSongData} />
              ))}
            </div>
          );
        }
      } else {
        newTiles = albumData.map((singleSongData) => (
          <div className="recently-added-carousel__item">
            <SingleRecentlyAlbum albumData={singleSongData} />
          </div>
        ));
      }
    } else {
      if (isSmallDesktop) {
        for (let i = 0; i < 3; i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              <SingleRecentlySongSkeleton />
              <SingleRecentlySongSkeleton />
              <SingleRecentlySongSkeleton />
            </div>
          );
        }
      } else {
        for (let i = 0; i < 9; i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              <SingleRecentlySongSkeleton />
            </div>
          );
        }
      }
    }

    setTiles(newTiles);
  }, [isSmallDesktop, albumData]);

  return (
    <>
      <h2 className="recently-added-header">OSTATNIO DODANE ALBUMY</h2>
      {tiles && (
        <Carousel
          className="recently-added-carousel"
          arrows={isSmallDesktop}
          draggable
          autoplay
          autoplaySpeed={5000}
          speed={isSmallDesktop ? 1000 : 400}
        >
          {tiles}
        </Carousel>
      )}
    </>
  );
};

RecomendedCarousel.propTypes = {
  className: PropTypes.string,
};

export default memo(RecomendedCarousel);
