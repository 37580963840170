import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import MetaData from "../../common/components/MetaData/MetaData";
import SongRanking from "../../common/components/SongRanking/SongRanking";
import { getPopularRanking } from "../../Api/rankings/content/getPopularRanking";
import { useLocation } from "react-router-dom";
import { Select } from "antd";

const { Option } = Select;

const ContactPage = () => {
  const location = useLocation();

  const [results, _setResults] = useState();
  const [lastSongId, _setLastSongId] = useState("");
  const [lastSentQuerySongId, _setLastSentQuerySongId] = useState("");
  const [loading, _setLoading] = useState(true);
  const [endOfResults, _setEndOfResults] = useState(false);
  const [selectedYear, _setSelectedYear] = useState(2021);

  const resultsRef = useRef(results);
  const setResults = (data) => {
    resultsRef.current = data;
    _setResults(data);
  };

  const lastSongIdRef = useRef(lastSongId);
  const setLastSongId = (data) => {
    lastSongIdRef.current = data;
    _setLastSongId(data);
  };

  const lastSentQuerySongIdRef = useRef(lastSentQuerySongId);
  const setLastSentQuerySongId = (data) => {
    lastSentQuerySongIdRef.current = data;
    _setLastSentQuerySongId(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  };

  const endOfResultsRef = useRef(endOfResults);
  const setEndOfResults = (data) => {
    endOfResultsRef.current = data;
    _setEndOfResults(data);
  };

  const selectedYearRef = useRef(selectedYear);
  const setSelectedYear = (data) => {
    selectedYearRef.current = data;
    _setSelectedYear(data);
  };

  const lazyLoad = useCallback(() => {
    const scrollNearTheBottom =
      document.documentElement.scrollHeight - window.innerHeight - 100 <=
      Math.trunc(window.scrollY);
    if (
      scrollNearTheBottom &&
      lastSentQuerySongIdRef.current !== lastSongIdRef.current &&
      !loadingRef.current
    ) {
      setLoading(true);
      getPopularRanking(lastSongIdRef.current, selectedYearRef.current)
        .then((res) => {
          if (res.length > 0) {
            const newResults = [];

            res.forEach((singleResponse) => {
              newResults.push({
                id: singleResponse.id,
                name: singleResponse.name,
                author: singleResponse.artist,
                additionalArtists: singleResponse.additionalArtists,
                album: singleResponse.album,
                releaseDate: singleResponse.year,
                rate: singleResponse.avarageRate,
                numberOfVotes: singleResponse.numberOfRates,
                imageUrl: singleResponse.imageUrl,
              });
            });

            setLastSentQuerySongId(lastSongIdRef.current);
            setResults([...resultsRef.current, ...newResults]);
            setLoading(false);
          } else {
            setLastSentQuerySongId(lastSongIdRef.current);
            setEndOfResults(true);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", lazyLoad);

    return () => {
      window.removeEventListener("scroll", lazyLoad);
    };
  }, [location]);

  //LOAD CONTENT ON MOUNT
  useEffect(() => {
    setResults([]);
    setLoading(true);
    setEndOfResults(false);

    setLastSentQuerySongId(undefined);
    getPopularRanking("", selectedYear)
      .then((res) => {
        const newResults = [];

        res.forEach((singleResponse) => {
          newResults.push({
            id: singleResponse.id,
            name: singleResponse.name,
            author: singleResponse.artist,
            additionalArtists: singleResponse.additionalArtists,
            album: singleResponse.album,
            releaseDate: singleResponse.year,
            rate: singleResponse.avarageRate,
            numberOfVotes: singleResponse.numberOfRates,
            imageUrl: singleResponse.imageUrl,
          });
        });

        setResults(newResults);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(true);
      });
  }, [selectedYear]);

  useEffect(() => {
    if (results !== undefined && results.length > 0) {
      setLastSongId(results[results.length - 1].id);
    }
  }, [results]);

  const selectYearHandler = (value) => {
    setSelectedYear(value);
  };

  return (
    <section>
      <MetaData
        title={"Popularne - Rap Krytyk"}
        description="Popularne - najpopularniejsze utwory na platformie rapkrytyk.pl - oceniaj, recenzuj, odkrywaj."
      />
      <PageContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          {selectedYear !== "undefined" && <h2 className="mr-4">ROK</h2>}
          <Select
            defaultValue="2021"
            style={{ fontSize: "22px", color: "#f8c165", width: "200px" }}
            onChange={selectYearHandler}
          >
            <Option value="2021">2021</Option>
            <Option value="2020">2020</Option>
            <Option value="2019">2019</Option>
            <Option value="undefined">WSZECHCZASÓW </Option>
          </Select>
        </div>
        <SongRanking
          title={"Najczęściej oceniane"}
          showPosition={true}
          songRankingData={results}
          loading={loading}
          endOfResults={endOfResults}
        />
      </PageContainer>
    </section>
  );
};

export default memo(ContactPage);
