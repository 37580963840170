import axios from "axios";
import { urls } from "../../Router/urls";
import { getIfUserLiked } from "./getIfUserLiked";
import { getSingleReview } from "./getSingleReview";

export const getUserReviews = (email, currentUserEmail) => {
  return axios
    .get(`${urls.base}/api/get-user-reviews?user=${email}`)
    .then((res) => {
      let requests = res.data.map((review) => {
        return getSingleReview(review.id, email).then((reviewDataResponse) => {
          return getIfUserLiked(review.id, email, currentUserEmail).then(
            (likeResponse) => {
              return {
                ...reviewDataResponse.singleReview,
                id: review.id,
                liked: likeResponse.liked,
              };
            }
          );
        });
      });
      return Promise.all(requests).then((responses) => {
        return responses;
      });
    })
    .catch((error) => {
      return error.response;
    });
};
