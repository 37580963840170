import React, { memo } from "react";
import PropTypes from "prop-types";

const GrayText = (props) => {
  const { children, className } = props;
  return <span className={`c-gray ${className ?? ""}`}>{children}</span>;
};

GrayText.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default memo(GrayText);
