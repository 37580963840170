import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import InputField from "../../../../common/components/InputField/InputField";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import Btn from "../../../../common/components/Btn/Btn";

const AddAlbumSongForm = (props) => {
  const { className, onSubmitAction } = props;

  const [form] = Form.useForm();
  const [additionalArtists, setAdditionalArtists] = useState([]);

  const onFinish = (values) => {
    onSubmitAction({ ...values, additionalArtists });
    form.resetFields();
    setAdditionalArtists([]);
  };

  const addAdditionalArtistHandler = () => {
    setAdditionalArtists([...additionalArtists, ""]);
  };

  const updateAdditionalArtistsHandler = (e, index) => {
    const newArr = [...additionalArtists];
    newArr[index] = e.target.value;
    setAdditionalArtists(newArr);
  };

  return (
    <section className={`${className ?? ""}`}>
      <Form onFinish={onFinish} form={form}>
        {additionalArtists.length > 0 &&
          additionalArtists.map((v, index) => (
            <div className="mb-4">
              <InputField
                label="Wykonawca dodatkowy*"
                key={index}
                onChange={(e) => updateAdditionalArtistsHandler(e, index)}
              />
            </div>
          ))}
        <CenterContent className="mb-4">
          <Btn onClick={addAdditionalArtistHandler}>
            Dodaj kolejnego wykonawcę utworu
          </Btn>
        </CenterContent>
        <div className="mb-4">
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField label="Nazwa utworu*" />
          </Form.Item>
        </div>
        <div className="mb-6">
          <Form.Item
            name="ytLink"
            rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
            validateTrigger="onSubmit"
          >
            <InputField label="Link do utworu na YouTube*" />
          </Form.Item>
        </div>
        <Form.Item>
          <CenterContent>
            <Btn type="primary" htmlType="submit">
              Dodaj utwór
            </Btn>
          </CenterContent>
        </Form.Item>
      </Form>
    </section>
  );
};

AddAlbumSongForm.propTypes = {
  className: PropTypes.string,
  onSubmitAction: PropTypes.func,
};

export default memo(AddAlbumSongForm);
