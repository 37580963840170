import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import TextField from "../../../../common/components/TextField/TextField";
import Btn from "../../../../common/components/Btn/Btn";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { Form } from "antd";
import { postComment } from "../../../../Api/articles/postComment";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../../../../common/components/Spinner/Spinner";
import { urls } from "../../../../Router/urls";
import { getUserLikedYourCommentList } from "../../../../Api/articles/getUserLikedYourCommentList";
import { getComment } from "../../../../Api/articles/getComment";
import UserLikedList from "../../../SingleSongPage/components/ReviewSong/UserLikedList/UserLikedList";
import { updateUserComment } from "../../../../Api/articles/updateUserComment";
import { addComment } from "../../../../Redux/actions/userActions";

const CreateComment = (props) => {
  const { userAuthentiacated, userCredentials, type, id, addComment } = props;
  const history = useHistory();
  const [form] = Form.useForm();
  const [reviewDescriptionColor, setReviewDescriptionColor] = useState(
    "c-gray"
  );
  const [alreadyReviewed, setAlreadyReviewed] = useState(false);
  const [clickedEdit, setClickedEdit] = useState(false);
  const [numberOfLikes, setNumberOfLikes] = useState();
  const [loading, setLoading] = useState(false);
  const [usersLikedLoading, setUsersLikedLoading] = useState(false);
  const [usersLikedList, setUsersLikedList] = useState(false);

  useEffect(() => {
    if (userAuthentiacated && userCredentials.email) {
      getComment(id, userCredentials.email, type).then((data) => {
        if (data.singleComment) {
          setAlreadyReviewed(true);
          setNumberOfLikes(data.singleComment.likes);
          form.setFieldsValue({ review: data.singleComment.commentText });
        }
      });
    }
  }, [userAuthentiacated, userCredentials]);

  const onFinish = (data) => {
    if (userCredentials.email) {
      setLoading(true);
      setReviewDescriptionColor("c-gray");
      if (alreadyReviewed) {
        updateUserComment({
          email: userCredentials.email,
          id: id,
          commentText: data.review,
          type,
        }).then(() => {
          setLoading(false);
          window.location.reload();
        });
      } else {
        postComment({
          email: userCredentials.email,
          nickName: userCredentials.nickName,
          id: id,
          commentText: data.review,
          type: type,
        }).then(() => {
          setLoading(false);
          addComment();
          setAlreadyReviewed(true);
        });
      }
    } else {
      history.push(urls.login);
    }
  };

  const onFinishFailed = (errorInfo) => {
    if (
      errorInfo.errorFields.find((errorField) =>
        errorField.name.includes("review")
      )
    )
      setReviewDescriptionColor("c-red");
  };

  const checkWhoHandler = () => {
    setUsersLikedLoading(true);
    getUserLikedYourCommentList(id, userCredentials.email, type)
      .then((res) => {
        setUsersLikedLoading(false);
        setUsersLikedList(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {alreadyReviewed && !clickedEdit ? (
        <div className="alredy-reviewed-baner">
          <h2>Dziękujemy za dodany komentarz.</h2>
          <Btn className="mt-2 mb-10" onClick={() => setClickedEdit(true)}>
            Edytuj swój komentarz
          </Btn>
          <h2>
            {numberOfLikes > 0 && (
              <span>
                <strong className="c-yellow">{numberOfLikes}</strong>
                <span>
                  {" "}
                  użytkowników uważa, że twoja recenzja jest świetna!
                </span>
              </span>
            )}
          </h2>
          {numberOfLikes > 0 && !usersLikedLoading && !usersLikedList && (
            <Btn className="mt-2 mb-4" onClick={checkWhoHandler}>
              Sprawdź kto!
            </Btn>
          )}
          {usersLikedLoading && <Spinner />}
          {usersLikedList.length > 0 && !usersLikedLoading && (
            <UserLikedList userList={usersLikedList} className="mt-2 mb-2" />
          )}
        </div>
      ) : (
        <>
          <h2>Twój komentarz artykułu</h2>
          <Form
            form={form}
            className="review-song mb-6"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <div className={`pb-2 ${reviewDescriptionColor}`}>
              *Powinien zawierać minimum 3 znaki
            </div>
            <Form.Item
              name="review"
              rules={[
                { min: 3, message: " " },
                { required: true, message: " " },
              ]}
              validateTrigger="onSubmit"
            >
              <TextField
                placeholder="Napisz swoją recenzję utworu"
                className="mb-4"
                rowSize={3}
              />
            </Form.Item>
            <Form.Item>
              <CenterContent>
                {loading ? (
                  <Spinner />
                ) : (
                  <Btn type="primary" htmlType="submit">
                    {alreadyReviewed
                      ? "Edytuj swój komentarz"
                      : "Prześlij komentarz"}
                  </Btn>
                )}
              </CenterContent>
            </Form.Item>
          </Form>
        </>
      )}
    </>
  );
};

CreateComment.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  userAuthentiacated: state.user.authenticated,
  userCredentials: state.user.credentials,
});

const mapDispatchToProps = {
  addComment,
};

export default memo(
  connect(mapStatetoProps, mapDispatchToProps)(CreateComment)
);
