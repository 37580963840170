import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import RegisterAdvantageBaner from "../RegisterPage/components/RegisterAdvantageBaner/RegisterAdvantageBaner";
import { urls } from "../../Router/urls";
import { Link } from "react-router-dom";
import MetaData from "../../common/components/MetaData/MetaData";
import { Form } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import CenterContent from "../../common/components/CenterContent/CenterContent";
import Btn from "../../common/components/Btn/Btn";
import InputField from "../../common/components/InputField/InputField";
import { postResetPasswordEmail } from "../../Api/emails/postResetPassordEmail";
import {
  EyeOutlined,
  EyeInvisibleOutlined,
  FrownOutlined,
} from "@ant-design/icons";
import { verifyNewPasswordCode } from "../../Api/newPassword/verifyNewPasswordCode";
import Spinner from "../../common/components/Spinner/Spinner";
import { postNewPassword } from "../../Api/newPassword/postNewPassword";
import { showNotificationAction } from "../../Redux/actions/notificationActions";
import { connect } from "react-redux";
import Message from "../../common/components/Message/Message";

const NewPasswordPage = (props) => {
  const { className, showNotificationAction } = props;
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const code = new URLSearchParams(location.search).get("code");

  const [validCode, setValidCode] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [generalError, setGeneralError] = useState(false);

  useEffect(() => {
    verifyNewPasswordCode({ code }).then((res) => {
      if (res && res.success) {
        setValidCode(true);
        setLoading(false);
      } else {
        setValidCode(false);
        setLoading(false);
      }
    });
  }, [code]);

  const newPasswordHandler = (resetData) => {
    setLoading(true);
    postNewPassword({
      code,
      newPassword: resetData.password,
    }).then((res) => {
      if (res && res.success) {
        setLoading(false);
        showNotificationAction(
          {
            title: "UDAŁO SIĘ!",
            message: "Zaloguj się przy użyciu nowego hasła.",
            icon: "like",
          },
          30
        );
        history.push(urls.login);
      } else {
        setLoading(false);
        setGeneralError(true);
      }
    });
  };

  const requiredRule = { required: true, message: "Proszę uzupełnij te pole." };

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Nowe hasło - Rap Krytyk"}
        description="Podaj nowe hasło do portalu RapKrytyk.pl"
      />
      <PageContainer className={"register-page  mb-20"}>
        <PageTitle>Restart hasła</PageTitle>
        {loading && validCode == undefined && <Spinner />}
        {validCode == false && (
          <>
            <CenterContent>
              <h2>Ten link nie jest już aktywny</h2>
            </CenterContent>
            <CenterContent>
              <FrownOutlined className="no-results-serach__icon" />
            </CenterContent>
            <CenterContent>
              <Link to={urls.forgotPassword}>Spróbuj wygenerować nowy</Link>
            </CenterContent>
          </>
        )}
        {validCode && (
          <Form
            form={form}
            className={`register-page__register`}
            onFinish={newPasswordHandler}
          >
            <div className="mb-4">
              <Form.Item
                name="password"
                rules={[
                  requiredRule,
                  {
                    pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/,
                    validateTrigger: "onSubmit",
                    message:
                      "Hasło musi zawierać minimum: 8 znaków, jedną wileką literę (A-Z) oraz cyfrę (0-9).",
                  },
                ]}
              >
                <InputField
                  label="Hasło*"
                  type={showPassword ? "text" : "password"}
                  icon={
                    showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />
                  }
                  onIconClick={() => setShowPassword((prev) => !prev)}
                />
              </Form.Item>
            </div>
            <div className="mb-6">
              <Form.Item
                name="confimedPassword"
                rules={[
                  requiredRule,
                  {
                    validator: (_, value) => {
                      if (value === form.getFieldValue("password"))
                        return Promise.resolve();
                      else return Promise.reject("error");
                    },
                    validateTrigger: "onSubmit",
                    message: "Podane hasła nie są takie same.",
                  },
                ]}
              >
                <InputField
                  label="Powtórz hasło*"
                  type={showPasswordRepeat ? "text" : "password"}
                  icon={
                    showPasswordRepeat ? (
                      <EyeOutlined />
                    ) : (
                      <EyeInvisibleOutlined />
                    )
                  }
                  onIconClick={() => setShowPasswordRepeat((prev) => !prev)}
                />
              </Form.Item>
            </div>

            {generalError && (
              <CenterContent className="mb-4">
                <Message type="error">
                  Coś poszło nie tak, spróbuj jeszcze raz.
                </Message>
              </CenterContent>
            )}
            {loading ? (
              <Spinner />
            ) : (
              <Form.Item>
                <CenterContent>
                  <Btn
                    type="primary"
                    htmlType="submit"
                    className="register-page__register__submit"
                  >
                    Zrestartuj hasło
                  </Btn>
                </CenterContent>
              </Form.Item>
            )}
          </Form>
        )}
      </PageContainer>
    </section>
  );
};

NewPasswordPage.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = {
  showNotificationAction,
};

export default memo(connect(null, mapDispatchToProps)(NewPasswordPage));
