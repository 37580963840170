import React, { memo } from "react";
import PropTypes from "prop-types";
import PageTitle from "../PageTitle/PageTitle";
import CenterContent from "../CenterContent/CenterContent";
import ArtistList from "./components/ArtistList/ArtistList";
import SingleArtistResultSkeleton from "./components/SingleArtistResult/SingleArtistResultSkeleton";
import NoResultsSearch from "../NoResultsSearch/NoResultsSearch";

const ArtistRanking = (props) => {
  const {
    showPosition,
    title,
    artistRankingData,
    query,
    loading,
    endOfResults,
  } = props;
  return (
    <section className={`artist-ranking`}>
      <PageTitle>{title}</PageTitle>
      {artistRankingData && artistRankingData.length > 0 && (
        <ArtistList artistRankingData={artistRankingData} query={query} />
      )}
      {artistRankingData && artistRankingData.length == 0 && !loading && (
        <NoResultsSearch />
      )}
      {loading && !endOfResults && <SingleArtistResultSkeleton />}
      {endOfResults && <CenterContent>Koniec wyników</CenterContent>}
    </section>
  );
};

ArtistRanking.propTypes = {
  showPosition: PropTypes.bool,
  title: PropTypes.string.isRequired,
  artistRankingData: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.string,
      imageUrl: PropTypes.string,
    })
  ).isRequired,
  query: PropTypes.string,
  loading: PropTypes.bool,
  endOfResults: PropTypes.bool,
};

export default memo(ArtistRanking);
