import axios from "axios";
import { urls } from "../../Router/urls";

export const editArticle = (articleData) => {
  return axios
    .put(`${urls.base}/api/edit-article`, articleData)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
