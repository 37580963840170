import React, { useState } from "react";
import { Button, Menu, message, Alert } from "antd";
import { Link } from "react-router-dom";
import PageContainer from "./common/components/PageContainer/PageContainer";

const { SubMenu } = Menu;

const TestField = () => {
  const [current, setCurrent] = useState("mail");

  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const success = () => {
    const hide = message.loading("Action in progress..", 0);
    // Dismiss manually and asynchronously
    setTimeout(hide, 2500);
  };

  return (
    <PageContainer>
      <Button type="primary">Button</Button>
      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item key="mail">Navigation One</Menu.Item>
        <Menu.Item key="app" disabled>
          Navigation Two
        </Menu.Item>
        <SubMenu title="Navigation Three - Submenu">
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="setting:1">Option 1</Menu.Item>
            <Menu.Item key="setting:2">Option 2</Menu.Item>
          </Menu.ItemGroup>
          <Menu.ItemGroup title="Item 2">
            <Menu.Item key="setting:3">Option 3</Menu.Item>
            <Menu.Item key="setting:4">Option 4</Menu.Item>
          </Menu.ItemGroup>
        </SubMenu>
        <Menu.Item key="alipay">
          <a
            href="https://ant.design"
            target="_blank"
            rel="noopener noreferrer"
          >
            Navigation Four - Link
          </a>
        </Menu.Item>
      </Menu>
      <h1>YES! but why?</h1>
      <div style={{ backgorund: "#CBF3F0" }}>
        akjhsd askldjalkjdlsajd <a href="/bla">chekc out</a> dlsajldjsal alkj
        dlkasj ldj aljd aslj dlksaj ldsajkl dal dsa
      </div>
      <Link to="/track">LINK CHECK</Link>{" "}
      <Button onClick={success}>Display a loading indicator</Button>
      <Alert message="Success Tips" type="success" showIcon closable />
      <Alert message="Informational Notes" type="info" showIcon closable />
      <Alert message="Warning" type="warning" showIcon closable />
      <Alert message="Error" type="error" showIcon closable />
    </PageContainer>
  );
};

export default TestField;
