import axios from "axios";
import { urls } from "../../Router/urls";

export const getCommentsAnonymous = (id, lastDocId, type) => {
  return axios
    .get(
      `${urls.base}/api/get-article-comment-list?id=${id}&lastDocId=${lastDocId}&type=${type}`
    )
    .then((res) => {
      return res.data.reviews;
    })
    .catch((error) => {
      return error.response;
    });
};
