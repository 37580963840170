import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getNewestThreeArticles } from "../../../../Api/articles/getNewestThreeArticles";
import RecommendedArticleTile from "./RecommendedArticleTile";
import { getSingleArticleData } from "../../../../Api/articles/getSingleArticleData";
import ArticleRecommendationSkeleton from "../../skeletons/ArticleRecommendationSkeleton";

const ArticleRecommendation = (props) => {
  const { recommendedList } = props;

  const [articlesData, setArticlesData] = useState();

  useEffect(() => {
    if (recommendedList && recommendedList.length == 3) {
      let requests = recommendedList.map((id) => {
        return getSingleArticleData(id).then((articleData) => {
          return articleData;
        });
      });
      Promise.all(requests).then((res) => {
        setArticlesData(res);
      });
    } else {
      getNewestThreeArticles().then((res) => {
        setArticlesData(res);
      });
    }
  }, [recommendedList]);

  return (
    <section className="article-recommendation-section">
      {articlesData && <h2 className="mb-2 w100">Polecane</h2>}
      {articlesData &&
        articlesData.map((singleArticleData) => (
          <RecommendedArticleTile articleData={singleArticleData} />
        ))}
      {!articlesData && <ArticleRecommendationSkeleton />}
    </section>
  );
};

ArticleRecommendation.propTypes = {
  className: PropTypes.string,
};

export default memo(ArticleRecommendation);
