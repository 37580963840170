import React, { memo } from "react";
import { Select } from "antd";

const OriginSelect = (props) => {
  const { ...rest } = props;

  return (
    <>
      <label for="origin-select">Pochodzenie albumu: </label>
      <Select name="origin-select" {...rest}>
        <Select.Option value="PL">Polska</Select.Option>
        <Select.Option value="FOREIGN">Zagraniczny</Select.Option>
      </Select>
    </>
  );
};

export default memo(OriginSelect);
