import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Tile from "../../../../common/components/Tile/Tile";
import Btn from "../../../../common/components/Btn/Btn";
import { StarFilled } from "@ant-design/icons";

const SingleRecentlySongSkeleton = (props) => {
  return (
    <div className="recently-added-carousel__item__tile">
      <Tile>
        <div className="recently-added-carousel__item__tile__content">
          <div className="recently-added-carousel__item__tile__content__frame">
            <div className="recently-added-carousel__item__tile__content__frame__img-skeleton skeleton"></div>
          </div>
          <div className="recently-added-carousel__item__tile__content__description">
            <h2 className="recently-added-carousel__item__tile__content__description__title-skeleton skeleton"></h2>
            <div className="recently-added-carousel__item__tile__content__description__property">
              <span className="recently-added-carousel__item__tile__content__description__property__label-skeleton skeleton"></span>
            </div>
            <div className="recently-added-carousel__item__tile__content__description__property">
              <span className="recently-added-carousel__item__tile__content__description__property__label-skeleton skeleton"></span>
            </div>
          </div>
          <Btn>
            <StarFilled />
            Oceń utwór
          </Btn>
        </div>
      </Tile>
    </div>
  );
};

export default SingleRecentlySongSkeleton;
