import React, { memo, useEffect, useRef, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { getArtistSearch } from "../../../Api/search/getArtistSearch";
import ArtistRanking from "../../../common/components/ArtistRanking/ArtistRanking";
import PropTypes from "prop-types";

const SearchArtistTab = (props) => {
  const { activeTab } = props;
  const location = useLocation();

  const [results, _setResults] = useState();
  const [title, setTitle] = useState("Wyszukano");
  const [query, _setQuery] = useState();
  const [lastArtistId, _setLastArtistId] = useState("");
  const [lastSentQueryAuthorId, _setLastSentQueryAuthorId] = useState("");
  const [loading, _setLoading] = useState(true);
  const [endOfResults, _setEndOfResults] = useState(false);

  //refs needed for SCROLL LISTENER
  const resultsRef = useRef(results);
  const setResults = (data) => {
    resultsRef.current = data;
    _setResults(data);
  };

  const queryRef = useRef(query);
  const setQuery = (data) => {
    queryRef.current = data;
    _setQuery(data);
  };

  const lastArtistIdRef = useRef(lastArtistId);
  const setLastArtistId = (data) => {
    lastArtistIdRef.current = data;
    _setLastArtistId(data);
  };

  const lastSentQueryAuthorIdRef = useRef(lastSentQueryAuthorId);
  const setLastSentQueryAuthorId = (data) => {
    lastSentQueryAuthorIdRef.current = data;
    _setLastSentQueryAuthorId(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  };

  const endOfResultsRef = useRef(endOfResults);
  const setEndOfResults = (data) => {
    endOfResultsRef.current = data;
    _setEndOfResults(data);
  };

  const lazyLoad = useCallback(() => {
    const scrollNearTheBottom =
      document.documentElement.scrollHeight - window.innerHeight - 100 <=
      Math.trunc(window.scrollY);
    if (
      scrollNearTheBottom &&
      lastSentQueryAuthorIdRef.current !== lastArtistIdRef.current &&
      !loadingRef.current &&
      resultsRef.current.length > 0
    ) {
      setLoading(true);
      getArtistSearch(queryRef.current, lastArtistIdRef.current)
        .then((res) => {
          if (res.length > 0) {
            const newResults = [];

            res.forEach((singleResponse) => {
              newResults.push({
                author: singleResponse.artist,
                imageUrl: singleResponse.imageUrl,
              });
            });

            setResults([...resultsRef.current, ...newResults]);
            setLastSentQueryAuthorId(lastArtistIdRef.current);
            setLoading(false);
          } else {
            setLastSentQueryAuthorId(lastArtistIdRef.current);
            setEndOfResults(true);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  //NEW SEARCH
  useEffect(() => {
    setResults([]);
    setLoading(true);
    setEndOfResults(false);
    setLastSentQueryAuthorId(undefined);
    const newQuery = new URLSearchParams(location.search).get("q");
    setQuery(newQuery);
    setTitle("Wyszukano " + newQuery);

    getArtistSearch(newQuery, "")
      .then((res) => {
        const newResults = [];

        res.forEach((singleResponse) => {
          newResults.push({
            author: singleResponse.artist,
            imageUrl: singleResponse.imageUrl,
          });
        });

        setResults(newResults);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [location]);

  useEffect(() => {
    if (results !== undefined && results.length > 0) {
      setLastArtistId(results[results.length - 1].author);
    }
  }, [results]);

  useEffect(() => {
    window.removeEventListener("scroll", lazyLoad);
    if (activeTab === "artist") {
      window.addEventListener("scroll", lazyLoad);
    }
    if (results && results.length > 0)
      setLastArtistId(results[results.length - 1].author);
  }, [activeTab]);

  return (
    <ArtistRanking
      showPosition={false}
      title={title}
      artistRankingData={results}
      query={query}
      loading={loading}
      endOfResults={endOfResults}
    />
  );
};

SearchArtistTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default memo(SearchArtistTab);
