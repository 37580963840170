import axios from "axios";
import { urls } from "../../Router/urls";

export const postArticleDisLike = (id, type, currentUserEmail) => {
  const ObjectToSend = {
    id,
    type,
    currentUserEmail,
  };
  return axios
    .post(`${urls.base}/api/article-dislike`, ObjectToSend)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
