import React, { memo, useEffect, useState } from "react";
import SearchSongTab from "./components/SearchSongTab";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import SearchArtistTab from "./components/SearchArtistTab";
import SearchAlbumTab from "./components/SearchAlbumTab";
import MetaData from "../../common/components/MetaData/MetaData";
import { Tabs } from "antd";
const { TabPane } = Tabs;

const SearchPage = (props) => {
  const [activeTab, setActiveTab] = useState("song");
  return (
    <PageContainer>
      <MetaData
        title={"Wyszukiwarka - Rap krytyk"}
        description={
          "Wyszukaj utwór, album lub wykonawcę na witrynie rapkrytyk. Oceniaj i recenzuj. Zdobywaj punkty i ścigaj się w rankingach rap krytyków"
        }
      />
      <section>
        <Tabs
          defaultActiveKey={activeTab}
          onChange={(active) => setActiveTab(active)}
        >
          <TabPane tab={"Utwory"} key={"song"}>
            <SearchSongTab activeTab={activeTab} />
          </TabPane>
          <TabPane tab={"Albumy"} key={"album"}>
            <SearchAlbumTab activeTab={activeTab} />
          </TabPane>
          <TabPane tab={"Wykonawcy"} key={"artist"}>
            <SearchArtistTab activeTab={activeTab} />
          </TabPane>
        </Tabs>
      </section>
    </PageContainer>
  );
};

export default memo(SearchPage);
