import React, { memo } from "react";
import PropTypes from "prop-types";
import Btn from "../../../common/components/Btn/Btn";
import { Link } from "react-router-dom";
import { TrophyOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import sectionImage from "../../../images/hp-section-04.jpg";
import { urls } from "../../../Router/urls";
import Slide from "react-reveal/Slide";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../common/variables/breakpoints/breakpoints";

const AddSongSection = (props) => {
  const { className } = props;

  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  return (
    <section className={`rivalry-section ${className ?? ""}`}>
      <div className="rivalry-section__left">
        <h2 className="rivalry-section__left__title">RYWALIZUJ</h2>
        <TrophyOutlined className="rivalry-section__left__icon" />
        <Slide left={isDesktop}>
          <p className="rivalry-section__left__text">
            Za <strong> każdą </strong> recenzję, ocenę i dodanie utworu /
            albumu zostajesz wynagrodzony punktami, dzięki którym możesz
            awanaswoać do kolejnych <Link>rang</Link>. Prowadzimy również{" "}
            <strong>ranking Rap Krytyków</strong>, w którym bierze udział każdy
            zarejestrowany użytkownik. Czy uda Ci się wejść na szczyt? Wszystko
            zależy od <strong>Ciebie</strong>.
          </p>
          <Link
            to={urls.rankingCritics}
            className="rivalry-section__left__link"
          >
            <Btn>Ranking Krytyków</Btn>
          </Link>
        </Slide>
      </div>

      <div className="rivalry-section__right">
        <img
          src={sectionImage}
          alt="Odkrywaj muzykę"
          className="rivalry-section__right__img"
        />
      </div>
    </section>
  );
};

AddSongSection.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(AddSongSection));
