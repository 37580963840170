import React, { memo, useState, useEffect } from "react";
import CoverPhoto from "../../../../common/components/CoverPhoto/CoverPhoto";
import moment from "moment";
import { List } from "antd";
import { YoutubeOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

const BOsongRequestItem = (props) => {
  const { request, addSingiel, addAlbum, reject } = props;
  const [showForm, setShowForm] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");

  const deleteItem = () => {
    if (textareaValue !== "") {
      console.log(textareaValue);
      reject(request.id, textareaValue, request.photoExtension);
    }
  };
  return (
    <div className="bo__song-req__item">
      <CoverPhoto imageSrc={request.imageUrl} />
      <div className="bo__song-req__item__important-value">{`TYP: ${
        request.songList ? "album" : "singiel"
      }`}</div>
      <h2>{`Nazwa: ${request.name}`}</h2>
      <div className="bo__song-req__item__important-value">{`Wykonawca: ${request.artist}`}</div>
      {request.additionalArtists && (
        <div className="bo__song-req__item__important-value">{`Dodatkowi wykonawcy: ${request.additionalArtists}`}</div>
      )}
      <div className="bo__song-req__item__important-value">{`Rok wydania: ${request.year}`}</div>
      <div>{`Pochodzenie: ${request.origin}`}</div>

      {!request.songList && (
        <a href={request.ytLink} alt="yt">
          <YoutubeOutlined />
          YouTube
        </a>
      )}
      <div>{`Stworzone przez: ${request.createdByUser}`}</div>
      <div>{`Kiedy: ${moment(request.createdAt).format(
        "DD/MM/YYYY HH:mm"
      )}`}</div>
      {request.songList && (
        <List
          className="add-album-container__list"
          size="large"
          header={<div>{`Lista utworów:`}</div>}
          bordered
          dataSource={request.songList}
          renderItem={(item) => (
            <List.Item
              className="add-album-container__list__item"
              actions={[
                <span>
                  {item.additionalArtists.length > 0 &&
                    `feat: ${item.additionalArtists.join(", ")}`}
                </span>,
                <a href={item.ytLink} target="_blank" rel="noopener noreferrer">
                  <YoutubeOutlined />
                  YouTube
                </a>,
              ]}
            >
              {item.name}
            </List.Item>
          )}
        />
      )}
      <div className="bo__song-req__item__buttons">
        {request.songList ? (
          <button
            className="bo__song-req__item__buttons__red"
            onClick={() => setShowForm(true)}
          >
            - Odrzuć album
          </button>
        ) : (
          <button
            className="bo__song-req__item__buttons__red"
            onClick={() => setShowForm(true)}
          >
            - Odrczuć singiel
          </button>
        )}
        {request.songList ? (
          <button
            className="bo__song-req__item__buttons__green"
            onClick={() => addAlbum(request.id)}
          >
            + Dodaj album
          </button>
        ) : (
          <button
            className="bo__song-req__item__buttons__green"
            onClick={() => addSingiel(request.id)}
          >
            + Dodaj singiel
          </button>
        )}
      </div>
      {showForm && (
        <div className="bo__song-req__item__form">
          <textarea
            className="bo__song-req__item__form__textarea"
            value={textareaValue}
            onChange={(e) => setTextareaValue(e.target.value)}
          />
          <button
            className="bo__song-req__item__buttons__red"
            onClick={deleteItem}
          >
            ODRZUĆ
          </button>
        </div>
      )}
    </div>
  );
};

BOsongRequestItem.propTypes = {
  request: PropTypes.object,
  addSingiel: PropTypes.func,
  addAlbum: PropTypes.func,
  reject: PropTypes.func,
};

export default memo(BOsongRequestItem);
