import React, { memo } from "react";

const HeaderImage = (props) => {
  const { src, tags } = props;

  return (
    <div className="article-img">
      <img src={src} alt="nagłówek artykułu" />
      <div className="article-img__tags-block">
        {tags.map((tag) => (
          <strong className="article-img__tags-block__tag">{tag}</strong>
        ))}
      </div>
    </div>
  );
};

export default memo(HeaderImage);
