import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../../../common/components/Spinner/Spinner";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import ResultList from "../../../common/components/SongRanking/components/ResulsList/ResultList";
import { getSingleSong } from "../../../Api/songs/getSingleSong";
import { getUserRates } from "../../../Api/rate/getUserRates";
import NoResultsSearch from "../../../common/components/NoResultsSearch/NoResultsSearch";
import PageContainer from "../../../common/components/PageContainer/PageContainer";
import PageTitle from "../../../common/components/PageTitle/PageTitle";

const MyContentRanking = (props) => {
  const { contentType, user } = props;
  const [listData, setListData] = useState();
  const [loading, setLoading] = useState(true);

  const title = {
    bit: "najlepszy bit",
    lirycs: "najlepsze słowa",
    vocal: "najlepszy wokal",
    funny: "energiczna",
    neutral: "chill",
    sad: "smutna",
    serious: "poważna",
    showOff: "flex",
    motivation: "storytelling",
  };

  useEffect(() => {
    if (user.credentials.email) {
      setLoading(true);
      getUserRates(user.credentials.email)
        .then((res) => {
          const newList = [];
          if (
            contentType === "bit" ||
            contentType === "lirycs" ||
            contentType === "vocal"
          ) {
            res.forEach((record) => {
              if (
                record.type &&
                contentType === "bit" &&
                record.bit > record.lirycs &&
                record.bit > record.vocal &&
                record.overall >= 8
              ) {
                newList.push({ id: record.id, overall: record.overall });
              } else if (
                record.type &&
                contentType === "lirycs" &&
                record.lirycs > record.bit &&
                record.lirycs > record.vocal &&
                record.overall >= 8
              ) {
                newList.push({ id: record.id, overall: record.overall });
              } else if (
                record.type &&
                contentType === "vocal" &&
                record.vocal > record.lirycs &&
                record.vocal > record.bit &&
                record.overall >= 8
              ) {
                newList.push({ id: record.id, overall: record.overall });
              }
            });
            return newList.sort((a, b) => a.overall - b.overall).slice(0, 20);
          } else {
            res.forEach((record) => {
              if (
                record.type &&
                record.type === contentType &&
                record.overall >= 8
              ) {
                newList.push({ id: record.id, overall: record.overall });
              }
            });
            return newList.sort((a, b) => a.overall - b.overall).slice(0, 20);
          }
        })
        .then((newList) => {
          if (newList.length > 0) {
            let requests = newList.map((item) => {
              return getSingleSong(item.id).then((singleResponse) => {
                return {
                  id: singleResponse.id,
                  name: singleResponse.name,
                  author: singleResponse.artist,
                  album: singleResponse.album,
                  releaseDate: singleResponse.year,
                  rate: singleResponse.avarageRate,
                  numberOfVotes: singleResponse.numberOfRates,
                  imageUrl: singleResponse.imageUrl,
                };
              });
            });
            Promise.all(requests).then((responses) => {
              setListData(responses);
              setLoading(false);
            });
          } else {
            setListData([]);
            setLoading(false);
          }
        });
    }
  }, [user]);

  return (
    <PageContainer>
      <PageTitle>Twoja lista {title[contentType]}: </PageTitle>
      {loading && (
        <>
          <CenterContent>
            <h1>To może chwilę potrwać</h1>
          </CenterContent>
          <Spinner />
        </>
      )}
      {!loading && listData && listData.length > 0 && (
        <>
          <ResultList
            songRankingData={listData}
            showPosition={true}
            query={""}
          />
          <CenterContent>
            Im więcej ocen oddasz tym dokładniejsze i większe listy stworzysz.
          </CenterContent>
        </>
      )}
      {!loading && listData && listData.length == 0 && (
        <NoResultsSearch
          hideButton={true}
          diffText={
            "Niestety oceniłeś jeszcze za mało utworów. Nie poddawaj się!"
          }
        />
      )}
    </PageContainer>
  );
};

MyContentRanking.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(MyContentRanking));
