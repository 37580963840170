import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
} from "../types/notificationActionTypes";

export const showNotificationAction = (notificationData, timeInSeconds) => (
  dispatch
) => {
  const timeToHide = timeInSeconds ? timeInSeconds * 1000 : 5000;
  dispatch({
    type: SHOW_NOTIFICATION,
    payload: { ...notificationData, duration: timeInSeconds ?? 5 },
  });
  setTimeout(dispatch(hideNotificationAction()), timeToHide);
};

export const hideNotificationAction = () => (dispatch) => {
  dispatch({
    type: HIDE_NOTIFICATION,
  });
};
