import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import PageContainer from "../PageContainer/PageContainer";
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { urls } from "../../../Router/urls";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../variables/breakpoints/breakpoints";
import CenterContent from "../CenterContent/CenterContent";
import { connect } from "react-redux";

const Footer = (props) => {
  const { className, user } = props;
  const [showFullFooter, setShowFullFooter] = useState(false);
  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });
  return (
    <footer className={`footer ${className ?? ""}`}>
      <PageContainer>
        {!isDesktop && (
          <CenterContent className="footer__expand">
            {showFullFooter ? (
              <span onClick={() => setShowFullFooter((prev) => !prev)}>
                Zwiń stopkę <UpOutlined />
              </span>
            ) : (
              <span onClick={() => setShowFullFooter((prev) => !prev)}>
                Rozwiń stopkę <DownOutlined />
              </span>
            )}
          </CenterContent>
        )}
        {(isDesktop || showFullFooter) && (
          <section className="footer__links">
            <div className="footer__links__block">
              {/* <Link to="/forum">Forum</Link> */}
              <Link to={urls.regulations}>Regulamin</Link>
              <Link to={urls.privacyPolicy}>Polityka prywatności</Link>
              <Link to={urls.rodo}>RODO</Link>
            </div>
            <div className="footer__links__block">
              <Link to={user.credentials.email ? urls.addContent : urls.login}>
                Dodaj utwór
              </Link>
              <HashLink to={urls.points}>Punktacja</HashLink>
              <Link to="/ranking/critics/general">Ranking krytyków</Link>
            </div>
            <div className="footer__links__block">
              <Link to={urls.aboutUs}>O nas</Link>
              <Link to={urls.contact}>Kontakt</Link>
            </div>
          </section>
        )}
      </PageContainer>
      <div className="footer__social-media">
        <a
          href={"https://www.instagram.com/rapkrytyk.pl/"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramOutlined />
        </a>
        <a
          href={"https://www.facebook.com/Rapkrytyk-637389456906483"}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookOutlined />
        </a>
        <a
          href={
            "https://www.youtube.com/channel/UCP7aXtH84cWivQp2c8nYvNQ/featured"
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <YoutubeOutlined />
        </a>
      </div>
      <div className="footer__copyright">
        Dziękujemy za wizytę! © 2021 by Rap Krytyk.
      </div>
    </footer>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(Footer));
