import {
  START_AUTHENTICATION,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  SET_USER,
  SET_USER_ERRORS,
  ADD_FIVE_FOR_USER,
  ADD_TEN_FOR_USER,
  LEVEL_UP,
  UPDATE_USER_DESCRIPTION,
} from "../types/userActionTypes";
import { getUserDataApiCall } from "../../Api/authentication/getUserDataApiCall";
import {
  loginWithGooglePopup,
  loginWithFacebookPopup,
  loginWithEmailAndPassword,
  logOutFunction,
} from "../../common/functions/loginWithFunction";
import { showNotificationAction } from "./notificationActions";

export const loginUserAction = (loginData) => (dispatch) => {
  dispatch({
    type: START_AUTHENTICATION,
  });
  loginWithEmailAndPassword(loginData.email, loginData.password);
};

export const getUserDataAction = () => (dispatch) => {
  getUserDataApiCall()
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.userData,
      });
    })
    .catch((err) => console.log(err));
};

export const logoutUser = () => (dispatch) => {
  logOutFunction();
};

export const loginWithGoogleAction = () => (dispatch) => {
  dispatch({
    type: START_AUTHENTICATION,
  });
  loginWithGooglePopup();
};

export const loginWithFacebookAction = () => (dispatch) => {
  dispatch({
    type: START_AUTHENTICATION,
  });
  loginWithFacebookPopup();
};

export const addFiveForUser = () => (dispatch) => {
  dispatch({
    type: ADD_FIVE_FOR_USER,
  });
  dispatch(
    showNotificationAction(
      {
        title: "+5 PUNKTÓW!",
        message:
          "Dziękujemy za twoją ocenę!!! Pniesz się w górę w rankingu krytyków.",
        icon: "like",
        link: "/my-account",
      },
      7
    )
  );
};

export const addTenForUser = () => (dispatch) => {
  dispatch({
    type: ADD_TEN_FOR_USER,
  });
  dispatch(
    showNotificationAction(
      {
        title: "+10 PUNKTÓW!",
        message:
          "Dziękujemy za twoją recenzję!!! Miło nam, że dzielisz się swoim zdaniem. Pniesz się w górę w rankingu krytyków.",
        icon: "like",
        link: "/my-account",
      },
      7
    )
  );
};

export const addComment = () => (dispatch) => {
  dispatch(
    showNotificationAction(
      {
        title: "Dodan komentarz",
        message: "Dzięki, że dzielisz się swoim zdaniem!",
        icon: "like",
      },
      7
    )
  );
};

export const levelUpAction = () => (dispatch) => {
  dispatch({
    type: LEVEL_UP,
  });
  dispatch(
    showNotificationAction(
      {
        title: "NOWA RANGA, GRATULACJE!",
        message:
          "Wow, wbiłeś nową rangę. Przechodząc do strony swojego profilu zobaczysz ile pkt. potrzebujesz do osiągnięcia kolejnej rangi.",
        icon: "fire",
        link: "/my-account",
      },
      30
    )
  );
};

export const updateUserDescriptionAction = (newDescription) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_DESCRIPTION,
    payload: {
      newDescription,
    },
  });
};
