import axios from "axios";
import { urls } from "../../Router/urls";

export const postUserDescription = (description, user) => {
  const objectForPost = {
    description,
    user,
  };
  return axios
    .post(`${urls.base}/api/set-user-description`, objectForPost)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
