export const setErrorsFromBE = (form, response) => {
  const fieldsChanges = [];
  Object.keys(response).forEach((fieldKey) => {
    fieldsChanges.push({
      name: fieldKey,
      errors: [response[fieldKey]],
    });
  });
  form.setFields(fieldsChanges);
};
