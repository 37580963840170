import React, { memo, useState, useEffect } from "react";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import RateStars from "../../../common/components/RateStars/RateStars";
import Btn from "../../../common/components/Btn/Btn";
import { useForm, Controller } from "react-hook-form";
import Message from "../../../common/components/Message/Message";
import { StarFilled, YoutubeOutlined } from "@ant-design/icons";
import { useLocation, Link, useHistory } from "react-router-dom";
import AboutSongStatic from "../../SingleSongPage/components/AboutSong/AboutSongStatic/AboutSongStatic";
import AboutSongStaticSkeleton from "../../SingleSongPage/components/AboutSong/AboutSongStatic/AboutSongStaticSkeleton";
import { getSingleAlbum } from "../../../Api/albums/getSingleAlbum";
import { List } from "antd";
import DetailRates from "../../../common/components/DetailRate/DetailRates";
import { getSingleSong } from "../../../Api/songs/getSingleSong";
import YourOverallRate from "../../../common/components/YourOverallRate/YourOverallRate";
import { postAlbumRate } from "../../../Api/rate/postAlbumRate";
import Spinner from "../../../common/components/Spinner/Spinner";
import { addFiveForUser } from "../../../Redux/actions/userActions";
import { connect } from "react-redux";
import { urls } from "../../../Router/urls";
import UserRate from "../../SingleSongPage/components/AboutSong/AboutSongStatic/UserRate";
import { getSingleUserRate } from "../../../Api/rate/getSingleUserRate";
import SongListSkeleton from "./SongListSkeleton";
import MetaData from "../../../common/components/MetaData/MetaData";

const AboutAlbum = (props) => {
  const { user, addFiveForUser } = props;
  const location = useLocation();
  const history = useHistory();
  const albumId = location.pathname.split("/")[2];

  const {
    register,
    handleSubmit,
    control,
    errors,
    setError,
    getValues,
  } = useForm();
  const [overall, setOverall] = useState();
  const [albumData, setAlbumData] = useState();
  const [detailData, setDetailData] = useState();
  const [songList, setSongList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userRateData, setUserRateData] = useState();

  useEffect(() => {
    getSingleAlbum(albumId)
      .then((res) => {
        setAlbumData({
          id: res.id,
          name: res.name,
          album: res.album,
          artist: res.artist,
          imageUrl: res.imageUrl,
          origin: res.origin,
          year: res.year,
          ytLink: res.ytLink,
          avarageRate: res.avarageRate,
          numberOfRates: res.numberOfRates,
        });

        setDetailData({
          bitAvarage: {
            rate: res.soundAvarage,
            label: "Brzmienie średnia",
          },
          lirycsAvarage: {
            rate: res.lirycsAvarage,
            label: "Słowa średnia",
          },
          vocalAvarage: {
            rate: res.cohesionAvarage,
            label: "Spójność średnia",
          },
        });
        return {
          id: res.id,
          songListShortened: res.songListShortened,
        };
      })
      .then((data) => {
        let requests = data.songListShortened.map((songName) => {
          return getSingleSong(
            `${data.id}_${songName
              .replace(/[^\w\s]/gi, "-") //special characters
              .replace(/\s/g, "") //spaces
              .replace(/_/g, "-") //underscore
              .toLowerCase()}`
          ).then((songRes) => {
            return {
              name: songRes.name,
              additionalArtists: songRes.additionalArtists,
              avarageRate: songRes.avarageRate,
              ytLink: songRes.ytLink,
              albumIndex: songRes.albumIndex,
            };
          });
        });
        return Promise.all(requests).then((responses) => {
          setSongList(responses);
        });
      });
  }, [location]);

  useEffect(() => {
    if (user.credentials.email)
      getSingleUserRate(albumId).then((res) => {
        if (res.overall) setUserRateData(res);
      });
  }, [user.credentials.email]);

  const onSubmit = (data) => {
    let formValid = true;
    const validateField = (fieldName) => {
      const requiredMessage = "Przed zatwierdzeniem proszę uzupełnij te pole.";
      if (data[fieldName] === undefined || data[fieldName] === 0) {
        setError(fieldName, {
          type: "required",
          message: requiredMessage,
        });
        formValid = false;
      }
    };

    //unfortunetly using rc-rate I can't validate with ref
    validateField("sound");
    validateField("lirycs");
    validateField("cohesion");

    if (formValid) {
      setLoading(true);

      postAlbumRate({
        id: albumData.id,
        name: albumData.name,
        ...data,
        overall: overall,
      }).then((res) => {
        setAlbumData({
          ...albumData,
          numberOfRates: albumData.numberOfRates + 1,
          avarageRate: res.avarageRate,
        });

        setDetailData({
          bitAvarage: {
            rate: res.soundAvarage,
            label: "Brzmienie średnia",
          },
          lirycsAvarage: {
            rate: res.lirycsAvarage,
            label: "Słowa średnia",
          },
          vocalAvarage: {
            rate: res.cohesionAvarage,
            label: "Spójność średnia",
          },
        });
        setUserRateData({
          ...data,
          overall: overall,
        });
        setLoading(false);
        addFiveForUser();
      });
    }
  };

  const checkIfAllRatesAreComplete = () => {
    if (user.credentials.email) {
      const valuesObject = getValues(["sound", "lirycs", "cohesion"]);
      const sound = valuesObject.sound;
      const lirycs = valuesObject.lirycs;
      const cohesion = valuesObject.cohesion;

      if (sound && lirycs && cohesion) {
        const overallNewValue = ((sound + lirycs + cohesion) / 3).toFixed(1);
        setOverall(overallNewValue);
      } else setOverall(null);
    } else {
      history.push(urls.login);
    }
  };
  return (
    <heading className="about-song">
      {albumData && (
        <MetaData
          title={`${albumData.name} - Oceniaj i recenzuj albumy Rap Krytyk`}
          description={`${albumData.name} - ${albumData.artist}. Oceniaj, recenzuj albumy. Na rapkrytyk.pl sprawdzaj opinie użytkowników i baw się dobrze słuchając rapu. Zdobywaj punkty.`}
        />
      )}
      {albumData ? (
        <AboutSongStatic
          title={`Album: ${albumData.name}`}
          songData={albumData}
        />
      ) : (
        <AboutSongStaticSkeleton />
      )}
      <div className="mb-14">
        {songList.length > 0 ? (
          <List
            className="add-album-container__list"
            size="large"
            header={
              <h3>
                <strong>{`Lista utworów:`}</strong>
              </h3>
            }
            dataSource={songList}
            renderItem={(item) => (
              <List.Item
                className="add-album-container__list__item"
                actions={[
                  <div className={"add-album-container__list__item__feats"}>
                    {item.additionalArtists &&
                      item.additionalArtists.length > 0 && (
                        <>
                          {"feat: "}
                          {item.additionalArtists.map(
                            (singleAdditionalArtist) => (
                              <Link
                                className="mr-1"
                                to={`/artist/${singleAdditionalArtist
                                  .replace(/[^\w\s]/gi, "-") //special characters
                                  .replace(/\s/g, "") //spaces
                                  .replace(/_/g, "-") //underscore
                                  .toLowerCase()}`}
                              >
                                <strong>{singleAdditionalArtist}</strong>
                              </Link>
                            )
                          )}
                        </>
                      )}
                  </div>,
                  <a
                    href={item.ytLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <YoutubeOutlined />
                    YouTube
                  </a>,
                  <div class="star-container">
                    {item.avarageRate} <StarFilled />
                  </div>,
                ]}
              >
                <Link
                  to={`/song/${albumData.id}_${item.name
                    .replace(/[^\w\s]/gi, "-") //special characters
                    .replace(/\s/g, "") //spaces
                    .replace(/_/g, "-") //underscore
                    .toLowerCase()}`}
                >
                  {`${item.albumIndex}. `} <strong>{item.name}</strong>
                </Link>
              </List.Item>
            )}
          />
        ) : (
          <SongListSkeleton />
        )}
      </div>
      <div>
        {userRateData ? (
          <UserRate userRateData={userRateData} />
        ) : (
          <form className="rate-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="rate-form__single-rate mb-4">
              <Controller
                control={control}
                name={"sound"}
                render={({ onChange, onBlur, value }) => (
                  <>
                    <RateStars
                      onChange={(newVal) => {
                        onChange(newVal);
                        checkIfAllRatesAreComplete();
                      }}
                      label={"Brzmienie"}
                      value={value}
                      ref={register({ required: true })}
                    />
                    {errors.sound && (
                      <CenterContent>
                        <Message type="error">{errors.sound.message}</Message>
                      </CenterContent>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mb-4 rate-form__single-rate">
              <Controller
                control={control}
                name={"lirycs"}
                render={({ onChange, onBlur, value }) => (
                  <>
                    <RateStars
                      onChange={(newVal) => {
                        onChange(newVal);
                        checkIfAllRatesAreComplete();
                      }}
                      label={"Słowa / Nawijka"}
                      value={value}
                    />
                    {errors.lirycs && (
                      <CenterContent>
                        <Message type="error">{errors.lirycs.message}</Message>
                      </CenterContent>
                    )}
                  </>
                )}
              />
            </div>
            <div className="mb-4 rate-form__single-rate">
              <Controller
                control={control}
                name={"cohesion"}
                render={({ onChange, onBlur, value }) => (
                  <>
                    <RateStars
                      onChange={(newVal) => {
                        onChange(newVal);
                        checkIfAllRatesAreComplete();
                      }}
                      label={"Spójność"}
                      value={value}
                    />
                    {errors.cohesion && (
                      <CenterContent>
                        <Message type="error">
                          {errors.cohesion.message}
                        </Message>
                      </CenterContent>
                    )}
                  </>
                )}
              />
            </div>

            {overall && <YourOverallRate overall={overall} showWarn={true} />}

            {loading ? (
              <Spinner />
            ) : (
              <CenterContent className="rate-form__btn-container mt-4">
                <Btn type="primary" onClick={handleSubmit(onSubmit)}>
                  Prześlij opinię
                </Btn>
              </CenterContent>
            )}
          </form>
        )}
        {detailData && (
          <DetailRates className="mt-10" detailRates={detailData} />
        )}
      </div>
    </heading>
  );
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  addFiveForUser,
};

export default memo(connect(mapStatetoProps, mapDispatchToProps)(AboutAlbum));
