import React, { memo, useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import Number from "../../../../common/components/Number/Number";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment-with-locales-es6";

const SingleRecomendedSelectorTile = (props) => {
  const { selectHandler, selectedList } = props;
  const [selectedNumber, setSelectedNumber] = useState();

  const {
    id,
    title,
    headerImg,
    author,
    teaserText,
    tags,
    timeStamp,
  } = props.articleData;

  useEffect(() => {
    if (selectedList && selectedList.includes(id))
      setSelectedNumber(selectedList.findIndex((el) => el == id) + 1);
    else setSelectedNumber(undefined);
  }, [selectedList]);

  return (
    <div
      className="recomended-selecetor__tile"
      key={id}
      onClick={() => selectHandler(id)}
    >
      <div className="recomended-selecetor__tile__left">
        <div className="recomended-selecetor__tile__left__img-container">
          <img src={headerImg} alt="article-header" />
          <div className="recomended-selecetor__tile__left__img-container__date">
            {moment(timeStamp).format("DD.MM.YYYY")}
          </div>
        </div>
        <div className="recomended-selecetor__tile__left__text">
          <div className="recomended-selecetor__tile__left__text__author">
            <strong>
              <UserOutlined /> {author}
            </strong>
          </div>
          <h2 className="recomended-selecetor__tile__left__text__title">
            {title}
          </h2>
          <div className="recomended-selecetor__tile__left__text__teaser">
            {teaserText}
          </div>
        </div>
        <div className="recomended-selecetor__tile__left__tags">
          Tagi:{" "}
          {tags.map((tagText) => (
            <strong className="recomended-selecetor__tile__left__tags__single-tag">
              {tagText}
            </strong>
          ))}
        </div>
      </div>
      {selectedNumber && (
        <div className="recomended-selecetor__tile__right">
          <Number className="recomended-selecetor__tile__right__selected-number">
            {selectedNumber}
          </Number>
        </div>
      )}
    </div>
  );
};

SingleRecomendedSelectorTile.propTypes = {
  selectHandler: PropTypes.func.isRequired,
  selectedList: PropTypes.arrayOf(PropTypes.string),
  articleData: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
    headerImg: PropTypes.string,
    id: PropTypes.string,
    link: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.string),
    teaserText: PropTypes.string,
    timeStamp: PropTypes.string,
  }).isRequired,
};

export default memo(SingleRecomendedSelectorTile);
