import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import Btn from "../Btn/Btn";
import CoverPhoto from "../CoverPhoto/CoverPhoto";
import CenterContent from "../CenterContent/CenterContent";

const AddPhoto = (props) => {
  const { id, btnText, sendImageDataToParent } = props;

  const [imagePreview, setImagePreview] = useState();

  const hadnlePhotoChange = (event) => {
    if (event.target.files[0].size <= 519887) {
      const image = event.target.files[0];
      setImagePreview(URL.createObjectURL(image));
      sendImageDataToParent(image);
    }
  };

  const hadnlePhotoAddBtnClick = () => {
    const fileInput = document.getElementById(id);
    fileInput.click();
  };

  return (
    <div>
      <input type="file" id={id} hidden="hidden" onChange={hadnlePhotoChange} />
      {imagePreview && <CoverPhoto imageSrc={imagePreview} />}
      <CenterContent className="c-gray mb-1">
        Maksymalny rozmiar obrazu 500 kb
      </CenterContent>
      <CenterContent>
        <Btn onClick={hadnlePhotoAddBtnClick}>{btnText}</Btn>
      </CenterContent>
    </div>
  );
};

AddPhoto.propTypes = {
  id: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  sendImageDataToParent: PropTypes.func.isRequired,
};

export default memo(AddPhoto);
