import React, { memo } from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

const RadioGroup = (props) => {
  const { className, value, onChange, values } = props;
  const handelChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <Radio.Group
      value={value}
      onChange={handelChange}
      className={`${className ?? ""}`}
    >
      {values.map((RadioVal) => (
        <Radio.Button value={RadioVal.value} key={RadioVal.value}>
          {RadioVal.label}
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

RadioGroup.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ),
};

export default memo(RadioGroup);
