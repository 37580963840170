import React, { memo, useEffect } from "react";
import { notification } from "antd";
import { connect } from "react-redux";
import {
  LikeOutlined,
  FireOutlined,
  SmileOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Notification = (props) => {
  const { notificationData } = props;

  let history = useHistory();

  useEffect(() => {
    const iconText = notificationData.icon;
    const icons = {
      like: <LikeOutlined />,
      fire: <FireOutlined />,
      smile: <SmileOutlined />,
      email: <MailOutlined />,
    };
    const onClickRedirect = () => {
      if (notificationData.link !== "") history.push(notificationData.link);
    };
    if (notificationData.show) {
      notification.open({
        message: notificationData.title,
        description: notificationData.message,
        icon: icons[iconText],
        onClick: onClickRedirect,
        duration: notificationData.duration,
      });
    }
  }, [notificationData.show]);

  return null;
};

const mapStatetoProps = (state) => ({
  notificationData: state.notification,
});

export default connect(mapStatetoProps)(Notification);
