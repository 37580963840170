import React from "react";
import InputField from "../../../../common/components/InputField/InputField";
import OriginSelect from "../OriginSelect/OriginSelect";
import Btn from "../../../../common/components/Btn/Btn";
import PropTypes from "prop-types";
import { Form, DatePicker } from "antd";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import moment from "moment";

const AddAlbumForm = (props) => {
  const { onSubmitAction, albumInfo } = props;
  const [albumForm] = Form.useForm();

  const initialFormValues =
    Object.keys(albumInfo).length > 0
      ? {
          album: albumInfo.album,
          artist: albumInfo.artist,
          year: moment(albumInfo.year, "YYYY"),
          origin: albumInfo.origin,
        }
      : {
          origin: "PL",
        };
  return (
    <Form
      form={albumForm}
      initialValues={initialFormValues}
      className="add-album-container__album-form"
      onFinish={onSubmitAction}
    >
      <Form.Item
        name="artist"
        rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
        validateTrigger="onSubmit"
      >
        <InputField label="Nazwa wykonawcy*" className="mb-4" />
      </Form.Item>
      <Form.Item
        name="album"
        rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
        validateTrigger="onSubmit"
      >
        <InputField label="Nazwa albumu*" className="mb-4" />
      </Form.Item>
      <div className="mb-4">
        <Form.Item
          name="year"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <DatePicker.YearPicker placeholder="Rok wydania*" />
        </Form.Item>
      </div>

      <div className="mb-4">
        <Form.Item
          name="origin"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <OriginSelect />
        </Form.Item>
      </div>
      <Form.Item>
        <CenterContent>
          <Btn type="primary" htmlType="submit">
            Kontynuuj
          </Btn>
        </CenterContent>
      </Form.Item>
    </Form>
  );
};

AddAlbumForm.propTypes = {
  className: PropTypes.string,
  onSubmitAction: PropTypes.func,
  albumInfo: PropTypes.object,
};

export default AddAlbumForm;
