export const urls = {
  base: "https://europe-west1-rapkrytyk-8950a.cloudfunctions.net/api", // on production has to be "https://europe-west1-rapkrytyk-8950a.cloudfunctions.net/api"
  homePage: "/",
  singleSong: "/song/:id",
  singleAlbum: "/album/:id",
  singleArtist: "/artist/:id",
  search: "/search",
  rankingList: "/ranking/list",
  rankingFunny: "/ranking/songs/funny",
  rankingNeutral: "/ranking/songs/neutral",
  rankingSad: "/ranking/songs/sad",
  rankingSerious: "/ranking/songs/serious",
  rankingShowOff: "/ranking/songs/showOff",
  rankingMotivation: "/ranking/songs/motivation",
  rankingAlbums: "/ranking/albums",
  rankingCritics: "/ranking/critics/general",
  rankingPopular: "/ranking/popular",
  login: "/login",
  register: "/register",
  points: "/#points-section",
  addContent: "/add",
  myAccount: "/my-account",
  account: "/account/:id",
  aboutUs: "/about-us",
  contact: "/contact",
  regulations: "/regulations",
  privacyPolicy: "/privacy-policy",
  rodo: "/rodo",
  forgotPassword: "/forgot-password",
  newPassword: "/new-password",
  externalBrowser: "/external-browser",
  myList: "/my-list",
  myListBit: "/my-list/bit",
  myListLirycs: "/my-list/lirycs",
  myListVocal: "/my-list/vocal",
  myListEnergy: "/my-list/energy",
  myListChill: "/my-list/chill",
  myListSad: "/my-list/sad",
  myListSerious: "/my-list/serious",
  myListFlex: "/my-list/flex",
  myListStorytelling: "/my-list/storytelling",
  points: "/points",
  articles: "/articles",
  article: "/article/:id",
  monthSummary: "/month-summary/:id",
  backoffice: {
    songRequests: "/backoffice/song-requests",
    addImage: "/backoffice/add-image",
    addArticle: "/backoffice/add-article",
    editArticle: "/backoffice/edit-article/:id",
    editMonthSummary: "/backoffice/edit-month-summary/:id",
    addLyrics: "/backoffice/add-lyrics/:id",
  },
};
