import axios from "axios";
import { urls } from "../../Router/urls";

export const editUserNickname = (user, newNickName) => {
  const objectForPost = {
    user,
    newNickName,
  };
  return axios
    .post(`${urls.base}/api/edit-nickname`, objectForPost)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error.response;
    });
};
