import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "../../../../../common/components/Tile/Tile";
import Number from "../../../../../common/components/Number/Number";
import moment from "moment";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";

const SingleRequest = (props) => {
  const { className, request } = props;

  const colorClass =
    request.state === "rejected"
      ? "red"
      : request.state === "accepted"
      ? "green"
      : "orange";

  return (
    <li className={`${className ?? "req-history__single-request"}`}>
      <Tile>
        <div className="req-history__single-request__content">
          <div className="req-history__single-request__content__frame">
            {request.state === "rejected" ? (
              <CloseCircleOutlined className="req-history__single-request__content__frame__icon" />
            ) : (
              <img
                className="req-history__single-request__content__frame__img"
                src={request.imageUrl}
                alt="okładka płyty"
              />
            )}
          </div>
          <div className="req-history__single-request__content__description">
            <h2 className="req-history__single-request__content__description__title">
              {request.name}
            </h2>
            <div className="req-history__single-request__content__description__property">
              <span className="req-history__single-request__content__description__property__label">
                Wykonawca:
              </span>
              <span>{request.artist}</span>
            </div>
            <div className="req-history__single-request__content__description__property">
              <span className="req-history__single-request__content__description__property__label">
                Typ:
              </span>
              <span>{request.songList ? "album" : "singiel"}</span>
            </div>
            <div className="req-history__single-request__content__description__property">
              <span className="req-history__single-request__content__description__property__label">
                Data stworzenia prośby:
              </span>
              <span>
                {moment(request.createdAt).format("DD/MM/YYYY HH:mm")}
                <Number></Number>
              </span>
            </div>
          </div>
          <div
            className={`req-history__single-request__content__state-block ${colorClass}`}
          >
            <div>
              Prośba:
              {request.state === "rejected" ? (
                <span>
                  <CloseCircleOutlined /> Odrzucona
                </span>
              ) : request.state === "accepted" ? (
                <span>
                  <CheckCircleOutlined /> Zaakceptowana
                </span>
              ) : (
                <span>
                  <ClockCircleOutlined /> Oczekuje
                </span>
              )}
            </div>
          </div>
          {request.state === "rejected" && (
            <div className="mt-4">
              <strong>Powód:</strong> {request.rejectionReason}
            </div>
          )}
        </div>
      </Tile>
    </li>
  );
};

SingleRequest.propTypes = {
  className: PropTypes.string,
  request: PropTypes.array.isRequired,
};

export default memo(SingleRequest);
