import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Comment, Tooltip, Typography } from "antd";
import Like from "../../../../common/components/Like/Like";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { ArrowDownOutlined } from "@ant-design/icons";
import moment from "moment-with-locales-es6";
import { postAddLike } from "../../../../Api/reviews/postAddLike";
import { disLike } from "../../../../Api/reviews/disLike";
import { Link } from "react-router-dom";

const { Paragraph } = Typography;

const SingleUserTabReview = (props) => {
  const { className, id } = props;
  const {
    name,
    nickName,
    createdAt,
    reviewText,
    likes,
    liked,
    email,
  } = props.reviewData;

  const [currentLikes, setCurrentLikes] = useState(0);
  const [likeClicked, setLikeClicked] = useState(false);

  useEffect(() => {
    setCurrentLikes(likes);
    setLikeClicked(liked);
  }, [props.reviewData]);

  const onLikeClick = () => {
    if (likeClicked) {
      setCurrentLikes(currentLikes - 1);
      setLikeClicked(false);
      disLike({
        id: id,
        reviewEmail: email,
      });
    } else {
      setCurrentLikes(currentLikes + 1);
      setLikeClicked(true);
      postAddLike({
        id: id,
        reviewEmail: email,
      });
    }
  };

  const actions = [
    <div className="pt-2">
      <Like
        number={currentLikes}
        clicked={likeClicked}
        onLikeClick={onLikeClick}
      />
    </div>,
  ];

  return (
    <div className={`review-overview__single ${className ?? ""}`}>
      <CenterContent className="mb-2 pt-6">
        {id.split("_").length > 2 ? (
          <h2>
            <Link to={`/song/${id}`}>Utwór: {name}</Link>
          </h2>
        ) : (
          <h2>
            <Link to={`/album/${id}`}>Album: {name}</Link>
          </h2>
        )}
      </CenterContent>
      <Comment
        actions={actions}
        author={<a className="mb-1">{nickName}</a>}
        content={
          <Paragraph
            className="pt-2"
            ellipsis={{
              rows: 5,
              expandable: true,
              symbol: (
                <CenterContent className="paragraph-expand">
                  <ArrowDownOutlined className="mr-1" />
                  {"rozwiń"}
                </CenterContent>
              ),
            }}
          >
            {reviewText}
          </Paragraph>
        }
        datetime={
          <Tooltip title={moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}>
            <span>{moment(createdAt).locale("pl").fromNow()}</span>
          </Tooltip>
        }
      />
    </div>
  );
};

SingleUserTabReview.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  reviewData: PropTypes.shape({
    name: PropTypes.string,
    liked: PropTypes.bool,
    createdAt: PropTypes.string,
    nickName: PropTypes.string,
    email: PropTypes.string,
    reviewText: PropTypes.string,
    numberOfLikes: PropTypes.number,
  }),
};

export default memo(SingleUserTabReview);
