import React, { memo } from "react";
import PropTypes from "prop-types";

const TextField = (props) => {
  const { className } = props;
  return <div className={`loader ${className}`}></div>;
};

TextField.propTypes = {
  className: PropTypes.string,
};

export default memo(TextField);
