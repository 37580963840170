import axios from "axios";
import { urls } from "../../Router/urls";
import { postAlbumCover } from "./postAlbumCover";
import store from "../../Redux/store";

export const postAlbum = (albumData, image) => {
  const reduxState = store.getState();
  const fileName = image.name;
  const extension = fileName.split(".")[fileName.split(".").length - 1];

  if (reduxState.user.credentials.type === "user") {
    const songRequest = {
      ...albumData,
      photoExtension: extension,
      user: reduxState.user.credentials.email,
    };
    return axios
      .post(`${urls.base}/api/request-album`, songRequest)
      .then((albumResponse) => {
        const albumId = albumResponse.data.id;
        const formData = new FormData();

        formData.append("image", image, `${albumId}.${extension}`);
        return postAlbumCover(formData)
          .then((res) => {
            return albumResponse;
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  } else if (reduxState.user.credentials.type === "admin") {
    const albumDataWithPhotoExtension = {
      ...albumData,
      photoExtension: extension,
    };
    return axios
      .post(`${urls.base}/api/add-album`, albumDataWithPhotoExtension)
      .then((albumResponse) => {
        const albumId = albumResponse.data.id;
        const formData = new FormData();

        formData.append("image", image, `${albumId}.${extension}`);
        return postAlbumCover(formData)
          .then((res) => {
            return albumResponse;
          })
          .catch((err) => console.log(err));
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  }
};
