import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Btn from "../../../common/components/Btn/Btn";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import Number from "../../../common/components/Number/Number";
import { PlusOutlined, AimOutlined } from "@ant-design/icons";
import VisibilitySensor from "react-visibility-sensor";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../common/variables/breakpoints/breakpoints";
import { urls } from "../../../Router/urls";
import { getSongsCount } from "../../../Api/songs/getSongsCount";
import { getRandomSong } from "../../../Api/songs/getRandomSong";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

const CountSection = (props) => {
  const { className, user } = props;
  const history = useHistory();

  const [countVisible, setCountVisible] = useState(false);
  const [songsCount, setSongsCount] = useState(0);

  const isDesktop = useMediaQuery({
    query: breakpoints.desktop,
  });

  useEffect(() => {
    getSongsCount()
      .then((res) => {
        setSongsCount(res.songsCount);
      })
      .catch((err) => console.log(err));
    if (isDesktop) setCountVisible(false);
  }, []);

  return (
    <section className={`home-page__count ${className ?? ""}`}>
      <VisibilitySensor
        onChange={(isVisible) => {
          setCountVisible(isVisible);
        }}
        active={!countVisible}
      >
        {
          <div className="home-page__count__text">
            <Number>
              <strong>
                <CountUp
                  start={countVisible ? 0 : songsCount}
                  end={songsCount}
                  duration={isDesktop ? 1.4 : 2.4}
                />
              </strong>
            </Number>
            <strong>utworów </strong> w naszej bazie
          </div>
        }
      </VisibilitySensor>

      <div className="home-page__count__buttons">
        <Link to={user.credentials.email ? urls.addContent : urls.login}>
          <Btn>
            <PlusOutlined /> Dodaj utwór / album
          </Btn>
        </Link>
      </div>
    </section>
  );
};

CountSection.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(CountSection));
