import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import SingleReviewOverview from "./SingleReviewOverview";
import { useLocation } from "react-router-dom";
import { getReviewsLoggedInUser } from "../../../../Api/reviews/getReviewsLoggedInUser";
import { getNumberOfReviews } from "../../../../Api/reviews/getNumberOfReviews";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { connect } from "react-redux";
import { getReviewsAnonymous } from "../../../../Api/reviews/getReviewsAnonymous";
import SingleReviewOverviewSkeleton from "./SingleReviewOverviewSkeleton";

const ReviewOverview = (props) => {
  const { className, userAuthentiacated, userCredentials } = props;
  const location = useLocation();
  const [displayedReviews, _setDisplayedReviews] = useState();
  const [numberOfReviews, setNumberOfRevies] = useState();
  const [loading, _setLoading] = useState(false);
  const [lastDocId, _setLastDocId] = useState();
  const [lastQueryId, _setLastQueryId] = useState();
  const [endOfResults, _setEndOfResults] = useState(false);

  const idFromUrl = location.pathname.split("/")[2];

  //refs needed for SCROLL LISTENER
  const displayedReviewsRef = useRef(displayedReviews);
  const setDisplayedReviews = (data) => {
    displayedReviewsRef.current = data;
    _setDisplayedReviews(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  };

  const lastDocIdRef = useRef(lastDocId);
  const setLastDocId = (data) => {
    lastDocIdRef.current = data;
    _setLastDocId(data);
  };

  const lastQueryIdRef = useRef(lastQueryId);
  const setLastQueryId = (data) => {
    lastQueryIdRef.current = data;
    _setLastQueryId(data);
  };

  const endOfResultsRef = useRef(endOfResults);
  const setEndOfResults = (data) => {
    endOfResultsRef.current = data;
    _setEndOfResults(data);
  };

  const userCredentialsRef = useRef();
  const setUserCredentialsRef = (data) => {
    userCredentialsRef.current = data;
  };

  const lazyLoad = useCallback((idFromUrl) => {
    const scrollNearTheBottom =
      document.documentElement.scrollHeight - window.innerHeight - 100 <=
      Math.trunc(window.scrollY);
    if (
      scrollNearTheBottom &&
      lastQueryIdRef.current !== lastDocIdRef.current &&
      !loadingRef.current &&
      displayedReviewsRef.current.length >= 10
    ) {
      setLoading(true);
      if (userCredentialsRef.current) {
        getReviewsLoggedInUser(
          idFromUrl,
          userCredentialsRef.current.email,
          lastDocIdRef.current
        )
          .then((res) => {
            if (res.length > 0) {
              setLastQueryId(lastDocIdRef.current);
              setDisplayedReviews([...displayedReviewsRef.current, ...res]);
              setLoading(false);
              setLastDocId(res[res.length - 1].email);
            } else {
              setLastQueryId(lastDocIdRef.current);
              setEndOfResults(true);
              setLoading(false);
            }
          })
          .catch((err) => console.log(err));
      } else {
        getReviewsAnonymous(idFromUrl, lastDocIdRef.current)
          .then((anonymousRes) => {
            if (anonymousRes.length > 0) {
              setLastQueryId(lastDocIdRef.current);
              setDisplayedReviews([
                ...displayedReviewsRef.current,
                ...anonymousRes,
              ]);
              setLoading(false);
              setLastDocId(anonymousRes[anonymousRes.length - 1].email);
            } else {
              setLastQueryId(lastDocIdRef.current);
              setEndOfResults(true);
              setLoading(false);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => lazyLoad(idFromUrl));

    return () => {
      window.removeEventListener("scroll", () => lazyLoad(idFromUrl));
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    getNumberOfReviews(idFromUrl).then((data) => {
      setNumberOfRevies(data.numberOfReviews);
    });
    if (userAuthentiacated && userCredentials.email) {
      setUserCredentialsRef(userCredentials);
      getReviewsLoggedInUser(idFromUrl, userCredentials.email, "").then(
        (res) => {
          setDisplayedReviews(res);
          setLoading(false);
          if (res.length > 0) setLastDocId(res[res.length - 1].email);
        }
      );
    } else {
      getReviewsAnonymous(idFromUrl, "").then((anonymousRes) => {
        setDisplayedReviews(anonymousRes);
        setLoading(false);
        if (anonymousRes.length > 0)
          setLastDocId(anonymousRes[anonymousRes.length - 1].email);
      });
    }
  }, [userAuthentiacated, userCredentials]);

  useEffect(() => {
    if (displayedReviews !== undefined && displayedReviews.length > 0) {
      setLastDocId(displayedReviews[displayedReviews.length - 1].email);
    }
  }, [displayedReviews]);

  return (
    <div className={`review-overview ${className ?? ""}`}>
      <h2 className="mb-4">
        Recenzje {numberOfReviews ? `(${numberOfReviews})` : ""}
      </h2>

      {displayedReviews !== undefined && displayedReviews.length > 0 ? (
        displayedReviews.map((reviewData) => (
          <SingleReviewOverview
            reviewData={reviewData}
            key={reviewData.email}
          />
        ))
      ) : (
        <>{!loading && <CenterContent>BRAK</CenterContent>}</>
      )}
      {loading && <SingleReviewOverviewSkeleton />}
      {endOfResults && (
        <CenterContent className="mt-2 mb-2">
          {" "}
          KONIEC LISTY RECENZJI{" "}
        </CenterContent>
      )}
    </div>
  );
};

ReviewOverview.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  userAuthentiacated: state.user.authenticated,
  userCredentials: state.user.credentials,
});

export default memo(connect(mapStatetoProps)(ReviewOverview));
