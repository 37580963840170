import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import recordBlack from "../../../images/record-black.png";
import recordBronze from "../../../images/record-bronze.png";
import recordSilver from "../../../images/record-silver.png";
import recordGold from "../../../images/record-gold.png";
import recordRubin from "../../../images/record-rubin.png";

const PointsSection = (props) => {
  const { className } = props;

  return (
    <section autoplay className="leagues-section" id="leagues-section">
      <h2>RANGI</h2>
      <div className="leagues-section__main">
        <div className="leagues-section__main__half">
          <h3>Klasyk</h3>
          <ol className="leagues-section__main__half__list">
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                I
              </span>
              Klasyczna płyta
              <span className="leagues-section__main__half__list__item__points">
                ≥ 0pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                II
              </span>
              Podwójny klasyk
              <span className="leagues-section__main__half__list__item__points">
                ≥ 50pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                III
              </span>
              Potrójny klasyk
              <span className="leagues-section__main__half__list__item__points">
                ≥ 100pkt.
              </span>
            </li>
          </ol>
        </div>
        <div className="leagues-section__main__half">
          <div className="leagues-section__main__half__image-container">
            <img src={recordBlack} alt="record-black" />
          </div>
        </div>
        <div className="leagues-section__main__half">
          <div className="leagues-section__main__half__image-container">
            <img src={recordBronze} alt="record-black" />
          </div>
        </div>
        <div className="leagues-section__main__half">
          <h3>Brąz</h3>
          <ol className="leagues-section__main__half__list">
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                I
              </span>
              Brązowa płyta
              <span className="leagues-section__main__half__list__item__points">
                ≥ 150pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                II
              </span>
              Podwójny brąz
              <span className="leagues-section__main__half__list__item__points">
                ≥ 250pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                III
              </span>
              Podwójny brąz
              <span className="leagues-section__main__half__list__item__points">
                ≥ 350pkt.
              </span>
            </li>
          </ol>
        </div>
        <div className="leagues-section__main__half">
          <h3>Srebro</h3>
          <ol className="leagues-section__main__half__list">
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                I
              </span>
              Srebrna płyta
              <span className="leagues-section__main__half__list__item__points">
                ≥ 550pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                II
              </span>
              Podwójne srebro
              <span className="leagues-section__main__half__list__item__points">
                ≥ 750pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                III
              </span>
              Potrójne srebro
              <span className="leagues-section__main__half__list__item__points">
                ≥ 950pkt.
              </span>
            </li>
          </ol>
        </div>
        <div className="leagues-section__main__half">
          <div className="leagues-section__main__half__image-container">
            <img src={recordSilver} alt="record-black" />
          </div>
        </div>
        <div className="leagues-section__main__half">
          <div className="leagues-section__main__half__image-container">
            <img src={recordGold} alt="record-black" />
          </div>
        </div>
        <div className="leagues-section__main__half">
          <h3>Złoto</h3>
          <ol className="leagues-section__main__half__list">
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                I
              </span>
              Złota płyta
              <span className="leagues-section__main__half__list__item__points">
                ≥ 1200pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                II
              </span>
              Podwójne złoto
              <span className="leagues-section__main__half__list__item__points">
                ≥ 1500pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                III
              </span>
              Potrójne złoto
              <span className="leagues-section__main__half__list__item__points">
                ≥ 1800pkt.
              </span>
            </li>
          </ol>
        </div>
        <div className="leagues-section__main__half">
          <h3>Rubin</h3>
          <ol className="leagues-section__main__half__list">
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                I
              </span>
              Rubinowa płyta
              <span className="leagues-section__main__half__list__item__points">
                ≥ 2200pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                II
              </span>
              Podwójny rubin
              <span className="leagues-section__main__half__list__item__points">
                ≥ 2600pkt.
              </span>
            </li>
            <li className="leagues-section__main__half__list__item">
              <span className="leagues-section__main__half__list__item__number">
                III
              </span>
              Potrójny rubin
              <span className="leagues-section__main__half__list__item__points">
                ≥ 3500pkt.
              </span>
            </li>
          </ol>
        </div>
        <div className="leagues-section__main__half">
          <div className="leagues-section__main__half__image-container">
            <img src={recordRubin} alt="record-black" />
          </div>
        </div>
      </div>
    </section>
  );
};

PointsSection.propTypes = {
  className: PropTypes.string,
};

export default memo(PointsSection);
