import axios from "axios";
import { urls } from "../../Router/urls";

export const getIfUserLikedArticle = (id, type, email) => {
  return axios
    .get(
      `${urls.base}/api/check-if-user-liked-article?id=${id}&type=${type}&email=${email}`
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
