import React, { memo, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SongLyricsDisplay from "./SongLyricsDisplay";
import { getLyrics } from "../../../../Api/lyrics/getLyrics";

const LyricsContainer = (props) => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [parts, setParts] = useState();
  const [numberOfWords, setNumberOfWords] = useState();

  useEffect(() => {
    //dely 2 sec because it in the bottom
    setTimeout(() => {
      getLyrics(id).then((res) => {
        if (res.numberOfWords && res.parts) {
          setParts(res.parts);
          setNumberOfWords(res.numberOfWords);
        }
      });
    }, 2000);
  }, []);
  return (
    <>
      {parts && numberOfWords && (
        <SongLyricsDisplay parts={parts} numberOfWords={numberOfWords} />
      )}
    </>
  );
};

export default memo(LyricsContainer);
