import React, { memo } from "react";
import { useMediaQuery } from "react-responsive";
import NavigationPC from "./NavigationPC";
import { breakpoints } from "../../variables/breakpoints/breakpoints";
import NavigationPhone from "./NavigationPhone";

const Navigation = (props) => {
  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });
  return <>{isDesktop ? <NavigationPC /> : <NavigationPhone />}</>;
};

export default Navigation;
