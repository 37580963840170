import React, { memo } from "react";
import PropTypes from "prop-types";
import Btn from "../../../common/components/Btn/Btn";
import { Link } from "react-router-dom";
import { AimOutlined, CompassOutlined } from "@ant-design/icons";
import { getRandomSong } from "../../../Api/songs/getRandomSong";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import sectionImage from "../../../images/hp-section-01.jpg";
import Slide from "react-reveal/Slide";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../common/variables/breakpoints/breakpoints";

const RandomSection = (props) => {
  const { className } = props;
  const history = useHistory();
  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  const randomSongHandler = () => {
    getRandomSong().then((res) => {
      if (res.id === undefined) randomSongHandler();
      else {
        history.push(`/song/${res.id}`);
      }
    });
  };

  return (
    <section className={`random-section ${className ?? ""}`}>
      <div className="random-section__left">
        <img
          src={sectionImage}
          alt="Odkrywaj muzykę"
          className="random-section__left__img"
        />
      </div>

      <div className="random-section__right">
        <h2 className="random-section__right__title">ODKRYWAJ MUZYKĘ</h2>
        <CompassOutlined className="random-section__right__icon" />
        <Slide right={isDesktop}>
          <p className="random-section__right__text">
            <strong>Oceń losowo</strong> to proste narzędzie do odkrywania
            <strong> nowych utworów </strong>
            jak i możliwość przypadkowego powrotu do utworów{" "}
            <strong> zapomnianych</strong>.
          </p>
          <Link className="random-section__right__link">
            <Btn onClick={randomSongHandler}>
              <AimOutlined /> Oceń losowy utwór
            </Btn>
          </Link>
        </Slide>
      </div>
    </section>
  );
};

RandomSection.propTypes = {
  className: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(RandomSection));
