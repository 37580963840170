import React, { memo } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const { TextArea } = Input;

const TextField = (props) => {
  const { className, rowSize, ...rest } = props;
  return (
    <TextArea
      className={`text-field ${className ?? ""}`}
      autoSize={{ minRows: rowSize }}
      {...rest}
    />
  );
};

TextField.propTypes = {
  className: PropTypes.string,
  rowSize: PropTypes.number.isRequired,
};

export default memo(TextField);
