import React, { memo } from "react";
import PropTypes from "prop-types";

const CenterContent = (props) => {
  const { children, className, ...rest } = props;
  return (
    <div className={`center-content ${className ?? ""}`} {...rest}>
      {children}
    </div>
  );
};

CenterContent.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default memo(CenterContent);
