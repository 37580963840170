import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import RegisterAdvantageBaner from "./components/RegisterAdvantageBaner/RegisterAdvantageBaner";
import { urls } from "../../Router/urls";
import { Link } from "react-router-dom";
import RegisterForm from "./components/RegisterFrom/RegisterForm";
import MetaData from "../../common/components/MetaData/MetaData";

const RegisterPage = (props) => {
  const { className } = props;

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Rejestracja - Rap Krytyk"}
        description="Załóż konto, aby mieć możliwość dodawania ocen, recenzji i utworów. Zdobywaj punkty by stać się najlepszym rapkrytykiem. Rap Krytyk to świetna zabawa! Odkrywaj playlisty."
      />
      <PageContainer className={"register-page"}>
        <PageTitle>Rejestracja</PageTitle>
        <RegisterForm />
        <div className="register-page__login-block">
          Posiadasz już konto?
          <Link to={urls.login}>Zaloguj sie!</Link>
        </div>
        <RegisterAdvantageBaner />
      </PageContainer>
    </section>
  );
};

RegisterPage.propTypes = {
  className: PropTypes.string,
};

export default memo(RegisterPage);
