import React, { memo } from "react";
import CenterContent from "../CenterContent/CenterContent";

const CoverPhotoSkeleton = () => {
  return (
    <CenterContent className="mb-6">
      <div className={`cover-photo__frame skeleton`}></div>
    </CenterContent>
  );
};

export default memo(CoverPhotoSkeleton);
