import axios from "axios";
import { urls } from "../../Router/urls";

export const getNewestThreeArticles = () => {
  return axios
    .get(`${urls.base}/api/get-three-last-articles`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
