import React, { memo } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LogoImage from "../../../images/logo.png";

const Logo = (props) => {
  const { children, className } = props;
  return (
    <div className="logo-baner">
      <Link to="/">
        <img src={LogoImage} alt={"Logo"} />
      </Link>
    </div>
  );
};

export default memo(Logo);
