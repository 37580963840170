import React, { memo } from "react";
import PropTypes from "prop-types";
import CenterContent from "../CenterContent/CenterContent";
import Number from "../Number/Number";
import { StarFilled } from "@ant-design/icons";

const DetailRates = (props) => {
  const { className } = props;
  const { bitAvarage, lirycsAvarage, vocalAvarage } = props.detailRates;

  return (
    <section className={`detail-rate ${className}`}>
      <div className="detail-rate__single">
        <strong className="detail-rate__single__label">
          {bitAvarage.label}
        </strong>
        <Number className="detail-rate__single__number">
          {bitAvarage.rate}
        </Number>
        <StarFilled className="detail-rate__single__star" />
      </div>
      <div className="detail-rate__single">
        <strong className="detail-rate__single__label">
          {lirycsAvarage.label}
        </strong>
        <Number className="detail-rate__single__number">
          {lirycsAvarage.rate}
        </Number>
        <StarFilled className="detail-rate__single__star" />
      </div>
      <div className="detail-rate__single">
        <strong className="detail-rate__single__label">
          {vocalAvarage.label}
        </strong>
        <Number className="detail-rate__single__number">
          {vocalAvarage.rate}
        </Number>
        <StarFilled className="detail-rate__single__star" />
      </div>
    </section>
  );
};

CenterContent.propTypes = {
  className: PropTypes.string,
  detailRates: PropTypes.shape({
    bitAvarage: PropTypes.shape({
      rate: PropTypes.number,
      label: PropTypes.string,
    }),
    lirycsAvarage: PropTypes.shape({
      rate: PropTypes.number,
      label: PropTypes.string,
    }),
    vocalAvarage: PropTypes.shape({
      rate: PropTypes.number,
      label: PropTypes.string,
    }),
  }).isRequired,
};

export default memo(DetailRates);
