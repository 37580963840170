import React, { memo } from "react";
import moment from "moment-with-locales-es6";
import Number from "../../../../common/components/Number/Number";

const ArticleTitle = (props) => {
  const { children, timeStamp } = props;

  return (
    <div>
      <h1 className="article-title">{children}</h1>
      {timeStamp && (
        <div className="mt-1 mb-4">
          <Number className="article-time-stamp">
            {moment(timeStamp).format("DD.MM.YYYY")}
          </Number>
        </div>
      )}
    </div>
  );
};

export default memo(ArticleTitle);
