import React, { memo } from "react";
import Tile from "../../../Tile/Tile";

const SingleArtistResultSkeleton = (props) => {
  return (
    <li className="single-artist-result-skeleton">
      <Tile>
        <div className="single-artist-result-skeleton__content">
          <div className="single-artist-result-skeleton__content__frame"></div>
          <h2 className="single-artist-result-skeleton__content__title ">
            <div className="skeleton"></div>
          </h2>
        </div>
      </Tile>
    </li>
  );
};

export default memo(SingleArtistResultSkeleton);
