import axios from "axios";
import { urls } from "../../Router/urls";

export const acceptSong = (songData) => {
  return axios
    .post(`${urls.base}/api/add-song`, songData)
    .then((songResponse) => {
      const objectForPut = { id: songResponse.data.id };
      return axios
        .put(`${urls.base}/api/request-accept`, objectForPut)
        .then(() => {
          return songResponse;
        });
    })
    .catch((error) => {
      console.log(error);
      return error.response;
    });
};
