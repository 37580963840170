import React from "react";
import { FrownOutlined } from "@ant-design/icons";
import Btn from "../Btn/Btn";
import { Link } from "react-router-dom";
import { urls } from "../../../Router/urls";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const NoResultsSearch = (props) => {
  const { hideButton, diffText, user } = props;
  return (
    <div className="no-results-serach">
      <h1 className="no-results-serach__header">
        {diffText ? diffText : "Niestety nie znaleźliśmy tego czego szukasz"}
      </h1>
      <FrownOutlined className="no-results-serach__icon" />
      {!hideButton && (
        <Link to={user.credentials.email ? urls.addContent : urls.login}>
          <Btn>Dodaj utwór / album</Btn>
        </Link>
      )}
    </div>
  );
};

NoResultsSearch.propTypes = {
  hideButton: PropTypes.bool,
  diffText: PropTypes.string,
};
const mapStatetoProps = (state) => ({
  user: state.user,
});

export default connect(mapStatetoProps)(NoResultsSearch);
