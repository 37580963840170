import React, { memo } from "react";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import MetaData from "../../common/components/MetaData/MetaData";

const RegulationsPage = () => {
  return (
    <section>
      <MetaData
        title={"Regulamin - Rap Krytyk"}
        description="Regulamin wirtryny Rap Krytyk, email kontaktowy: rapkrytyk.info@gmail.com"
      />
      <PageContainer>
        <PageTitle>Regulamin serwisu</PageTitle>
        <h2>I. Pojęcia og&oacute;lne</h2>
        <ul>
          <li>
            <strong>Regulamin</strong>&nbsp;&ndash; niniejszy regulamin
          </li>
          <li>
            <strong>Serwis</strong>&nbsp;&ndash; serwis internetowych "Rap
            Krytyk", działających pod adresem https://rapkrytyk.pl
          </li>
          <li>
            <strong>Usługodawca</strong>&nbsp;&ndash; właściciel serwisu będący
            osobą fizyczną - Jakub Wiśniewski
          </li>
          <li>
            <strong>Usługobiorca</strong>&nbsp;&ndash; każda osoba fizyczna,
            uzyskująca dostęp do Serwisu i korzystająca z usług świadczonych za
            pośrednictwem Serwisu przez Usługodawcę.
          </li>
          <li>
            <strong>Komunikacja Drogą Elektroniczną</strong>&nbsp;&ndash;
            Komunikacja pomiędzy stronami za pośrednictwem poczty elektronicznej
            (e-mail) oraz formularzy kontaktowych dostępnych na stronie www.
          </li>
        </ul>
        <h2>II. Postanowienia og&oacute;lne</h2>
        <ul>
          <li>
            Regulamin, określa zasady funkcjonowania i użytkowania Serwisu oraz
            określa zakres praw i obowiązk&oacute;w Usługobiorc&oacute;w i
            Usługodawcy związanych z użytkowaniem Serwisu.
          </li>
          <li>
            Przedmiotem usług Usługodawcy jest udostępnienie nieodpłatnych
            narzędzi w postaci Serwisu, umożliwiających Usługobiorcom dostęp do
            treści w postaci wpis&oacute;w, artykuł&oacute;w i materiał&oacute;w
            audiowizualnych lub aplikacji internetowych i formularzy
            elektronicznych
          </li>
          <li>
            Wszelkie ewentualne treści, artykuły i informacje zawierające cechy
            wskaz&oacute;wek lub porad publikowane na łamach Serwisu są jedynie
            og&oacute;lnym zbiorem informacji i nie są kierowane do
            poszczeg&oacute;lnych Usługobiorc&oacute;w. Usługodawca nie ponosi
            odpowiedzialności za wykorzystanie ich przez Usługobiorc&oacute;w.
          </li>
          <li>
            Usługobiorca bierze na siebie pełną odpowiedzialno za spos&oacute;b
            wykorzystania materiał&oacute;w udostępnianych w ramach Serwisu w
            tym za wykorzystanie ich zgodnie z obowiązującymi przepisami prawa.
          </li>
          <li>
            Usługodawca nie udziela żadnej gwarancji co do przydatności
            materiał&oacute;w umieszczonych w Serwisie.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności z tytułu ewentualnych
            szk&oacute;d poniesionych przez Usługobiorc&oacute;w Serwisu lub
            osoby trzecie w związku z korzystaniem z Serwisu. Wszelkie ryzyko
            związane z korzystaniem z Serwisu, a w szczeg&oacute;lności z
            używaniem i wykorzystywaniem informacji umieszczonych w Serwisie,
            ponosi Usługobiorca korzystający z usług Serwisu.
          </li>
        </ul>
        <h2>III. Warunki używania Serwisu</h2>
        <ul>
          <li>
            Używanie Serwisu przez każdego z Usługobiorc&oacute;w jest
            nieodpłatne i dobrowolne.
          </li>
          <li>
            Usługobiorcy mają obowiązek zapoznania się z Regulaminem oraz
            pozostałymi dokumentami stanowiącymi jego integralną część i muszą
            zaakceptować w całości jego postanowienia w celu dalszego
            korzystania z Serwisu.
          </li>
          <li>
            Usługobiorcy nie mogą wykorzystywać żadnych pozyskanych w Serwisie
            danych osobowych do cel&oacute;w marketingowych.
          </li>
          <li>
            Wymagania techniczne korzystania z Serwisu:
            <ul>
              <li>
                urządzenie z wyświetlaczem umożliwiające wyświetlanie stron
                internetowych,
              </li>
              <li>połączenie z internetem,</li>
              <li>
                dowolna przeglądarka internetowa, kt&oacute;ra wyświetla strony
                internetowe zgodnie ze standardami i postanowieniami Konsorcjum
                W3C i obsługuje strony www udostępniane w języku HTML5,
              </li>
              <li>włączoną obsługę skrypt&oacute;w JavaScript,</li>
              <li>włączoną obsługę plik&oacute;w Cookie</li>
            </ul>
          </li>
          <li>
            W celu zapewnienia bezpieczeństwa Usługodawcy, Usługobiorcy oraz
            innych Usługobiorc&oacute;w korzystających z Serwisu, wszyscy
            Usługobiorcy korzystający z Serwisu powinni stosować się do
            og&oacute;lnie przyjętych&nbsp;
            <a href="https://nety.pl/cyberbezpieczenstwo/zasady-ogolne-korzystania-z-sieci-internet/">
              zasad bezpieczeństwa w sieci
            </a>
            ,
          </li>
          <li>
            Zabrania się działań wykonywanych osobiście przez
            Usługobiorc&oacute;w lub przy użyciu oprorgamowania:
            <ul>
              <li>
                bez zgody pisemnej, dekompilacji i analizy kodu
                źr&oacute;dłowego,
              </li>
              <li>
                bez zgody pisemnej, powodujących nadmierne obciążenie serwera
                Serwisu,
              </li>
              <li>
                bez zgody pisemnej, pr&oacute;b wykrycia luk w zabezpieczeniach
                Serwisu i konfiguracji serwera,
              </li>
              <li>
                podejmowania pr&oacute;b wgrywania lub wszczykiwania na serwer i
                do bazy danych kodu, skrypt&oacute;w i oprogramowania mogących
                wyrządzić szkodę oprogramowaniu Serwisu, innym Usługobiorcom lub
                Usługodawcy,
              </li>
              <li>
                podejmowania pr&oacute;b wgrywania lub wszczykiwania na serwer i
                do bazy danych kodu, skrypt&oacute;w i oprogramowania mogących
                śledzić lub wykradać dane Usługobiorc&oacute;w lub Usługodawcy,
              </li>
              <li>
                podejmowania jakichkolwiek działań mających na celu uszkodzenie,
                zablokowanie działania Serwisu lub uniemożliwienie realizacji
                celu w jakim działa Serwis.
              </li>
            </ul>
          </li>
          <li>
            W przypadku wykrycia zaistnienia lub potencjalnej możliwości
            zaistnienia incydentu Cyberbezpieczeństwa lub naruszenia RODO,
            Usługobiorcy w pierwszej kolejności powinni zgłosić ten fakt
            Usługodawcy w celu szybkiego usunięcia problemu / zagrożenia i
            zabezpieczenia interes&oacute;w wszystkich Usługobiorc&oacute;w
            Serwisu.
          </li>
        </ul>
        <h2>IV. Warunki oraz zasady rejestracji</h2>
        <ul>
          <li>
            Usługobiorcy mogą korzystać z Serwisu bez konieczności rejestracji.
          </li>
          <li>
            Usługobiorcy muszą być zarejestrowani i posiadać konto w Serwisie by
            korzystać z dodatkowych usług świadczonych w Serwisie, dostępnych
            jedynie dla Usługobiorc&oacute;w po zalogowaniu.
          </li>
          <li>Rejestracja w Serwisie jest dobrowolna.</li>
          <li>Rejestracja w Serwisie jest nieodpłatna.</li>
          <li>
            Każdy Usługobiorca może posiadać tylko jedno konto w Serwisie.
          </li>
          <li>
            Wymagania techniczne związane z rejestracją konta:
            <ul>
              <li>
                posiadanie indywidualnego konta poczty elektronicznej e-mail,
              </li>
            </ul>
          </li>
          <li>
            Rejestrujący się w Serwisie Usługobiorcy wyrażają zgodę na
            przetwarzanie ich danych osobowych przez Usługobiorcę w zakresie w
            jakim zostały one wprowadzone do Serwisu podczas procesu rejestracji
            oraz ich p&oacute;źniejszych zmianom lub usunięciu.
          </li>
          <li>
            Usługodawca ma prawo zawieszać lub usuwać konta Usługobiorc&oacute;w
            według własnego uznania, uniemożliwiając lub ograniczając w ten
            spos&oacute;b dostęp do poszczeg&oacute;lnych lub wszystkich usług,
            treści, materiał&oacute;w i zasob&oacute;w Serwisu, w
            szczeg&oacute;lności jeżeli Usługobiorca dopuści się łamania
            Regulaminu, powszechnie obowiązujących przepis&oacute;w prawa, zasad
            wsp&oacute;łżycia społecznego lub działa na szkodę Usługodawcy lub
            innych Usługobiorc&oacute;w, uzasadnionego interesu Usługodawcy oraz
            podmiot&oacute;w trzecich wsp&oacute;łpracujących lub nie z
            Usługodawcą.
          </li>
          <li>
            Wszelkie usługi Serwisu mogą być zmieniane co do ich treści i
            zakresu, dodawane lub odejmowane, a także czasowo zawieszane lub
            dostęp do nich może być ograniczany, według swobodnej decyzji
            Usługodawcy, bez możliwości wnoszenia sprzeciwu w tym zakresie przez
            Usługobiorc&oacute;w.
          </li>
          <li>
            Dodatkowe zasady bezpieczeństwa w zakresie korzystania z konta:
            <ul>
              <li>
                Zabrania się Usługobiorcom zarejestrowanym w Serwisie do
                udostępniania loginu oraz hasła do swojego konta osobom trzecim.
              </li>
              <li>
                Usługodawca nie ma prawa i nigdy nie będzie zażądać od
                Usługobiorcy hasła do wybranego konta.
              </li>
            </ul>
          </li>
          <li>
            Usuwanie konta:
            <ul>
              <li>
                Każdy Usługobiorca posiadający konto w Serwisie ma możliwość
                samodzielnego usunięcia konta z Serwisu.
              </li>
              <li>
                Usługobiorcy mogą to uczynić po zalogowaniu się w panelu w
                Serwisie.
              </li>
              <li>
                Usunięcie konta skutkuje usunięciem wszelkich danych
                identyfikacyjnych Usługobiorcy oraz anonimizacją nazwy
                użytkownika i adresu e-mail.
              </li>
            </ul>
          </li>
        </ul>
        <h2>
          V. Warunki komunikacji i świadczenia pozostałych usług w Serwisie
        </h2>
        <ul>
          <li>
            Serwis udostępnia usługi i narzędzia umożliwiające Usługobiorcom
            interakcję z Serwisem w postaci:
            <ul>
              <li>Formularz kontaktowy</li>
              <li>Komentowania wpis&oacute;w i artykuł&oacute;w</li>
              <li>
                Publikowania własnych treści w postaci wpis&oacute;w i
                artykuł&oacute;w
              </li>
              <li>
                Publikowanie własnych treści w postaci materiał&oacute;w
                graficznych i multimedialnych
              </li>
            </ul>
          </li>
          <li>
            Serwis udostępnia dane kontaktowe w postaci:
            <ul>
              <li>Adresu e-mail</li>
            </ul>
          </li>
          <li>
            W przypadku kontaktu Usługobiorcy z Usługodawcą, dane osobowe
            Usługobiorc&oacute;w będa przetwarzane zgodnie z "
            <a href="https://rapkrytyk.pl/privacy-policy">
              Polityką Prywatności
            </a>
            ", stanowiącą integralną część Regulaminu.
          </li>
          <li>
            Warunki umieszczania treści przez Usługobiorc&oacute;w w Serwisie:
            <ul>
              <li>
                Zabrania się umieszczania w Serwisie treści obraźliwych lub
                oszczerczych względem Usługodawcy, pozostałych
                Usługobiorc&oacute;w, os&oacute;b trzecich oraz podmiot&oacute;w
                trzecich,
              </li>
              <li>
                Zabrania się umieszczania w Serwisie materiał&oacute;w
                tekstowcyh, graficznych, audiowizualnych, skrypt&oacute;w,
                program&oacute;w i innych utwor&oacute;w, na kt&oacute;re
                Usługobiorca nie posiada się licencji, lub kt&oacute;rych autor
                praw majątkowych nie wyraził zgody na darmową publikację,
              </li>
              <li>
                Zabrania się umieszczania w Serwisie treści wulgarnych,
                pornograficznych, erotycznych i niezgodnych z polskim i
                europejskim prawem a także odnośnik&oacute;w do stron
                zawierających wskazane treści,
              </li>
              <li>
                Zabrania się umieszczania w Serwisie skrypt&oacute;w i
                program&oacute;w nadmiernie obciążających serwer, oprogramowania
                nielegalnego, oprogramowania służącego do naruszania
                zabezpieczeń oraz innych podobnych działań a także
                odnośnik&oacute;w do stron zawierających wskazane materiały,
              </li>
              <li>
                Zabrania się umieszczania w Serwisie treści merketingowych i
                reklamujących inne serwisy komercyjne, produkty, usługi czy
                komercyjne strony internetowe
              </li>
            </ul>
          </li>
        </ul>
        <h2>VI. Gromadzenie danych o Usługobiorcach</h2>
        <p>
          W celu prawidłowego świadczenia usług przez Serwis, zabezpieczenia
          prawnego interesu Usługodawcy oraz w celu zapewnienia zgodności
          działania Serwisu z obowiązującym prawem, Usługodawca za pośrednictwem
          Serwisu gromadzi i przetwarza niekt&oacute;re dane o Użytkownikach.
        </p>
        <p>
          W celu prawidłowego świadczenia usług, Serwis wykorzystuje i zapisuje
          niekt&oacute;re anonimowe informacje o Usługobiorcy w plikach cookies.
        </p>
        <p>
          Zakres, cele, spos&oacute;b oraz zasady przetwarzania danych dostępne
          są w załącznikach do Regulaminu: &bdquo;
          <a href="https://rapkrytyk.pl/rodo">Obowiązek informacyjny RODO</a>
          &rdquo; oraz w &bdquo;
          <a href="https://rapkrytyk.pl/privacy-policy">Polityce prywatności</a>
          &bdquo;, stanowiących integralną część Regulaminu.
        </p>
        <ul>
          <li>
            <em>Dane zbierane automatycznie:</em>
            <br />
            Do sprawnego działania Serwisu oraz do statystyk zbieramy
            automatycznie niekt&oacute;re dane o Usługobiorcy. Do danych tych
            należą:
            <ul>
              <li>Adres IP</li>
              <li>Typ przeglądarki</li>
              <li>Rozdzielczość ekranu</li>
              <li>Przybliżona lokalizacja</li>
              <li>Otwierane podstrony serwisu</li>
              <li>Czas spędzony na odpowiedniej podstronie serwisu</li>
              <li>Rodzaj systemu operacyjnego</li>
              <li>Adres poprzedniej podstrony</li>
              <li>Adres strony odsyłającej</li>
              <li>Język przeglądarki</li>
              <li>Predkość łącza internetowego</li>
              <li>Dostawca usług internetowych</li>
              <li>
                Anonimowe dane demograficzne na podstawie danych Google
                Analytics:
                <ul>
                  <li>Płeć</li>
                  <li>Wiek</li>
                  <li>Zainteresowania</li>
                </ul>
              </li>
              <li>
                Anonimowe dane niezbędne do serwowania reklam:
                <ul>
                  <li>
                    Dane związane z raportowaniem o wyświetlanych reklamach
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              Powyższe dane uzyskiwane są poprzez skrypt Google Analytics i są
              anonimowe.
            </p>
          </li>
          <li>
            <em>Dane zbierane podczas rejestracji:</em>
            <br />
            Nazwa użytkownika, imię i nazwisko, adres e-mail
            <p>
              W przypadku Usługobiorc&oacute;w zalogowanych (posiadających konto
              w Serwisie), w plikach cookies zapisywanych na urządzeniu
              Usługobiorcy może być umieszczony identyfikator Usługobiorcy
              powiązany z kontem Usługobiorcy
            </p>
          </li>
        </ul>
        <h2>VII. Prawa autorskie</h2>
        <ul>
          <li>
            Właścicielem Serwisu oraz praw autorskich do serwisu jest
            Usługodawca.
          </li>
          <li>
            Część danych zamieszczonych w Serwisie są chronione prawami
            autorskimi należącymi do firm, instytucji i os&oacute;b trzecich,
            niepowiązanych w jakikolwiek spos&oacute;b z Usługodawcą, i są
            wykorzystywane na podstawie uzyskanych licencji, lub opartych na
            licencji darmowej.
          </li>
          <li>
            Na podstawie Ustawy z dnia 4 lutego 1994 o prawie autorskim zabrania
            się wykorzystywania, kopiowania, reprodukowania w jakiejkolwiek
            formie oraz przetrzymywania w systemach wyszukiwania z wyłączeniem
            wyszukiwarki Google, Bing, Yahoo, NetSprint, DuckDuckGo, Facebook
            oraz LinkedIn jakichkolwiek artykuł&oacute;w, opis&oacute;w, zdjęć
            oraz wszelkich innych treści, materiał&oacute;w graficznych, wideo
            lub audio znajdujących się w Serwisie bez pisemnej zgody lub zgody
            przekazanej za pomocą Komunikacji Drogą Elektroniczną ich prawnego
            właściciela.
          </li>
          <li>
            Zgodnie z Ustawą z dnia 4 lutego 1994 o prawie autorskim ochronie
            nie podlegają proste informacje prasowe, rozumiane jako same
            informacje, bez komentarza i oceny ich autora. Autor rozumie to jako
            możliwość wykorzystywania informacji z zamieszczonych w serwisie
            tekst&oacute;w, ale już nie kopiowania całości lub części
            artykuł&oacute;w o ile nie zostało to oznaczone w
            poszczeg&oacute;lnym materiale udostępnionym w Serwisie.
          </li>
        </ul>
        <h2>VIII. Zmiany Regulaminu</h2>
        <ul>
          <li>
            Wszelkie postanowienia Regulaminu mogą być w każdej chwili
            jednostronnie zmieniane przez Usługodawcę, bez podawania przyczyn.
          </li>
          <li>
            Informacja o zmianie Regulaminu będzie rozsyłana Drogą Elektroniczną
            do Usługobiorc&oacute;w zarejestrowanych w Serwisie.
          </li>
          <li>
            W przypadku zmiany Regulaminu jego postanowienia wchodzą w życie
            natychmiast po jego publikacji dla Usługobiorc&oacute;w
            nieposiadających konta w Serwisie.
          </li>
          <li>
            W przypadku zmiany Regulaminu jego postanowienia wchodzą w życie z
            7-dniowym okresem przejściowym dla Usługobiorc&oacute;w
            posiadających konta w Serwisie zarejestrowane przez zmianą
            Regulaminu.
          </li>
          <li>
            Traktuje się iż każdy Usługobiorca, kontynuujący korzystanie z
            Serwisu po zmianie Regulaminu akceptuje go w całości.
          </li>
        </ul>
        <h2>IX. Postanowienia końcowe</h2>
        <ul>
          <li>
            Usługodawca nie odpowiada w żaden spos&oacute;b, jak tylko pozwalają
            na to obowiązujące przepisy prawa, za treści przekazywane i
            publikowane w Serwisie przez Usługobiorc&oacute;w, za ich
            prawdziwość, rzetelność, autentyczność czy wady prawne.
          </li>
          <li>
            Usługodawca dokona wszelkich starań by usługi Serwisu były oferowane
            w spos&oacute;b ciągły. Nie ponosi on jednak żadnej
            odpowiedzialności za zakł&oacute;cenia spowodowane siłą wyższą lub
            niedozwoloną ingerencją Usługobiorc&oacute;w, os&oacute;b trzecich
            czy działalnością zewnętrznych automatycznych program&oacute;w.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do zmiany jakichkolwiek informacji
            umieszczonych w Serwisie w wybranym przez Usługodawcę terminie, bez
            konieczności uprzedniego powiadomienia Usługobiorc&oacute;w
            korzystających z usług Serwisu.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do czasowego, całkowitego lub
            częściowego wyłączenia Serwisu w celu jego ulepszenia, dodawania
            usług lub przeprowadzania konserwacji, bez wcześniejszego
            uprzedzania o tym Usługobiorc&oacute;w.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do wyłączenia Serwisu na stałe,
            bez wcześniejszego uprzedzania o tym Usługobiorc&oacute;w.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do dokonania cesji w części lub w
            całości wszelkich swoich praw i obowiązk&oacute;w związanych z
            Serwisem, bez zgody i możliwości wyrażania jakichkolwiek
            sprzeciw&oacute;w przez Usługobiorc&oacute;w.
          </li>
          <li>
            Obowiązujące oraz poprzednie Regulaminy Serwisu znajduję się na tej
            podstronie pod aktualnym Regulaminem.
          </li>
          <li>
            We wszelkich sprawach związanych z działalnością Serwisu należy
            kontaktować się z Usługodawcę korzystając z jednej z poniższych form
            kontaktu:
            <ul>
              <li>Używając formularza kontaktowego dostępnego w Serwisie</li>
              <li>
                Wysyłając wiadomość na adres e-mail: rapkrytyk.info@gmail.com
              </li>
              <li>
                Poprzez połączenie telefoniczne z numerem: +48 502 656 363
              </li>
            </ul>
            Kontakt przy użyciu wskazanych środk&oacute;w komunikacji wyłącznie
            w sprawach związanych z prowadzonym Serwisem.
          </li>
        </ul>
      </PageContainer>
    </section>
  );
};

export default memo(RegulationsPage);
