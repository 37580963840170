import React, { memo } from "react";
import { Pagination } from "antd";
import PropTypes from "prop-types";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../variables/breakpoints/breakpoints";

const PaginationBar = (props) => {
  const { current, total } = props;
  const isDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });
  return (
    <Pagination
      defaultCurrent={current}
      total={total}
      showSizeChanger={false}
      size={isDesktop ? "default" : "small"}
    />
  );
};

PaginationBar.propTypes = {
  total: PropTypes.number.isRequired, // of itmes inside
  current: PropTypes.number,
};

export default memo(PaginationBar);
