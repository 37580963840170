import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "../../../Tile/Tile";
import { StarFilled } from "@ant-design/icons";
import Number from "../../../Number/Number";
import { Link } from "react-router-dom";
import Highlighter from "react-highlight-words";

const SingleResult = (props) => {
  const { className, query } = props;
  const {
    id,
    name,
    author,
    additionalArtists,
    album,
    releaseDate,
    numberOfVotes,
    rate,
    showOrder,
    imageUrl,
  } = props.songData;
  return (
    <li className="single-result" key={id}>
      <Link to={album == undefined ? `/album/${id}` : `/song/${id}`}>
        <Tile>
          <div className="single-result__content">
            <div className="single-result__content__frame">
              <img
                className="single-result__content__frame__img"
                src={imageUrl}
                alt="okładka płyty"
              />
            </div>
            <div className="single-result__content__description">
              <h2 className="single-result__content__description__title">
                <Highlighter
                  highlightClassName="highlight"
                  searchWords={[query]}
                  textToHighlight={name}
                />
              </h2>
              {author && (
                <div className="single-result__content__description__property">
                  <span className="single-result__content__description__property__label">
                    Wykonawca:
                  </span>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[query]}
                    textToHighlight={author}
                  />
                </div>
              )}
              {additionalArtists && additionalArtists.length > 0 && (
                <div className="single-result__content__description__property">
                  <span className="single-result__content__description__property__label">
                    Feat:
                  </span>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[query]}
                    textToHighlight={additionalArtists.join(", ")}
                  />
                </div>
              )}
              {name === album && (
                <div className="single-result__content__description__property">
                  <span className="single-result__content__description__property__label">
                    Singiel
                  </span>
                </div>
              )}
              {album && name !== album && (
                <div className="single-result__content__description__property">
                  <span className="single-result__content__description__property__label">
                    Album:
                  </span>
                  <Highlighter
                    highlightClassName="highlight"
                    searchWords={[query]}
                    textToHighlight={album}
                  />
                </div>
              )}
              {releaseDate && (
                <div className="single-result__content__description__property">
                  <span className="single-result__content__description__property__label">
                    Data wydania:
                  </span>
                  <span>
                    <Number>{releaseDate}</Number>
                  </span>
                </div>
              )}
            </div>
            <div className="single-result__content__rate">
              <div className="single-result__content__rate__block">
                <span className="single-result__content__rate__block__number">
                  <Number>{parseFloat(rate)}</Number>
                </span>
                <StarFilled
                  className={"single-result__content__rate__block__star"}
                />
              </div>
              <div className="single-result__content__rate__votes">
                <span className="single-result__content__rate__votes__label">
                  Ilość opini:
                </span>
                <span className="single-result__content__rate__votes__number">
                  <Number>{numberOfVotes}</Number>
                </span>
              </div>
            </div>
          </div>
        </Tile>
      </Link>
    </li>
  );
};

SingleResult.propTypes = {
  className: PropTypes.string,
  songData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    author: PropTypes.string,
    additionalArtists: PropTypes.string,
    album: PropTypes.string,
    releaseDate: PropTypes.string,
    rate: PropTypes.number,
    numberOfVotes: PropTypes.number,
    imageUrl: PropTypes.string,
  }),
  query: PropTypes.string,
};

export default memo(SingleResult);
