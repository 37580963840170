import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LeagueProgressBar from "../../../../common/components/LeagueProgressBar/LeagueProgressBar";
import moment from "moment";
import { Button } from "antd";
import Description from "../../../MyAccountPage/Components/MyProfileBio/Description";
import AwardList from "../../../MyAccountPage/Components/MyProfileBio/AwardList";

const MyProfileBio = (props) => {
  const { className, user } = props;
  const { createdAt, email, nickName, points, rank } = user.credentials;
  const [imageSrc, setImageSrc] = useState();
  useEffect(() => {
    setImageSrc(
      `https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/rank-${rank}.png?alt=media`
    );
  }, [user.credentials.rank]);

  return (
    <section className={`${className ?? "profile"}`}>
      {user.credentials.rank && (
        <>
          <h1 className="profile__nickname">{nickName} </h1>
          <div className="profile__main-block">
            <div className="profile__main-block__img-container">
              {imageSrc && <img src={imageSrc} alt="avatar" />}
            </div>
            <div className="profile__main-block__content">
              <div className="profile__main-block__content__item">
                <span className="profile__main-block__content__item__label">
                  Email:
                </span>
                {email}
              </div>
              <div className="profile__main-block__content__item">
                <span className="profile__main-block__content__item__label">
                  Profils stworzony:
                </span>
                {moment(createdAt).format("DD/MM/YYYY")}
              </div>
              <LeagueProgressBar userRank={rank} userPoints={points} />
              <Description
                description={user.credentials.description}
                editModeAvaliable={false}
                userEmail={user.credentials.email}
              />
            </div>
          </div>
        </>
      )}
      {user.credentials.rank && <AwardList userRank={user.credentials.rank} />}
    </section>
  );
};

MyProfileBio.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
};

export default memo(MyProfileBio);
