import axios from "axios";
import { urls } from "../../Router/urls";

export const getSingleAlbum = (id) => {
  return axios
    .get(`${urls.base}/api/get-album?id=${id}`)
    .then((res) => {
      return res.data.result;
    })
    .catch((error) => {
      return error.response;
    });
};
