import React, { memo } from "react";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import MetaData from "../../common/components/MetaData/MetaData";

const PrivacyPolicyPage = () => {
  return (
    <section>
      <MetaData
        title={"Polityka prywatności - Rap Krytyk"}
        description="Polityka prywatności wirtryny Rap Krytyk, email kontaktowy: rapkrytyk.info@gmail.com"
      />
      <PageContainer>
        <PageTitle>Polityka prywatności</PageTitle>
        <p>
          Poniższa Polityka Prywatności określa&nbsp;
          <strong>
            zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach
            Użytkownik&oacute;w
          </strong>
          &nbsp;korzystających z Serwisu do cel&oacute;w świadczenia usług drogą
          elektroniczną przez Administratora oraz&nbsp;
          <strong>
            zasady gromadzenia i przetwarzania danych osobowych
            Użytkownik&oacute;w
          </strong>
          , kt&oacute;re zostały podane przez nich osobiście i dobrowolnie za
          pośrednictwem narzędzi dostępnych w Serwisie.
        </p>
        <p>
          Poniższa Polityka Prywatności jest integralną częścią&nbsp;
          <a href="https://rapkrytyk.pl/regulations">Regulaminu Serwisu</a>,
          kt&oacute;ry określa zasady, prawa i obowiązki Użytkownik&oacute;w
          korzystających z Serwisu.
        </p>
        <h2>&sect;1 Definicje</h2>
        <ul>
          <li>
            <p>
              <strong>Serwis</strong>&nbsp;- serwis internetowy "Rap Krytyk"
              działający pod adresem https://rapkrytyk.pl
            </p>
          </li>
          <li>
            <p>
              <strong>Serwis zewnętrzny</strong>&nbsp;- serwisy internetowe
              partner&oacute;w, usługodawc&oacute;w lub usługobiorc&oacute;w
              wsp&oacute;łpracujących z Administratorem
            </p>
          </li>
          <li>
            <p>
              <strong>Administrator Serwisu / Danych</strong>&nbsp;-
              Administratorem Serwisu oraz Administratorem Danych (dalej
              Administrator) jest osoba fizyczna "Jakub Wiśniewski" zamieszkała
              w Warszawie, świadcząca usługi drogą elektroniczną za
              pośrednictwem Serwisu
            </p>
          </li>
          <li>
            <p>
              <strong>Użytkownik</strong>&nbsp;- osoba fizyczna, dla
              kt&oacute;rej Administrator świadczy usługi drogą elektroniczną za
              pośrednictwem Serwisu.
            </p>
          </li>
          <li>
            <p>
              <strong>Urządzenie</strong>&nbsp;- elektroniczne urządzenie wraz z
              oprogramowaniem, za pośrednictwem kt&oacute;rego Użytkownik
              uzyskuje dostęp do Serwisu
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies (ciasteczka)</strong>&nbsp;- dane tekstowe
              gromadzone w formie plik&oacute;w zamieszczanych na Urządzeniu
              Użytkownika
            </p>
          </li>
          <li>
            <p>
              <strong>RODO</strong>&nbsp;- Rozporządzenie Parlamentu
              Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
              sprawie ochrony os&oacute;b fizycznych w związku z przetwarzaniem
              danych osobowych i w sprawie swobodnego przepływu takich danych
              oraz uchylenia dyrektywy 95/46/WE (og&oacute;lne rozporządzenie o
              ochronie danych)
            </p>
          </li>
          <li>
            <p>
              <strong>Dane osobowe</strong>&nbsp;- oznaczają informacje o
              zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej
              (&bdquo;osobie, kt&oacute;rej dane dotyczą&rdquo;); możliwa do
              zidentyfikowania osoba fizyczna to osoba, kt&oacute;rą można
              bezpośrednio lub pośrednio zidentyfikować, w szczeg&oacute;lności
              na podstawie identyfikatora takiego jak imię i nazwisko, numer
              identyfikacyjny, dane o lokalizacji, identyfikator internetowy lub
              jeden bądź kilka szczeg&oacute;lnych czynnik&oacute;w
              określających fizyczną, fizjologiczną, genetyczną, psychiczną,
              ekonomiczną, kulturową lub społeczną tożsamość osoby fizycznej
            </p>
          </li>
          <li>
            <p>
              <strong>Przetwarzanie</strong>&nbsp;- oznacza operację lub zestaw
              operacji wykonywanych na danych osobowych lub zestawach danych
              osobowych w spos&oacute;b zautomatyzowany lub niezautomatyzowany,
              taką jak zbieranie, utrwalanie, organizowanie, porządkowanie,
              przechowywanie, adaptowanie lub modyfikowanie, pobieranie,
              przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie,
              rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie
              lub łączenie, ograniczanie, usuwanie lub niszczenie;
            </p>
          </li>
          <li>
            <p>
              <strong>Ograniczenie przetwarzania</strong>&nbsp;- oznacza
              oznaczenie przechowywanych danych osobowych w celu ograniczenia
              ich przyszłego przetwarzania
            </p>
          </li>
          <li>
            <p>
              <strong>Profilowanie</strong>&nbsp;- oznacza dowolną formę
              zautomatyzowanego przetwarzania danych osobowych, kt&oacute;re
              polega na wykorzystaniu danych osobowych do oceny
              niekt&oacute;rych czynnik&oacute;w osobowych osoby fizycznej, w
              szczeg&oacute;lności do analizy lub prognozy aspekt&oacute;w
              dotyczących efekt&oacute;w pracy tej osoby fizycznej, jej sytuacji
              ekonomicznej, zdrowia, osobistych preferencji, zainteresowań,
              wiarygodności, zachowania, lokalizacji lub przemieszczania się
            </p>
          </li>
          <li>
            <p>
              <strong>Zgoda</strong>&nbsp;- zgoda osoby, kt&oacute;rej dane
              dotyczą oznacza dobrowolne, konkretne, świadome i jednoznaczne
              okazanie woli, kt&oacute;rym osoba, kt&oacute;rej dane dotyczą, w
              formie oświadczenia lub wyraźnego działania potwierdzającego,
              przyzwala na przetwarzanie dotyczących jej danych osobowych
            </p>
          </li>
          <li>
            <p>
              <strong>Naruszenie ochrony danych osobowych</strong>&nbsp;-
              oznacza naruszenie bezpieczeństwa prowadzące do przypadkowego lub
              niezgodnego z prawem zniszczenia, utracenia, zmodyfikowania,
              nieuprawnionego ujawnienia lub nieuprawnionego dostępu do danych
              osobowych przesyłanych, przechowywanych lub w inny spos&oacute;b
              przetwarzanych
            </p>
          </li>
          <li>
            <p>
              <strong>Pseudonimizacja</strong>&nbsp;- oznacza przetworzenie
              danych osobowych w taki spos&oacute;b, by nie można ich było już
              przypisać konkretnej osobie, kt&oacute;rej dane dotyczą, bez
              użycia dodatkowych informacji, pod warunkiem że takie dodatkowe
              informacje są przechowywane osobno i są objęte środkami
              technicznymi i organizacyjnymi uniemożliwiającymi ich przypisanie
              zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej
            </p>
          </li>
          <li>
            <p>
              <strong>Anonimizacja</strong>&nbsp;- Anonimizacja danych to
              nieodwracalny proces operacji na danych, kt&oacute;ry niszczy /
              nadpisuje "dane osobowe" uniemożliwiając identyfikację, lub
              powiązanie danego rekordu z konkretnym użytkownikiem lub osobą
              fizyczną.
            </p>
          </li>
        </ul>
        <h2>&sect;2 Inspektor Ochrony Danych</h2>
        <p>
          Na podstawie Art. 37 RODO, Administrator nie powołał Inspektora
          Ochrony Danych.
        </p>
        <p>
          W sprawach dotyczących przetwarzania danych, w tym danych osobowych,
          należy kontaktować się bezpośrednio z Administratorem.
        </p>
        <h2>&sect;3 Rodzaje Plik&oacute;w Cookies</h2>
        <ul>
          <li>
            <p>
              <strong>Cookies wewnętrzne</strong>&nbsp;- pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przez system
              teleinformatyczny Serwisu
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies zewnętrzne</strong>&nbsp;- pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przez systemy
              teleinformatyczne Serwis&oacute;w zewnętrznych. Skrypty
              Serwis&oacute;w zewnętrznych, kt&oacute;re mogą umieszczać pliki
              Cookies na Urządzeniach Użytkownika zostały świadomie umieszczone
              w Serwisie poprzez skrypty i usługi udostępnione i zainstalowane w
              Serwisie
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies sesyjne</strong>&nbsp;- pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przez Serwis&nbsp;podczas
              jednej sesji danego Urządzenia. Po zakończeniu sesji pliki są
              usuwane z Urządzenia Użytkownika.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies trwałe</strong>&nbsp;- pliki zamieszczane i
              odczytywane z Urządzenia Użytkownika przez Serwis&nbsp;do momentu
              ich ręcznego usunięcia. Pliki nie są usuwane automatycznie po
              zakończeniu sesji Urządzenia chyba że konfiguracja Urządzenia
              Użytkownika jest ustawiona na tryb usuwanie plik&oacute;w Cookie
              po zakończeniu sesji Urządzenia.
            </p>
          </li>
        </ul>
        <h2>&sect;4 Bezpieczeństwo składowania danych</h2>
        <ul>
          <li>
            <p>
              <strong>
                Mechanizmy składowania i odczytu plik&oacute;w Cookie
              </strong>
              &nbsp;- Mechanizmy składowania, odczytu i wymiany danych pomiędzy
              Plikami Cookies zapisywanymi na Urządzeniu Użytkownika a Serwisem
              są realizowane poprzez wbudowane mechanizmy przeglądarek
              internetowych i nie pozwalają na pobieranie innych danych z
              Urządzenia Użytkownika lub danych innych witryn internetowych,
              kt&oacute;re odwiedzał Użytkownik, w tym danych osobowych ani
              informacji poufnych. Przeniesienie na Urządzenie Użytkownika
              wirus&oacute;w, koni trojańskich oraz innych robak&oacute;w jest
              także praktycznie niemożliwe.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookie wewnętrzne</strong>&nbsp;- zastosowane przez
              Administratora pliki Cookie są bezpieczne dla Urządzeń
              Użytkownik&oacute;w i nie zawierają skrypt&oacute;w, treści lub
              informacji mogących zagrażać bezpieczeństwu danych osobowych lub
              bezpieczeństwu Urządzenia z kt&oacute;rego korzysta Użytkownik.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookie zewnętrzne</strong>&nbsp;- Administrator dokonuje
              wszelkich możliwych działań w celu weryfikacji i doboru
              partner&oacute;w serwisu w kontekście bezpieczeństwa
              Użytkownik&oacute;w. Administrator do wsp&oacute;łpracy dobiera
              znanych, dużych partner&oacute;w o globalnym zaufaniu społecznym.
              Nie posiada on jednak pełnej kontroli nad zawartością
              plik&oacute;w Cookie pochodzących od zewnętrznych
              partner&oacute;w. Za bezpieczeństwo plik&oacute;w Cookie, ich
              zawartość oraz zgodne z licencją wykorzystanie przez zainstalowane
              w serwisie Skrypty, pochodzących z Serwis&oacute;w zewnętrznych,
              Administrator nie ponosi odpowiedzialności na tyle na ile pozwala
              na to prawo. Lista partner&oacute;w zamieszczona jest w dalszej
              części Polityki Prywatności.
            </p>
          </li>
          <li>
            <p>
              <strong>Kontrola plik&oacute;w Cookie</strong>
            </p>
            <ul>
              <li>
                <p>
                  Użytkownik może w dowolnym momencie, samodzielnie zmienić
                  ustawienia dotyczące zapisywania, usuwania oraz dostępu do
                  danych zapisanych plik&oacute;w Cookies przez każdą witrynę
                  internetową
                </p>
              </li>
              <li>
                <p>
                  Informacje o sposobie wyłączenia plik&oacute;w Cookie w
                  najpopularniejszych przeglądarkach komputerowych dostępne są
                  na stronie:&nbsp;
                  <a
                    href="https://nety.pl/jak-wylaczyc-pliki-cookie/"
                    rel="external"
                  >
                    jak wyłączyć cookie
                  </a>
                  &nbsp;lub u jednego ze wskazanych dostawc&oacute;w:
                </p>
                <ul>
                  <li>
                    <a
                      href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DDesktop&amp;hl=pl"
                      rel="nofollow external"
                    >
                      Zarządzanie plikami cookies w przeglądarce&nbsp;
                      <strong>Chrome</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://help.opera.com/pl/latest/web-preferences/"
                      rel="nofollow external"
                    >
                      Zarządzanie plikami cookies w przeglądarce&nbsp;
                      <strong>Opera</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.mozilla.org/pl/kb/blokowanie-ciasteczek"
                      rel="nofollow external"
                    >
                      Zarządzanie plikami cookies w przeglądarce&nbsp;
                      <strong>FireFox</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.microsoft.com/pl-pl/help/4027947/microsoft-edge-delete-cookies"
                      rel="nofollow external"
                    >
                      Zarządzanie plikami cookies w przeglądarce&nbsp;
                      <strong>Edge</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac"
                      rel="nofollow external"
                    >
                      Zarządzanie plikami cookies w przeglądarce&nbsp;
                      <strong>Safari</strong>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://windows.microsoft.com/pl-pl/internet-explorer/delete-manage-cookies#ie=ie-11"
                      rel="nofollow external"
                    >
                      Zarządzanie plikami cookies w przeglądarce&nbsp;
                      <strong>Internet Explorer 11</strong>
                    </a>
                  </li>
                </ul>
                <p>&nbsp;</p>
              </li>
              <li>
                <p>
                  Użytkownik może w dowolnym momencie usunąć wszelkie zapisane
                  do tej pory pliki Cookie korzystając z narzędzi Urządzenia
                  Użytkownika, za pośrednictwem kt&oacute;rego Użytkownik
                  korzysta z usług Serwisu.
                </p>
              </li>
            </ul>
            <p>&nbsp;</p>
          </li>
          <li>
            <p>
              <strong>Zagrożenia po stronie Użytkownika&nbsp;</strong>-
              Administrator stosuje wszelkie możliwe środki techniczne w celu
              zapewnienia bezpieczeństwa danych umieszczanych w plikach Cookie.
              Należy jednak zwr&oacute;cić uwagę, że zapewnienie bezpieczeństwa
              tych danych zależy od obu stron w tym działalności Użytkownika.
              Administrator nie bierze odpowiedzialności za przechwycenie tych
              danych, podszycie się pod sesję Użytkownika lub ich usunięcie, na
              skutek świadomej lub nieświadomej działalność Użytkownika,
              wirus&oacute;w, koni trojańskich i innego oprogramowania
              szpiegującego, kt&oacute;rymi może jest lub było zainfekowane
              Urządzenie Użytkownika. Użytkownicy w celu zabezpieczenia się
              przed tymi zagrożeniami powinni stosować się do&nbsp;
              <a href="https://nety.pl/cyberbezpieczenstwo/" rel="external">
                zaleceń korzystania z sieci internet
              </a>
              .
            </p>
          </li>
          <li>
            <p>
              <strong>Przechowywanie danych osobowych</strong>&nbsp;-
              Administrator zapewnia, że dokonuje wszelkich starań, by
              przetwarzane dane osobowe wprowadzone dobrowolnie przez
              Użytkownik&oacute;w były bezpieczne, dostęp do nich był
              ograniczony i realizowany zgodnie z ich przeznaczeniem i celami
              przetwarzania. Administrator zapewnia także, że dokonuje wszelkich
              starań w celu zabezpieczenia posiadanych danych przed ich utratą,
              poprzez stosowanie odpowiednich zabezpieczeń fizycznych jak i
              organizacyjnych.
            </p>
          </li>
          <li>
            <p>
              <strong>Przechowywanie haseł</strong>&nbsp;- Administrator
              oświadcza, że hasła przechowywane są w zaszyfrowanej postaci,
              używając najnowszych standard&oacute;w i wytycznych w tym
              zakresie. Deszyfracja podawanych w Serwisie haseł dostępu do konta
              jest praktycznie niemożliwa.
            </p>
          </li>
        </ul>
        <h2>&sect;5 Cele do kt&oacute;rych wykorzystywane są pliki Cookie</h2>
        <ul id="cele">
          <li>Usprawnienie i ułatwienie dostępu do Serwisu</li>
          <li>Personalizacja Serwisu dla Użytkownik&oacute;w</li>
          <li>Umożliwienie Logowania do serwisu</li>
          <li>Marketing, Remarketing w serwisach zewnętrznych</li>
          <li>Usługi serwowania reklam</li>
          <li>Usługi afiliacyjne</li>
          <li>
            Prowadzenie statystyk (użytkownik&oacute;w, ilości odwiedzin,
            rodzaj&oacute;w urządzeń, łącze itp.)
          </li>
          <li>Serwowanie usług multimedialnych</li>
          <li>Świadczenie usług społecznościowych</li>
        </ul>
        <h2>&sect;6 Cele przetwarzania danych osobowych</h2>
        <p>
          Dane osobowe dobrowolnie podane przez Użytkownik&oacute;w są
          przetwarzane w jednym z następujących cel&oacute;w:
        </p>
        <ul>
          <li>
            Realizacji usług elektronicznych:
            <ul>
              <li>
                Usługi rejestracji i utrzymania konta Użytkownika w Serwisie i
                funkcjonalności z nim związanych
              </li>
              <li>
                Usługi komentowania / polubienia wpis&oacute;w w Serwisie bez
                konieczności rejestrowania się
              </li>
              <li>
                Usługi udostępniania informacji o treści umieszczonych w
                Serwisie w serwisach społecznościowych lub innych witrynach.
              </li>
            </ul>
          </li>
          <li>
            Komunikacji Administratora z Użytkownikami w sprawach związanych z
            Serwisem oraz ochrony danych
          </li>
          <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
        </ul>
        <p>
          Dane o Użytkownikach gromadzone anonimowo i automatycznie są
          przetwarzane w jednym z następujących cel&oacute;w:
        </p>
        <ul>
          <li>Prowadzenie statystyk</li>
          <li>Remarketing</li>
          <li>
            Serwowanie reklam dostosowanych do preferencji Użytkownik&oacute;w
          </li>
          <li>Obsługi program&oacute;w afiliacyjnych</li>
          <li>Zapewnienia prawnie uzasadnionego interesu Administratora</li>
        </ul>
        <h2>&sect;7 Pliki Cookies Serwis&oacute;w zewnętrznych</h2>
        <p id="zewinfo">
          Administrator w Serwisie wykorzystuje skrypty javascript i komponenty
          webowe partner&oacute;w, kt&oacute;rzy mogą umieszczać własne pliki
          cookies na Urządzeniu Użytkownika. Pamiętaj, że w ustawieniach swojej
          przeglądarki możesz sam decydować o dozwolonych plikach cookies jakie
          mogą być używane przez poszczeg&oacute;lne witryny internetowe.
          Poniżej znajduje się lista partner&oacute;w lub ich usług
          zaimplementowanych w Serwisie, mogących umieszczać pliki cookies:
        </p>
        <ul id="zewnetrzne">
          <li>
            <strong>Usługi multimedialne:</strong>
            <ul>
              <li>
                <a
                  href="https://www.youtube.com/t/terms"
                  rel="nofollow external"
                >
                  YouTube
                </a>
              </li>
              <li>
                <a
                  href="https://soundcloud.com/pages/privacy"
                  rel="nofollow external"
                >
                  SoundCloud
                </a>
              </li>
              <li>
                <a
                  href="https://www.spotify.com/pl/legal/privacy-policy/"
                  rel="nofollow external"
                >
                  Spotify
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Usługi społecznościowe / łączone:</strong>
            <br />
            (Rejestracja, Logowanie, udostępnianie treści, komunikacja, itp.)
            <br />
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/legal/terms"
                  rel="nofollow external"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://policies.google.com/privacy?hl=pl"
                  rel="nofollow external"
                >
                  Google+
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Usługi udostępniania treści:</strong>
            <ul>
              <li>
                <a
                  href="https://help.instagram.com/519522125107875"
                  rel="nofollow external"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Usługi serwowania reklam oraz sieci afiliacyjne:</strong>
            <ul>
              <li>
                <a
                  href="https://policies.google.com/privacy?hl=pl"
                  rel="nofollow external"
                >
                  Google Adsense
                </a>
              </li>
            </ul>
          </li>
          <li>
            <strong>Prowadzenie statystyk:</strong>
            <ul>
              <li>
                <a
                  href="https://policies.google.com/privacy?hl=pl"
                  rel="nofollow external"
                >
                  Google Analytics
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <p>
          Usługi świadczone przez podmioty trzecie są poza kontrolą
          Administratora. Podmioty te mogą w każdej chwili zmienić swoje warunki
          świadczenia usług, polityki prywatności, cel przetwarzania danych oraz
          spos&oacute;w wykorzystywania plik&oacute;w cookie.
        </p>
        <h2>&sect;8 Rodzaje gromadzonych danych</h2>
        <p>
          Serwis gromadzi dane o Użytkownikach. Cześć danych jest gromadzona
          automatycznie i anonimowo, a część danych to dane osobowe podane
          dobrowolnie przez Użytkownik&oacute;w w trakcie zapisywania się do
          poszczeg&oacute;lnych usług oferowanych przez Serwis.
        </p>
        <p>
          <strong>Anonimowe dane gromadzone automatycznie:</strong>
        </p>
        <ul>
          <li>Adres IP</li>
          <li>Typ przeglądarki</li>
          <li>Rozdzielczość ekranu</li>
          <li>Przybliżona lokalizacja</li>
          <li>Otwierane podstrony serwisu</li>
          <li>Czas spędzony na odpowiedniej podstronie serwisu</li>
          <li>Rodzaj systemu operacyjnego</li>
          <li>Adres poprzedniej podstrony</li>
          <li>Adres strony odsyłającej</li>
          <li>Język przeglądarki</li>
          <li>Prędkość łącza internetowego</li>
          <li>Dostawca usług internetowych</li>
          <li>Dane demograficzne (wiek, płeć)</li>
        </ul>
        <p>
          <strong>Dane gromadzone podczas rejestracji:</strong>
        </p>
        <ul>
          <li>Imię / nazwisko / pseudonim</li>
          <li>Login</li>
          <li>Adres e-mail</li>
          <li>Adres IP (zbierane automatycznie)</li>
        </ul>
        <p>
          <strong>Dane gromadzone podczas zapisu do usługi Newsletter</strong>
        </p>
        <ul>
          <li>Adres e-mail</li>
        </ul>
        <p>
          <strong>Dane gromadzone podczas dodawania komentarza</strong>
        </p>
        <ul>
          <li>Imię i nazwisko / pseudonim</li>
          <li>Adres e-mail</li>
          <li>Adres IP (zbierane automatycznie)</li>
        </ul>
        <p>
          Część danych (bez danych identyfikujących) może być przechowywana w
          plikach cookies. Cześć danych (bez danych identyfikujących) może być
          przekazywana do dostawcy usług statystycznych.
        </p>
        <h2>&sect;9 Dostęp do danych osobowych przez podmioty trzecie</h2>
        <p>
          Co do zasady jedynym odbiorcą danych osobowych podawanych przez
          Użytkownik&oacute;w jest Administrator. Dane gromadzone w ramach
          świadczonych usług nie są przekazywane ani odsprzedawane podmiotom
          trzecim.
        </p>
        <p>
          Dostęp do danych (najczęściej na podstawie Umowy powierzenia
          przetwarzania danych) mogą posiadać podmioty, odpowiedzialne za
          utrzymania infrastruktury i usług niezbędnych do prowadzenia serwisu
          tj.:
        </p>
        <ul>
          <li>
            Firmy hostingowe, świadczące usługi hostingu lub usług pokrewnych
            dla Administratora
          </li>
        </ul>
        <p>
          <strong>
            Powierzenie przetwarzania danych osobowych - Usługi Hostingu, VPS
            lub Serwer&oacute;w Dedykowanych
          </strong>
        </p>
        <p>
          Administrator w celu prowadzenia serwisu korzysta z usług zewnętrznego
          dostawcy hostingu, VPS lub Serwer&oacute;w Dedykowanych -&nbsp;
          <strong>
            &lt;arel="nofollow external"=""
            href="https://firebase.google.com/"&gt;Firebase
          </strong>
          . Wszelkie dane gromadzone i przetwarzane w serwisie są przechowywane
          i przetwarzane w infrastrukturze usługodawcy zlokalizowanej w obrębie
          gramic Unii Europejskiej. Istnieje możliwość dostępu do danych wskutek
          prac serwisowych realizowanych przez personel usługodawcy. Dostęp do
          tych danych reguluje umowa zawarta pomiędzy Administratorem a
          Usługodawcą.
        </p>
        <p>&nbsp;</p>
        <h2>&sect;10 Spos&oacute;b przetwarzania danych osobowych</h2>
        <p>
          <strong>
            Dane osobowe podane dobrowolnie przez Użytkownik&oacute;w:
          </strong>
        </p>
        <ul>
          <li>
            Dane osobowe nie będą przekazywane poza Unię Europejską, chyba że
            zostały opublikowane na skutek indywidualnego działania Użytkownika
            (np. wprowadzenie komentarza lub wpisu), co sprawi, że dane będą
            dostępne dla każdej osoby odwiedzającej serwis.
          </li>
          <li>
            Dane osobowe nie będą wykorzystywane do zautomatyzowanego
            podejmowania decyzji (profilowania).
          </li>
          <li>Dane osobowe nie będą odsprzedawane podmiotom trzecim.</li>
        </ul>
        <p>
          <strong>
            Dane anonimowe (bez danych osobowych) gromadzone automatycznie:
          </strong>
        </p>
        <ul>
          <li>
            Dane anonimiwe (bez danych osobowych) będą przekazywane poza Unię
            Europejską.
          </li>
          <li>
            Dane anonimiwe (bez danych osobowych) mogą być wykorzystywane do
            zautomatyzowanego podejmowania decyzji (profilowania).
            <br />
            Profilowanie danych anonimowych (bez danych osobowych) nie wywołuje
            skutk&oacute;w prawnych lub w podobny spos&oacute;b istotnie nie
            wpływa na osobę, kt&oacute;rej dane podlegają automatycznemu
            podejmowaniu decyzji.
          </li>
          <li>
            Dane anonimiwe (bez danych osobowych) nie będą odsprzedawane
            podmiotom trzecim.
          </li>
        </ul>
        <h2>&sect;11 Podstawy prawne przetwarzania danych osobowych</h2>
        <p>
          Serwis gromadzi i przetwarza dane Użytkownik&oacute;w na podstawie:
        </p>
        <ul>
          <li>
            Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia
            27 kwietnia 2016 r. w sprawie ochrony os&oacute;b fizycznych w
            związku z przetwarzaniem danych osobowych i w sprawie swobodnego
            przepływu takich danych oraz uchylenia dyrektywy 95/46/WE
            (og&oacute;lne rozporządzenie o ochronie danych)
            <ul>
              <li>
                art. 6 ust. 1 lit. a<br />
                <small>
                  osoba, kt&oacute;rej dane dotyczą wyraziła zgodę na
                  przetwarzanie swoich danych osobowych w jednym lub większej
                  liczbie określonych cel&oacute;w
                </small>
              </li>
              <li>
                art. 6 ust. 1 lit. b<br />
                <small>
                  przetwarzanie jest niezbędne do wykonania umowy, kt&oacute;rej
                  stroną jest osoba, kt&oacute;rej dane dotyczą, lub do podjęcia
                  działań na żądanie osoby, kt&oacute;rej dane dotyczą, przed
                  zawarciem umowy
                </small>
              </li>
              <li>
                art. 6 ust. 1 lit. f<br />
                <small>
                  przetwarzanie jest niezbędne do cel&oacute;w wynikających z
                  prawnie uzasadnionych interes&oacute;w realizowanych przez
                  administratora lub przez stronę trzecią
                </small>
              </li>
            </ul>
          </li>
          <li>
            Ustawa z dnia 10 maja 2018 r. o ochronie danych osobowych (Dz.U.
            2018 poz. 1000)
          </li>
          <li>
            Ustawa z dnia 16 lipca 2004 r. Prawo telekomunikacyjne (Dz.U. 2004
            nr 171 poz. 1800)
          </li>
          <li>
            Ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach
            pokrewnych (Dz. U. 1994 Nr 24 poz. 83)
          </li>
        </ul>
        <h2>&sect;12 Okres przetwarzania danych osobowych</h2>
        <p>
          <strong>
            Dane osobowe podane dobrowolnie przez Użytkownik&oacute;w:
          </strong>
        </p>
        <p>
          Co do zasady wskazane dane osobowe są przechowywane wyłącznie przez
          okres świadczenia Usługi w ramach Serwisu przez Administratora. Są one
          usuwane lub anonimizowane w okresie do 30 dni od chwili zakończenia
          świadczenia usług (np. usunięcie zarejestrowanego konta użytkownika,
          wypisanie z listy Newsletter, itp.)
        </p>
        <p>
          Wyjątek stanowi sytuacja, kt&oacute;ra wymaga zabezpieczenia prawnie
          uzasadnionych cel&oacute;w dalszego przetwarzania tych danych przez
          Administratora. W takiej sytuacji Administrator będzie przechowywał
          wskazane dane, od czasu żądania ich usunięcia przez Użytkownika, nie
          dłużej niż przez okres 3 lat w przypadku naruszenia lub podejrzenia
          naruszenia zapis&oacute;w regulaminu serwisu przez Użytkownika
        </p>
        <p>
          <strong>
            Dane anonimowe (bez danych osobowych) gromadzone automatycznie:
          </strong>
        </p>
        <p>
          Anonimowe dane statystyczne, niestanowiące danych osobowych, są
          przechowywane przez Administratora w celu prowadzenia statystyk
          serwisu przez czas nieoznaczony
        </p>
        <h2>
          &sect;13 Prawa Użytkownik&oacute;w związane z przetwarzaniem danych
          osobowych
        </h2>
        <p>
          Serwis gromadzi i przetwarza dane Użytkownik&oacute;w na podstawie:
        </p>
        <ul>
          <li>
            <p>
              <strong>Prawo dostępu do danych osobowych</strong>
              <br />
              Użytkownikom przysługuje prawo uzyskania dostępu do swoich danych
              osobowych, realizowane na żądanie złożone do Administratora
            </p>
          </li>
          <li>
            <p>
              <strong>Prawo do sprostowania danych osobowych</strong>
              <br />
              Użytkownikom przysługuje prawo żądania od Administratora
              niezwłocznego sprostowania danych osobowych, kt&oacute;re są
              nieprawidłowe lub / oraz uzupełnienia niekompletnych danych
              osobowych, realizowane na żądanie złożone do Administratora
            </p>
          </li>
          <li>
            <p>
              <strong>Prawo do usunięcia danych osobowych</strong>
              <br />
              Użytkownikom przysługuje prawo żądania od Administratora
              niezwłocznego usunięcia danych osobowych, realizowane na żądanie
              złożone do Administratora W przypadku kont użytkownik&oacute;w,
              usunięcie danych polega na anonimizacji danych umożliwiających
              identyfikację Użytkownika. Administrator zastrzega sobie prawo
              wstrzymania realizacji żądania usunięcia danych w celu ochrony
              prawnie uzasadnionego interesu Administratora (np. w gdy
              Użytkownik dopuścił się naruszenia Regulaminu czy dane zostały
              pozyskane wskutek prowadzonej korespondencji).
              <br />W przypadku usługi Newsletter, Użytkownik ma możliwość
              samodzielnego usunięcia swoich danych osobowych korzystając z
              odnośnika umieszczonego w każdej przesyłanej wiadomości e-mail.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Prawo do ograniczenia przetwarzania danych osobowych
              </strong>
              <br />
              Użytkownikom przysługuje prawo ograniczenia przetwarzania danych
              osobowych w przypadkach wskazanych w art. 18 RODO, m.in.
              kwestionowania prawidłowość danych osobowych, realizowane na
              żądanie złożone do Administratora
            </p>
          </li>
          <li>
            <p>
              <strong>Prawo do przenoszenia danych osobowych</strong>
              <br />
              Użytkownikom przysługuje prawo uzyskania od Administratora, danych
              osobowych dotyczących Użytkownika w ustrukturyzowanym, powszechnie
              używanym formacie nadającym się do odczytu maszynowego,
              realizowane na żądanie złożone do Administratora
            </p>
          </li>
          <li>
            <p>
              <strong>
                Prawo wniesienia sprzeciwu wobec przetwarzania danych osobowych
              </strong>
              <br />
              Użytkownikom przysługuje prawo wniesienia sprzeciwu wobec
              przetwarzania jego danych osobowych w przypadkach określonych w
              art. 21 RODO, realizowane na żądanie złożone do Administratora
            </p>
          </li>
          <li>
            <p>
              <strong>Prawo wniesienia skargi</strong>
              <br />
              Użytkownikom przysługuje prawo wniesienia skargi do organu
              nadzorczego zajmującego się ochroną danych osobowych.
            </p>
          </li>
        </ul>
        <h2>&sect;14 Kontakt do Administratora</h2>
        <p>
          Z Administratorem można skontaktować się w jeden z poniższych
          sposob&oacute;w
        </p>
        <ul>
          <li>
            <p>
              <strong>Adres poczty elektronicznej</strong>&nbsp;-
              rapkrytyk.info@gmail.com
            </p>
          </li>
          <li>
            <p>
              <strong>Połączenie telefoniczne</strong>&nbsp;- +48 502 656 363
            </p>
          </li>
          <li>
            <p>
              <strong>Formularz kontaktowy</strong>&nbsp;- dostępny pod adresem:
              /contact
            </p>
          </li>
        </ul>
        <h2>&sect;15 Wymagania Serwisu</h2>
        <ul>
          <li>
            <p>
              Ograniczenie zapisu i dostępu do plik&oacute;w Cookie na
              Urządzeniu Użytkownika może spowodować nieprawidłowe działanie
              niekt&oacute;rych funkcji Serwisu.
            </p>
          </li>
          <li>
            <p>
              Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo
              działające funkcje Serwisu w przypadku gdy Użytkownik ograniczy w
              jakikolwiek spos&oacute;b możliwość zapisywania i odczytu
              plik&oacute;w Cookie.
            </p>
          </li>
        </ul>
        <h2>&sect;16 Linki zewnętrzne</h2>
        <p>
          W Serwisie - artykułach, postach, wpisach czy komentarzach
          Użytkownik&oacute;w mogą znajdować się odnośniki do witryn
          zewnętrznych, z kt&oacute;rymi Właściciel serwisu nie
          wsp&oacute;łpracuje. Linki te oraz strony lub pliki pod nimi wskazane
          mogą być niebezpieczne dla Twojego Urządzenia lub stanowić zagrożenie
          bezpieczeństwa Twoich danych. Administrator nie ponosi
          odpowiedzialności za zawartość znajdującą się poza Serwisem.
        </p>
        <h2>&sect;17 Zmiany w Polityce Prywatności</h2>
        <ul>
          <li>
            <p>
              Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
              Polityki Prywatności bez konieczności informowania o tym
              Użytkownik&oacute;w w zakresie stosowania i wykorzystywania danych
              anonimowych lub stosowania plik&oacute;w Cookie.
            </p>
          </li>
          <li>
            <p>
              Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej
              Polityki Prywatności w zakresie przetwarzania Danych Osobowych, o
              czym poinformuje Użytkownik&oacute;w posiadających konta
              użytkownika lub zapisanych do usługi newsletter, za pośrednictwem
              poczty elektronicznej w terminie do 7 dni od zmiany
              zapis&oacute;w. Dalsze korzystanie z usług oznacza zapoznanie się
              i akceptację wprowadzonych zmian Polityki Prywatności. W przypadku
              w kt&oacute;rym Użytkownik nie będzie się zgadzał z wprowadzonymi
              zmianami, ma obowiązek usunąć swoje konto z Serwisu lub wypisać
              się z usługi Newsletter.
            </p>
          </li>
          <li>
            <p>
              Wprowadzone zmiany w Polityce Prywatności będą publikowane na tej
              podstronie Serwisu.
            </p>
          </li>
          <li>
            <p>Wprowadzone zmiany wchodzą w życie z chwilą ich publikacji.</p>
          </li>
        </ul>
      </PageContainer>
    </section>
  );
};

export default memo(PrivacyPolicyPage);
