import React, { memo } from "react";
import PropTypes from "prop-types";
import CenterContent from "../CenterContent/CenterContent";
import { StarFilled } from "@ant-design/icons";
import Number from "../Number/Number";

const YourOverallRate = (props) => {
  const { className, overall, showWarn } = props;
  return (
    <>
      <CenterContent className={`overall-block ${className}`}>
        <span className="overall-block__label">Twoja średnia ocena:</span>
        <span className="overall-block__number">
          <Number>{overall}</Number>
        </span>
        <StarFilled />
      </CenterContent>
      {showWarn && (
        <CenterContent className="c-gray w100 mt-2">
          {"Twoja ocena nie będzie podlegała zmianie, dobrze to przemyśl."}
        </CenterContent>
      )}
    </>
  );
};

YourOverallRate.propTypes = {
  className: PropTypes.string,
  overall: PropTypes.number,
  showWarn: PropTypes.bool,
};

export default memo(YourOverallRate);
