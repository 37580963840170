import React, { memo, useState, useEffect } from "react";
import { postAlbumCover } from "../../../Api/songs/postAlbumCover";
import Btn from "../../../common/components/Btn/Btn";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import InputField from "../../../common/components/InputField/InputField";
import PageContainer from "../../../common/components/PageContainer/PageContainer";
import Spinner from "../../../common/components/Spinner/Spinner";
import { useHistory, useLocation } from "react-router-dom";
import { urls } from "../../../Router/urls";
import { connect } from "react-redux";
import JoditEditor from "jodit-react";
import { postLyrics } from "../../../Api/lyrics/postLyrics";

const BOaddLyrics = (props) => {
  const { user } = props;
  const history = useHistory();
  const location = useLocation();
  const songId = location.pathname.split("/add-lyrics/")[1];

  const [lyricsBlock, setLyricsBlock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preSubmitClicked, setPreSubmitClicked] = useState(false);

  useEffect(() => {
    if (
      user.credentials.type !== "admin" &&
      user.credentials.type !== "editor" &&
      user.credentials.type !== undefined
    ) {
      history.push(urls.homePage);
    }
  }, [user.credentials.type]);

  const addLyricsBlockHandler = () => {
    setLyricsBlock([...lyricsBlock, ""]);
  };

  const updateLyricsBlockHandler = (value, index) => {
    console.log(value);
    const newArr = [...lyricsBlock];
    newArr[index] = value;
    setLyricsBlock(newArr);
  };

  const preSubmit = () => {
    console.log(lyricsBlock);
    setPreSubmitClicked(true);
  };

  const submit = () => {
    console.log(lyricsBlock);
    setLoading(true);
    postLyrics({
      id: songId,
      parts: lyricsBlock,
    }).then(() => {
      setLoading(false);
    });
  };

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  return (
    <PageContainer>
      <Btn
        onClick={addLyricsBlockHandler}
        style={{ position: "fixed", top: "200px", left: "50px", zIndex: 10 }}
      >
        Dodaj blok tekstu
      </Btn>
      {lyricsBlock.length > 0 &&
        lyricsBlock.map((v, index) => (
          <div style={{ width: "60%", margin: "12px auto" }}>
            <JoditEditor
              key={index}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onChange={(val) => updateLyricsBlockHandler(val, index)}
            />
          </div>
        ))}
      <CenterContent className="mb-2">
        <Btn onClick={preSubmit}>Pokaż napisy</Btn>
      </CenterContent>
      {preSubmitClicked && (
        <CenterContent className="mb-2">
          {loading ? <Spinner /> : <Btn onClick={submit}>DODAJ NAPISY</Btn>}
        </CenterContent>
      )}
    </PageContainer>
  );
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(BOaddLyrics));
