import React from "react";
import { Link } from "react-router-dom";
import { urls } from "../../../Router/urls";
import Btn from "../Btn/Btn";
import PageContainer from "../PageContainer/PageContainer";

const CookieConsent = (props) => {
  const { setCookieAccepted } = props;
  const onAckeptClickHanled = () => {
    localStorage.setItem("cookie-accepted", true);
    setCookieAccepted(true);
  };
  return (
    <div className={`cookie-consent-bg`}>
      <PageContainer className={`cookie-consent`}>
        <div className={"cookie-consent__text"}>
          Rap Krytyk korzysta z plików cookies w celu zapewnienia maksymalnej
          wygody w korzystaniu z naszego serwisu.
          <div className="cookie-consent__text__link">
            <Link to={urls.privacyPolicy}>Więcej informacji</Link>
          </div>
        </div>
        <div className={"cookie-consent__btn-container"}>
          <Btn onClick={onAckeptClickHanled}>Akceptuję</Btn>
        </div>
      </PageContainer>
    </div>
  );
};

export default CookieConsent;
