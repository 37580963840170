import React, { memo } from "react";

const RecommendedArticleTile = () => {
  return (
    <div className="recommended-article-tile-skeleton">
      <div className="recommended-article-tile-skeleton__img-container"></div>
      <div className="recommended-article-tile-skeleton__text">
        <h2 className="recommended-article-tile-skeleton__text__title skeleton"></h2>
        <h2 className="recommended-article-tile-skeleton__text__title-semi skeleton"></h2>
      </div>
    </div>
  );
};

export default memo(RecommendedArticleTile);
