import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { getArticleBody } from "../../../../Api/articles/getArticleBody";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../../common/variables/breakpoints/breakpoints";
import RateArticleBlock from "./RateArticleBlock";
import { getMonthSummaryBody } from "../../../../Api/articles/getMonthSummaryBody";

const ArticleBody = (props) => {
  const { author, teaserText, id, tags, link, type } = props;

  const [bodyHtml, setBodyHtml] = useState(); // {body, likes}

  useEffect(() => {
    if (id && type == "article") {
      getArticleBody(id).then((res) => {
        setBodyHtml(res);
      });
    } else if (id && type == "month-summary") {
      getMonthSummaryBody(id).then((res) => {
        setBodyHtml(res);
      });
    }
  }, [id]);

  const isSmallDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  return (
    <section className="article-body">
      <div className="article-body__sheet">
        {isSmallDesktop && bodyHtml && (
          <div className="article-body__sheet__rate-col">
            <RateArticleBlock
              likes={bodyHtml.likes}
              id={id}
              link={link}
              type={type}
            />
          </div>
        )}

        <div className="article-body__sheet__content">
          {teaserText && (
            <p className="article-body__sheet__content__teaser">
              <strong>{teaserText}</strong>
            </p>
          )}
          {bodyHtml && (
            <div
              className="article-body__sheet__content__external-html"
              dangerouslySetInnerHTML={{ __html: bodyHtml.body }}
            ></div>
          )}
          {!isSmallDesktop && bodyHtml && (
            <RateArticleBlock
              likes={bodyHtml.likes}
              id={id}
              link={link}
              type={type}
            />
          )}
          {author && (
            <h4 className="article-body__sheet__content__author">
              <UserOutlined /> Autor: {author}
            </h4>
          )}
          {tags && (
            <CenterContent className="article-body__sheet__content__tags">
              Tagi: <h3 className="ml-1">{" " + tags.join(", ")}</h3>
            </CenterContent>
          )}
        </div>
      </div>
    </section>
  );
};

ArticleBody.propTypes = {
  author: PropTypes.string,
  teaserText: PropTypes.string,
  id: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
};

export default memo(ArticleBody);
