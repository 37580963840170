import axios from "axios";
import { urls } from "../../Router/urls";
import store from "../../Redux/store";

export const postArticleLike = (id, type, currentUserEmail) => {
  const reduxState = store.getState();

  if (reduxState.user.credentials.email) {
    const ObjectToSend = {
      id,
      type,
      currentUserEmail,
    };
    return axios
      .post(`${urls.base}/api/article-like`, ObjectToSend)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        return error.response;
      });
  } else {
    window.location.replace(urls.login);
  }
};
