import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUserReviews } from "../../../../Api/reviews/getUserReviews";
import SingleReviewOverviewSkeleton from "../../../SingleSongPage/components/ReviewOverview/SingleReviewOverviewSkeleton";
import SingleReviewOverview from "../../../SingleSongPage/components/ReviewOverview/SingleReviewOverview";
import SingleUserTabReview from "../../../MyAccountPage/Components/ReviewsTab/SingleUserTabReview";
import NoResultsSearch from "../../../../common/components/NoResultsSearch/NoResultsSearch";

const ReviewsTab = (props) => {
  const { className, user, userVisiting } = props;
  const [loading, setLoading] = useState();
  const [reviewsData, setReviewsData] = useState();

  useEffect(() => {
    if (user.credentials.email) {
      setLoading(true);
      getUserReviews(
        userVisiting.credentials.email,
        user.credentials.email
      ).then((res) => {
        setReviewsData(res.reverse());
        setLoading(false);
      });
    }
  }, [user]);

  const arrayOfSkeletons = [1, 2, 3];

  return (
    <section className={`${className ?? "reviews-tab"}`}>
      <div className={`review-overview`}>
        {loading &&
          arrayOfSkeletons.map((skeleton) => <SingleReviewOverviewSkeleton />)}
        {reviewsData &&
          reviewsData.length > 0 &&
          reviewsData.map((reviewData) => (
            <SingleUserTabReview reviewData={reviewData} id={reviewData.id} />
          ))}

        {!loading && reviewsData && reviewsData.length == 0 && (
          <NoResultsSearch
            hideButton={true}
            diffText={"Użytkownik jeszcze nie dodałeś żadnej recenzji"}
          />
        )}
      </div>
    </section>
  );
};

ReviewsTab.propTypes = {
  className: PropTypes.string,
  userVisiting: PropTypes.object,
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(ReviewsTab));
