import React, { memo, useState, useEffect, useRef } from "react";
import { postAlbumCover } from "../../../Api/songs/postAlbumCover";
import Btn from "../../../common/components/Btn/Btn";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import InputField from "../../../common/components/InputField/InputField";
import PageContainer from "../../../common/components/PageContainer/PageContainer";
import Spinner from "../../../common/components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { urls } from "../../../Router/urls";
import { connect } from "react-redux";
import JoditEditor from "jodit-react";
import { Form, Select } from "antd";
import RadioGroup from "../../../common/components/RadioGroup/RadioGroup";
import { postArticle } from "../../api/postArticle";
import RecomendedSelector from "./components/RecomendedSelector";

const BOaddArticle = (props) => {
  const { user } = props;
  const history = useHistory();
  const defaultAuthor = "Kamil Dybaś";
  const defaultType = "article";

  const [form] = Form.useForm();
  const editor = useRef(null);
  const [stringHtml, setStringHtml] = useState("");
  const [recomendedList, setRecomendedList] = useState([]);
  const [recomendedError, setRecomendedError] = useState(false);
  const [loading, setLoading] = useState(false);

  const pickRecomendedRef = useRef();

  useEffect(() => {
    if (
      user.credentials.type !== "admin" &&
      user.credentials.type !== "editor" &&
      user.credentials.type !== undefined
    ) {
      history.push(urls.homePage);
    }
  }, [user.credentials.type]);

  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
  };

  const showArticle = () => {
    const newHtml = editor.current.value;
    setStringHtml(newHtml);
  };

  const onAuthorChangeHandler = (val) => {
    form.setFieldsValue({ author: val });
  };

  const onTypeChangeHandler = (val) => {
    form.setFieldsValue({ type: val });
  };

  const onTagClickHandler = (tag) => {
    const currentTagsValue = form.getFieldValue("tags");
    if (currentTagsValue) {
      form.setFieldsValue({ tags: currentTagsValue + tag });
    } else {
      form.setFieldsValue({ tags: tag });
    }
  };

  const submit = (values) => {
    if (recomendedList.length == 0 || recomendedList.length == 3) {
      setLoading(true);
      setRecomendedError(false);

      const arrayOfTags = values.tags.replace(/\s/g, "").split(";");
      //check if last element is empty
      if (arrayOfTags[arrayOfTags.length - 1] == "") {
        arrayOfTags.pop();
      }

      const objectToSend = {
        ...values,
        tags: arrayOfTags,
        recomendedList,
        body: editor.current.value,
      };

      postArticle(objectToSend).then(() => {
        setLoading(false);
      });
    } else {
      setRecomendedError(true);
      pickRecomendedRef.current.scrollIntoView();
    }
  };

  return (
    <PageContainer className="bo__add-article">
      <Form
        className="bo__add-article__form"
        form={form}
        onFinish={submit}
        scrollToFirstError={true}
        initialValues={{ author: defaultAuthor, type: defaultType }}
      >
        <Form.Item
          name="title"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <InputField
            label="Tytuł*"
            className="bo__add-article__form__title-input"
          />
        </Form.Item>
        <Form.Item
          name="headerImg"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <InputField label="Link do zdjęcia (HEADER)*" />
        </Form.Item>
        <Form.Item
          name="teaserText"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <InputField label="Kilka zdań wstępu*" />
        </Form.Item>
        <Form.Item
          name="author"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <label for="author-select">Autor: </label>
          <Select
            name="author-select"
            defaultValue={defaultAuthor}
            onChange={onAuthorChangeHandler}
          >
            <Select.Option value="Kamil Dybaś">Kamil Dybaś</Select.Option>
            <Select.Option value="Jakub Wiśniewski">
              Jakub Wiśniewski
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="tags"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <InputField label="Tagi - MAX 5*" defaultValue="" />
          {/* <div>{'Oddzielaj przy użyciu ";" np. news; quebo; raper'}</div> */}
        </Form.Item>
        <RadioGroup
          onChange={onTagClickHandler}
          className="bo__add-article__form__radio-group"
          values={[
            { value: "news;", label: "News" },
            { value: "polska;", label: "Polska" },
            { value: "ciekawostki;", label: "Ciekawostki" },
            { value: "raper;", label: "Raper" },
            { value: "rap;", label: "Rap" },
          ]}
        />

        <Form.Item
          name="type"
          rules={[{ required: true, message: "Proszę uzupłnij te pole." }]}
          validateTrigger="onSubmit"
        >
          <label for="type-select">Typ pubplikacji: </label>
          <Select
            name="type-select"
            defaultValue={defaultType}
            onChange={onTypeChangeHandler}
          >
            <Select.Option value="article">Artykuł</Select.Option>
            <Select.Option value="month-summary">
              Podsumowanie miesiąca
            </Select.Option>
          </Select>
        </Form.Item>

        <div ref={pickRecomendedRef} className="mb-4 w100">
          <h2 className="mb-2">Wybierz polecane artykuły (3 albo 0)</h2>
          <RecomendedSelector
            recomendedList={recomendedList}
            updateRecomendedList={setRecomendedList}
            error={recomendedError}
          />
        </div>

        <div className="bo__add-article__form__jodit-editor-container">
          <JoditEditor
            ref={editor}
            config={config}
            tabIndex={1} // tabIndex of textarea
          />
        </div>

        <Btn onClick={showArticle} className="mt-4">
          Pokaż artykuł
        </Btn>

        {stringHtml.length > 0 && (
          <div dangerouslySetInnerHTML={{ __html: stringHtml }}></div>
        )}
        {stringHtml.length > 0 && (
          <>
            {loading ? (
              <Spinner />
            ) : (
              <Form.Item>
                <CenterContent className="mt-6">
                  <Btn type="primary" htmlType="submit">
                    Dodaj artykuł
                  </Btn>
                </CenterContent>
              </Form.Item>
            )}
          </>
        )}
      </Form>
    </PageContainer>
  );
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(BOaddArticle));
