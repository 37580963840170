import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getMyRequestsHistory } from "../../../../Api/myProfile/getMyRequestsHistory";
import SingleRequest from "./SingleRequest/SingleRequest";
import SingleRequestSkeleton from "./SingleRequest/SingleRequestSkeleton";
import NoResultsSearch from "../../../../common/components/NoResultsSearch/NoResultsSearch";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";

const RequestsHistory = (props) => {
  const { className } = props;
  const [requests, setRequests] = useState();

  useEffect(() => {
    getMyRequestsHistory().then((res) => {
      //sort by date
      res.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setRequests(res);
    });
  }, []);

  return (
    <section className={`${className ?? "req-history"}`}>
      {requests ? (
        requests.map((request) => <SingleRequest request={request} />)
      ) : (
        <SingleRequestSkeleton />
      )}
      {requests && requests.length == 0 && (
        <CenterContent className="w-100">
          <NoResultsSearch
            hideButton={true}
            diffText={
              "Jeszcze nie stworzyłeś żadnej prośby o dodanie utworu / albumu"
            }
          />
        </CenterContent>
      )}
    </section>
  );
};

RequestsHistory.propTypes = {
  className: PropTypes.string,
};

export default memo(RequestsHistory);
