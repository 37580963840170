import React, { memo } from "react";
import PropTypes from "prop-types";
import { Card } from "antd";

const Tile = (props) => {
  const { children, className, title } = props;
  return (
    <Card title={title} className={`tile ${className ?? ""}`}>
      {children}
    </Card>
  );
};

Tile.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

export default memo(Tile);
