import React from "react";
import TestField from "../TestField";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SingleSongPage from "../Pages/SingleSongPage/SingleSongPage";
import ContentRankingPage from "../Pages/ContentRankingPage/ContentRankingPage";
import CriticRankingPange from "../Pages/CriticRankingPage/CriticRankingPange";
import LoginPage from "../Pages/LoginPage/LoginPage";
import RegisterPage from "../Pages/RegisterPage/RegisterPage";
import { urls } from "./urls";
import HomePage from "../Pages/HomePage/HomePage";
import AddSongPage from "../Pages/AddSongPage/AddSongPage";
import MyAccountPage from "../Pages/MyAccountPage/MyAccountPage";
import SearchPage from "../Pages/SearchPage/SearchPage";
import SingleAlbumPage from "../Pages/SingleAlbumPage/SingleAlbumPage";
import SingleArtistPage from "../Pages/SingleArtistPage/SingleArtistPage";
import RankingListPage from "../Pages/RankingListPage/RankingListPage";
import DiffUserAccountPage from "../Pages/DiffUserAccountPage/DiffUserAccountPage";
import AboutUsPage from "../Pages/AboutUsPage/AboutUsPage";
import ContactPage from "../Pages/ContactPage/ContactPage";
import NotFoundPage from "../Pages/NotFoundPage/NotFoundPage";
import RegulationsPage from "../Pages/RegulationsPage/RegulationsPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import RODOPage from "../Pages/RODOPage/RODOPage";
import ForgotPasswordPage from "../Pages/ForgotPasswordPage/ForgotPasswordPage";
import NewPasswordPage from "../Pages/NewPasswordPage.js/NewPasswordPage";
import ExternalBrowserPage from "../Pages/ExternalBrowserPage/ExternalBrowserPage";
import PopularRankingPage from "../Pages/PopuplarRankingPage/PopularRankingPage";
import MyListPage from "../Pages/MyListPage/MyListPage";
import MyContentRankingPage from "../Pages/MyListPage/MyContentRankingPage/MyContentRankingPage";
import PointsPage from "../Pages/PointsPage/PointsPage";
//backoffice
import BOaddImage from "../Backoffice/Pages/BOaddImage/BOaddImage";
import BOsongRequestsPage from "../Backoffice/Pages/BOsongRequestsPage/BOsongRequestsPage";
import BOaddArticle from "../Backoffice/Pages/BOaddArticle/BOaddArticle";
import ArticlesPage from "../Pages/ArticlesPage/ArticlesPage";
import SingleArticlePage from "../Pages/SingleArticlePage/SingleArticlePage";
import BOeditArticle from "../Backoffice/Pages/BOeditArticle/BOeditArticle";
import BOaddLyrics from "../Backoffice/Pages/BOaddLyrics.js/BOaddLyrics";

const RouterComponent = () => {
  return (
    <Switch>
      <Route exact path={urls.homePage} component={HomePage} />
      <Route exact path={urls.singleSong} component={SingleSongPage} />
      <Route exact path={urls.singleAlbum} component={SingleAlbumPage} />
      <Route exact path={urls.singleArtist} component={SingleArtistPage} />
      <Route exact path={urls.search} component={() => <SearchPage />} />

      <Route
        exact
        path={urls.rankingList}
        component={() => <RankingListPage />}
      />
      <Route
        exact
        path={urls.rankingFunny}
        component={() => (
          <ContentRankingPage showPosition={true} title={"Tematyka zabawna"} />
        )}
      />
      <Route
        exact
        path={urls.rankingNeutral}
        component={() => (
          <ContentRankingPage
            showPosition={true}
            title={"Tematyka neutralna"}
          />
        )}
      />
      <Route
        exact
        path={urls.rankingSad}
        component={() => (
          <ContentRankingPage showPosition={true} title={"Tematyka smutna"} />
        )}
      />
      <Route
        exact
        path={urls.rankingSerious}
        component={() => (
          <ContentRankingPage showPosition={true} title={"Tematyka poważna"} />
        )}
      />
      <Route
        exact
        path={urls.rankingShowOff}
        component={() => (
          <ContentRankingPage showPosition={true} title={"Tematyka popis"} />
        )}
      />
      <Route
        exact
        path={urls.rankingMotivation}
        component={() => (
          <ContentRankingPage
            showPosition={true}
            title={"Tematyka motywacyjna"}
          />
        )}
      />
      <Route
        exact
        path={urls.rankingAlbums}
        component={() => (
          <ContentRankingPage showPosition={true} title={"Ranking albumów"} />
        )}
      />
      <Route exact path={urls.rankingPopular} component={PopularRankingPage} />
      <Route exact path={urls.rankingCritics} component={CriticRankingPange} />
      <Route exact path={urls.login} component={LoginPage} />
      <Route exact path={urls.register} component={RegisterPage} />
      <Route exact path={urls.addContent} component={AddSongPage} />
      <Route exact path={urls.myAccount} component={MyAccountPage} />
      <Route exact path={urls.account} component={DiffUserAccountPage} />
      <Route exact path={urls.aboutUs} component={AboutUsPage} />
      <Route exact path={urls.contact} component={ContactPage} />
      <Route exact path={urls.regulations} component={RegulationsPage} />
      <Route exact path={urls.privacyPolicy} component={PrivacyPolicyPage} />
      <Route exact path={urls.rodo} component={RODOPage} />
      <Route exact path={urls.forgotPassword} component={ForgotPasswordPage} />
      <Route exact path={urls.newPassword} component={NewPasswordPage} />
      <Route
        exact
        path={urls.externalBrowser}
        component={ExternalBrowserPage}
      />
      <Route exact path={urls.myList} component={MyListPage} />
      <Route
        exacta
        path={urls.myListBit}
        component={() => <MyContentRankingPage contentType={"bit"} />}
      />
      <Route
        exacta
        path={urls.myListLirycs}
        component={() => <MyContentRankingPage contentType={"lirycs"} />}
      />
      <Route
        exacta
        path={urls.myListVocal}
        component={() => <MyContentRankingPage contentType={"vocal"} />}
      />
      <Route
        exacta
        path={urls.myListEnergy}
        component={() => <MyContentRankingPage contentType={"funny"} />}
      />
      <Route
        exact
        path={urls.myListChill}
        component={() => <MyContentRankingPage contentType={"neutral"} />}
      />
      <Route
        exact
        path={urls.myListSad}
        component={() => <MyContentRankingPage contentType={"sad"} />}
      />
      <Route
        exact
        path={urls.myListSerious}
        component={() => <MyContentRankingPage contentType={"serious"} />}
      />
      <Route
        exact
        path={urls.myListFlex}
        component={() => <MyContentRankingPage contentType={"showOff"} />}
      />
      <Route
        exact
        path={urls.myListStorytelling}
        component={() => <MyContentRankingPage contentType={"motivation"} />}
      />
      <Route exact path={urls.points} component={PointsPage} />
      <Route exact path={urls.articles} component={ArticlesPage} />
      <Route
        exact
        path={urls.article}
        component={() => <SingleArticlePage type="article" />}
      />
      <Route
        exact
        path={urls.monthSummary}
        component={() => <SingleArticlePage type="month-summary" />}
      />

      {/*BACKOFFICE*/}
      <Route
        exact
        path={urls.backoffice.songRequests}
        component={BOsongRequestsPage}
      />
      <Route exact path={urls.backoffice.addImage} component={BOaddImage} />
      <Route exact path={urls.backoffice.addArticle} component={BOaddArticle} />
      <Route
        exact
        path={urls.backoffice.editArticle}
        component={() => <BOeditArticle type="article" />}
      />
      <Route
        exact
        path={urls.backoffice.editMonthSummary}
        component={() => <BOeditArticle type="month-summary" />}
      />
      <Route exact path={urls.backoffice.addLyrics} component={BOaddLyrics} />

      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default RouterComponent;
