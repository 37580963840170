import React, { memo } from "react";
import PropTypes from "prop-types";

const PageTitle = (props) => {
  const { children, className } = props;
  return <h1 className={`page-title ${className ?? ""}`}>{children}</h1>;
};

PageTitle.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
};

export default memo(PageTitle);
