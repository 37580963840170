import React, { memo, useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import CenterContent from "../../../../common/components/CenterContent/CenterContent";
import { connect } from "react-redux";
import { getCommentsAnonymous } from "../../../../Api/articles/getCommentsAnonymous";
import SingleReviewOverviewSkeleton from "../../../SingleSongPage/components/ReviewOverview/SingleReviewOverviewSkeleton";
import { getCommentsLoggedIn } from "../../../../Api/articles/getCommentsLoggedIn";
import SingleComment from "./SingleComment";
import { getNumberOfComments } from "../../../../Api/articles/getNumberOfComments";

const CommentOverview = (props) => {
  const {
    className,
    userAuthentiacated,
    userCredentials,
    notification,
    type,
    id,
  } = props;
  const [displayedReviews, _setDisplayedReviews] = useState();
  const [numberOfReviews, setNumberOfRevies] = useState();
  const [loading, _setLoading] = useState(false);
  const [lastDocId, _setLastDocId] = useState();
  const [lastQueryId, _setLastQueryId] = useState();
  const [endOfResults, _setEndOfResults] = useState(false);

  //refs needed for SCROLL LISTENER
  const displayedReviewsRef = useRef(displayedReviews);
  const setDisplayedReviews = (data) => {
    displayedReviewsRef.current = data;
    _setDisplayedReviews(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  };

  const lastDocIdRef = useRef(lastDocId);
  const setLastDocId = (data) => {
    lastDocIdRef.current = data;
    _setLastDocId(data);
  };

  const lastQueryIdRef = useRef(lastQueryId);
  const setLastQueryId = (data) => {
    lastQueryIdRef.current = data;
    _setLastQueryId(data);
  };

  const endOfResultsRef = useRef(endOfResults);
  const setEndOfResults = (data) => {
    endOfResultsRef.current = data;
    _setEndOfResults(data);
  };

  const userCredentialsRef = useRef();
  const setUserCredentialsRef = (data) => {
    userCredentialsRef.current = data;
  };

  const lazyLoad = useCallback((id) => {
    const scrollNearTheBottom =
      document.documentElement.scrollHeight - window.innerHeight - 100 <=
      Math.trunc(window.scrollY);
    if (
      scrollNearTheBottom &&
      lastQueryIdRef.current !== lastDocIdRef.current &&
      !loadingRef.current &&
      displayedReviewsRef.current.length >= 10
    ) {
      setLoading(true);
      if (userCredentialsRef.current) {
        getCommentsLoggedIn(
          id,
          userCredentialsRef.current.email,
          lastDocIdRef.current,
          type
        )
          .then((res) => {
            if (res.length > 0) {
              setLastQueryId(lastDocIdRef.current);
              setDisplayedReviews([...displayedReviewsRef.current, ...res]);
              setLoading(false);
              setLastDocId(res[res.length - 1].email);
            } else {
              setLastQueryId(lastDocIdRef.current);
              setEndOfResults(true);
              setLoading(false);
            }
          })
          .catch((err) => console.log(err));
      } else {
        getCommentsAnonymous(id, lastDocIdRef.current, type)
          .then((anonymousRes) => {
            if (anonymousRes.length > 0) {
              setLastQueryId(lastDocIdRef.current);
              setDisplayedReviews([
                ...displayedReviewsRef.current,
                ...anonymousRes,
              ]);
              setLoading(false);
              setLastDocId(anonymousRes[anonymousRes.length - 1].email);
            } else {
              setLastQueryId(lastDocIdRef.current);
              setEndOfResults(true);
              setLoading(false);
            }
          })
          .catch((err) => console.log(err));
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => lazyLoad(id));

    return () => {
      window.removeEventListener("scroll", () => lazyLoad(id));
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    getNumberOfComments(id, type).then((data) => {
      setNumberOfRevies(data.number);
    });
    if (userAuthentiacated && userCredentials.email) {
      setUserCredentialsRef(userCredentials);
      getCommentsLoggedIn(id, userCredentials.email, "", type).then((res) => {
        setDisplayedReviews(res);
        setLoading(false);
        if (res.length > 0) setLastDocId(res[res.length - 1].email);
      });
    } else {
      getCommentsAnonymous(id, "", type).then((anonymousRes) => {
        setDisplayedReviews(anonymousRes);
        setLoading(false);
        if (anonymousRes.length > 0)
          setLastDocId(anonymousRes[anonymousRes.length - 1].email);
      });
    }
  }, [userAuthentiacated, userCredentials, notification]);

  useEffect(() => {
    if (displayedReviews !== undefined && displayedReviews.length > 0) {
      setLastDocId(displayedReviews[displayedReviews.length - 1].email);
    }
  }, [displayedReviews]);

  return (
    <div className={`review-overview ${className ?? ""}`}>
      <h2 className="mb-4">
        Komentarze {numberOfReviews ? `(${numberOfReviews})` : ""}
      </h2>

      {displayedReviews !== undefined && displayedReviews.length > 0 ? (
        displayedReviews.map((reviewData) => (
          <SingleComment
            reviewData={reviewData}
            key={reviewData.email}
            type={type}
            id={id}
          />
        ))
      ) : (
        <>{!loading && <CenterContent>BRAK</CenterContent>}</>
      )}
      {loading && <SingleReviewOverviewSkeleton />}
      {endOfResults && (
        <CenterContent className="mt-2 mb-2">
          {" "}
          KONIEC LISTY KOMENTARZY{" "}
        </CenterContent>
      )}
    </div>
  );
};

CommentOverview.propTypes = {
  type: PropTypes.string,
  id: PropTypes.string,
};

const mapStatetoProps = (state) => ({
  userAuthentiacated: state.user.authenticated,
  userCredentials: state.user.credentials,
  notification: state.notification,
});

export default memo(connect(mapStatetoProps)(CommentOverview));
