import React from "react";

const ArticleTileSkeleton = () => {
  return (
    <div className="article-tile-skeleton">
      <div className="article-tile-skeleton__img-container"></div>
      <div className="article-tile-skeleton__text">
        <h2 className="article-tile-skeleton__text__title skeleton"></h2>
        <h2 className="article-tile-skeleton__text__title-second skeleton"></h2>
        <div className="article-tile-skeleton__text__teaser">
          <div className="article-tile-skeleton__text__teaser__line skeleton"></div>
          <div className="article-tile-skeleton__text__teaser__line skeleton"></div>
          <div className="article-tile-skeleton__text__teaser__line skeleton"></div>
        </div>
      </div>
      <div className="article-tile-skeleton__tags">
        <span className="article-tile-skeleton__tags__single-tag skeleton"></span>
        <span className="article-tile-skeleton__tags__single-tag skeleton"></span>
        <span className="article-tile-skeleton__tags__single-tag skeleton"></span>
      </div>
    </div>
  );
};

export default ArticleTileSkeleton;
