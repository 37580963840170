import React from "react";
import { FrownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { urls } from "../../Router/urls";
import Btn from "../../common/components/Btn/Btn";
import MetaData from "../../common/components/MetaData/MetaData";

const NotFoundPage = (props) => {
  return (
    <div className="no-results-serach">
      <MetaData
        description={"Nie znaleziono strony którą szukasz."}
        title={"Błędny URL"}
      />
      <h1 className="no-results-serach__header">
        Niestety podany URL jest błędny
      </h1>
      <FrownOutlined className="no-results-serach__icon" />
      <Link to={urls.homePage}>
        <Btn>Przejdź do strony głównej</Btn>
      </Link>
    </div>
  );
};

export default NotFoundPage;
