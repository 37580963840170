import React, { memo } from "react";
import PropTypes from "prop-types";
import Tile from "../../../../../common/components/Tile/Tile";

const SingleRequestSkeleton = (props) => {
  const { className } = props;

  const timesToRender = [0, 1, 3, 4];

  return (
    <>
      {timesToRender.map(() => (
        <li
          className={`${className ?? "req-history-skeleton__single-request"}`}
        >
          <Tile>
            <div className="req-history-skeleton__single-request__content">
              <div className="req-history-skeleton__single-request__content__frame"></div>
              <div className="req-history-skeleton__single-request__content__description">
                <h2 className="req-history-skeleton__single-request__content__description__title skeleton"></h2>
                <div className="req-history-skeleton__single-request__content__description__property">
                  <span className="req-history-skeleton__single-request__content__description__property__label skeleton"></span>
                </div>
                <div className="req-history-skeleton__single-request__content__description__property">
                  <span className="req-history-skeleton__single-request__content__description__property__label skeleton"></span>
                </div>
                <div className="req-history-skeleton__single-request__content__description__property">
                  <span className="req-history-skeleton__single-request__content__description__property__label skeleton longer"></span>
                </div>
              </div>
              <div
                className={`req-history-skeleton__single-request__content__state-block`}
              ></div>
            </div>
          </Tile>
        </li>
      ))}
    </>
  );
};

SingleRequestSkeleton.propTypes = {
  className: PropTypes.string,
};

export default memo(SingleRequestSkeleton);
