import axios from "axios";
import { urls } from "../../Router/urls";

export const getComment = (id, email, type) => {
  return axios
    .get(`${urls.base}/api/get-comment?id=${id}&email=${email}&type=${type}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
