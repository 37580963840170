import axios from "axios";
import { urls } from "../../Router/urls";
import { getIfUserLikedComment } from "./getfUserLikedComment";

export const getCommentsLoggedIn = (id, currentUserEmail, lastDocId, type) => {
  return axios
    .get(
      `${urls.base}/api/get-article-comment-list?id=${id}&lastDocId=${lastDocId}&type=${type}`
    )
    .then((res) => {
      let requests = res.data.reviews.map((review) => {
        return getIfUserLikedComment(
          id,
          review.email,
          currentUserEmail,
          type
        ).then((likeResponse) => {
          return {
            ...review,
            liked: likeResponse.liked,
          };
        });
      });
      return Promise.all(requests).then((responses) => {
        return responses;
      });
    })
    .catch((error) => {
      return error.response;
    });
};
