import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import { Carousel } from "antd";
import cdsPhoto from "../../../images/cds-home.jpg";
import cdsMobilePhoto from "../../../images/cds-home-mobile.jpg";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../common/variables/breakpoints/breakpoints";

const HomeCarousel = (props) => {
  const { className } = props;

  const isSmallDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });
  return (
    <Carousel autoplay className="home-carousel">
      <div className="home-carousel__item">
        <img
          src={isSmallDesktop ? cdsPhoto : cdsMobilePhoto}
          alt="header-CDs"
        />
      </div>
    </Carousel>
  );
};

HomeCarousel.propTypes = {
  className: PropTypes.string,
};

export default memo(HomeCarousel);
