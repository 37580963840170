import React, { memo, useEffect, useState } from "react";
import SingleArtistResult from "../../common/components/ArtistRanking/components/SingleArtistResult/SingleArtistResult";
import MetaData from "../../common/components/MetaData/MetaData";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import { urls } from "../../Router/urls";

const RankingListPage = (props) => {
  const dataList = [
    {
      author: "energiczne",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/funny.jpg?alt=media",
      redirectUrl: urls.rankingFunny,
    },
    {
      author: "chill",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/neutral.jpeg?alt=media",
      redirectUrl: urls.rankingNeutral,
    },
    {
      author: "smutne",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/sad.jpeg?alt=media",
      redirectUrl: urls.rankingSad,
    },
    {
      author: "poważne",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/serious.jpeg?alt=media",
      redirectUrl: urls.rankingSerious,
    },
    {
      author: "flex",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/showoff.jpeg?alt=media",
      redirectUrl: urls.rankingShowOff,
    },
    {
      author: "storytelling",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/motivation.jpg?alt=media",
      redirectUrl: urls.rankingMotivation,
    },
  ];
  return (
    <PageContainer>
      <MetaData
        title={"Lista rankingów - Rap krytyk"}
        description={
          "Wybierz ranking który Cię najbardziej zainteresuje! Odkrywaj rankingi które stworzyli nasi użytkownicy poprzez dodawanie ocen. Najlepsza lista utworów."
        }
      />
      <PageTitle>Rankingi utworów:</PageTitle>
      <ul className="pl-0 mb-0">
        {dataList.map((data, index) => (
          <SingleArtistResult artistRankingData={data} key={index} />
        ))}
      </ul>
    </PageContainer>
  );
};

export default memo(RankingListPage);
