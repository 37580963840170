import React, { memo } from "react";
import PropTypes from "prop-types";
import CenterContent from "../../../../../common/components/CenterContent/CenterContent";
import RateStars from "../../../../../common/components/RateStars/RateStars";
import YourOverallRate from "../../../../../common/components/YourOverallRate/YourOverallRate";

const UserRate = (props) => {
  const {
    cohesion,
    overall,
    sound,
    bit,
    lirycs,
    type,
    vocal,
  } = props.userRateData;

  const typeLabels = {
    funny: "Energiczny",
    neutral: "Chill",
    sad: "Smutny",
    serious: "Poważny",
    showOff: "Flex",
    motivation: "Storytelling",
  };

  return (
    <div className="rate-form">
      {bit ? (
        <>
          <div className="rate-form__single-rate mb-4">
            <RateStars
              label={"Bit / Podkład muzyczny"}
              value={bit}
              disabled={true}
            />
          </div>
          <div className="mb-4 rate-form__single-rate">
            <RateStars
              label={"Słowa / Nawijka"}
              value={lirycs}
              disabled={true}
            />
          </div>
          <div className="mb-4 rate-form__single-rate">
            <RateStars
              label={"Wokal / Brzmienie głosu"}
              value={vocal}
              disabled={true}
            />
          </div>

          <div className="rate-form__type">
            <CenterContent className="mb-1">
              <h3>Wybrałeś typ: {typeLabels[type]}</h3>
            </CenterContent>
          </div>
        </>
      ) : (
        <>
          <div className="rate-form__single-rate mb-4">
            <RateStars label={"Brzmienie"} value={sound} disabled={true} />
          </div>
          <div className="mb-4 rate-form__single-rate">
            <RateStars
              label={"Słowa / Nawijka"}
              value={lirycs}
              disabled={true}
            />
          </div>
          <div className="mb-4 rate-form__single-rate">
            <RateStars label={"Spójność"} value={cohesion} disabled={true} />
          </div>
        </>
      )}

      {<YourOverallRate overall={overall} showWarn={false} />}
    </div>
  );
};

UserRate.propTypes = {
  userRateData: PropTypes.shape({
    cohesion: PropTypes.number,
    overall: PropTypes.number,
    sound: PropTypes.number,
    bit: PropTypes.number,
    lirycs: PropTypes.number,
    type: PropTypes.string,
    vocal: PropTypes.number,
  }),
};

export default memo(UserRate);
