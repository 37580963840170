import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import Rate from "rc-rate";
import "rc-rate/assets/index.css";
import CenterContent from "../CenterContent/CenterContent";

const RateStars = (props) => {
  const { className, label, value, onChange, disabled } = props;
  const [predictedStarValue, setPredictedStarValue] = useState();

  const addInactiveClass = () => {
    if (value || predictedStarValue) return "";
    else return "--inactive";
  };
  return (
    <div className={`rate-stars-block ${className ?? ""}`}>
      <h3 className="rate-stars-block__label">{label}</h3>
      <CenterContent>
        <Rate
          count={10}
          style={{ fontSize: "26px", outline: "none" }}
          value={value}
          allowHalf={true}
          onChange={(newValue) => onChange(newValue)}
          onHoverChange={(newValue) => setPredictedStarValue(newValue)}
          disabled={disabled}
        />
      </CenterContent>
      <CenterContent>
        <h5 className={`rate-stars-block__value${addInactiveClass()}`}>
          {predictedStarValue ? predictedStarValue : value ? value : 0}
        </h5>
      </CenterContent>
    </div>
  );
};

RateStars.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default memo(RateStars);
