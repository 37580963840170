import React, { memo } from "react";
import PropTypes from "prop-types";
import Number from "../../../Number/Number";

const Position = (props) => {
  const { className, position } = props;
  return (
    <>
      <div className={`position ${className ?? ""}`}>
        <Number>{position}</Number>
      </div>
    </>
  );
};

Position.propTypes = {
  className: PropTypes.string,
  position: PropTypes.number,
};

export default memo(Position);
