import React, { memo, useState, useEffect } from "react";
import { postAlbumCover } from "../../../Api/songs/postAlbumCover";
import Btn from "../../../common/components/Btn/Btn";
import CenterContent from "../../../common/components/CenterContent/CenterContent";
import InputField from "../../../common/components/InputField/InputField";
import PageContainer from "../../../common/components/PageContainer/PageContainer";
import Spinner from "../../../common/components/Spinner/Spinner";
import { useHistory } from "react-router-dom";
import { urls } from "../../../Router/urls";
import { connect } from "react-redux";

const BOaddImage = (props) => {
  const { user } = props;
  const history = useHistory();

  const [imagePreview, setImagePreview] = useState();
  const [imageData, setImageData] = useState();
  const [imageName, setImageName] = useState("");
  const [databaseImageUrl, setDatabaseImageUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      user.credentials.type !== "admin" &&
      user.credentials.type !== "editor" &&
      user.credentials.type !== undefined
    ) {
      history.push(urls.homePage);
    }
  }, [user.credentials.type]);

  const hadnlePhotoChange = (event) => {
    if (event.target.files[0].size <= 519887) {
      const image = event.target.files[0];
      setImagePreview(URL.createObjectURL(image));
      setImageData(image);
    }
  };

  const hadnlePhotoAddBtnClick = () => {
    const fileInput = document.getElementById("bo-image");
    fileInput.click();
  };

  const submit = () => {
    setLoading(true);
    const fileName = imageData.name;
    const extension = fileName.split(".")[fileName.split(".").length - 1];
    const formData = new FormData();

    formData.append("image", imageData, `${imageName}.${extension}`);
    return postAlbumCover(formData)
      .then((res) => {
        setDatabaseImageUrl(
          `https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/${imageName}.${extension}?alt=media`
        );
        setImagePreview(undefined);
        setImageData(undefined);
        setImageName("");
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <PageContainer>
      <div style={{ minHeight: "50vh" }}>
        <input
          type="file"
          id="bo-image"
          hidden="hidden"
          onChange={hadnlePhotoChange}
        />
        {imagePreview && (
          <CenterContent className="mb-2">
            <img
              alt="bo-image"
              src={imagePreview}
              style={{ maxWidth: "100%" }}
            />
          </CenterContent>
        )}
        <CenterContent className="c-gray mb-1">
          Maksymalny rozmiar obrazu 500 kb
        </CenterContent>
        <CenterContent>
          <Btn onClick={hadnlePhotoAddBtnClick}>Wybierz zdjęcie</Btn>
        </CenterContent>
        {imageData && (
          <CenterContent className="mt-4">
            <InputField
              onChange={(e) => setImageName(e.target.value)}
              label={"Nazwa zdjęcia"}
            />
          </CenterContent>
        )}
        {loading && <Spinner className="mt-2" />}
        {imageData && !loading && (
          <CenterContent className="mt-2">
            <Btn onClick={submit} disabled={imageName.length < 3}>
              Zapisz zdjęcie w bazie danych
            </Btn>
          </CenterContent>
        )}
        {databaseImageUrl.length > 4 && !loading && (
          <>
            <CenterContent className="mt-4">
              <h4>SPRAWDŻ CZY LINK DZIAŁA!</h4>
            </CenterContent>
            <CenterContent className="mt-2">
              <a href={databaseImageUrl} alt="image-link" target="_blank">
                {databaseImageUrl}
              </a>
            </CenterContent>
          </>
        )}
      </div>
    </PageContainer>
  );
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

export default memo(connect(mapStatetoProps)(BOaddImage));
