import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { urls } from "../../../../../Router/urls";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../../../../Redux/actions/userActions";
import { useHistory } from "react-router-dom";
import Btn from "../../../Btn/Btn";
import LeagueProgressBar from "../../../LeagueProgressBar/LeagueProgressBar";
import CenterContent from "../../../CenterContent/CenterContent";

const AccountBlockPhone = (props) => {
  const {
    className,
    user,
    logoutUser,
    setCurrent,
    loginActive,
    registerActive,
  } = props;
  const history = useHistory();

  const logOutHandler = () => {
    logoutUser();
    history.push("/");
  };

  const [imageSrc, setImageSrc] = useState();
  useEffect(() => {
    setImageSrc(
      `https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/rank-${user.credentials.rank}.png?alt=media`
    );
  }, [user.credentials.rank]);

  return (
    <div className={`navigation-mobile__account-block ${className ?? ""}`}>
      {user.loading && (
        <div className="navigation-mobile__account-block__skeleton-container">
          <span className="navigation-mobile__account-block__skeleton-container__img skeleton"></span>
          <span className="navigation-mobile__account-block__skeleton-container__nickname skeleton"></span>
        </div>
      )}
      {user.authenticated && user.credentials.nickName && (
        <div className="navigation-mobile__account-block__user">
          <Link to={urls.myAccount}>
            <img
              className="navigation-mobile__account-block__user__img"
              src={imageSrc}
            />
            <div className="navigation-mobile__account-block__user__name">
              {user.credentials.nickName}
            </div>
            <LeagueProgressBar
              userRank={user.credentials.rank}
              userPoints={user.credentials.points}
            />
          </Link>

          <CenterContent>
            <Btn
              className="navigation-mobile__account-block__user__log-out"
              onClick={logOutHandler}
            >
              Wyloguj
            </Btn>
          </CenterContent>
        </div>
      )}

      {!user.loading && !user.authenticated && (
        <>
          <Link
            to={urls.register}
            onClick={() => {
              setCurrent(urls.register);
            }}
            className={`navigation-mobile__account-block__nav-link pl-4 pr-4 ${registerActive}`}
          >
            Rejestracja
          </Link>
          <div>
            <Link
              to={urls.login}
              onClick={() => {
                setCurrent(urls.login);
              }}
              className={`navigation-mobile__account-block__nav-link pl-4 pr-4 ${loginActive}`}
            >
              Zaloguj
            </Link>
          </div>
        </>
      )}
    </div>
  );
};

AccountBlockPhone.propTypes = {
  className: PropTypes.string,
  setCurrent: PropTypes.func.isRequired,
  registerActive: PropTypes.string.isRequired,
  loginActive: PropTypes.string.isRequired,
  user: PropTypes.shape({
    authenticated: PropTypes.bool,
    loading: PropTypes.bool,
    credentials: PropTypes.object,
    errorsBE: PropTypes.object,
  }),
};

const mapStatetoProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  logoutUser,
};

export default memo(
  connect(mapStatetoProps, mapDispatchToProps)(AccountBlockPhone)
);
