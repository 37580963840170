import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import SongRanking from "../../../common/components/SongRanking/SongRanking";
import { useLocation } from "react-router-dom";
import { getAlbumSearch } from "../../../Api/search/getAlbumSearch";
import PropTypes from "prop-types";

const SearchSongRanking = (props) => {
  const { activeTab } = props;
  const location = useLocation();

  const [results, _setResults] = useState();
  const [title, setTitle] = useState("Wyszukano");
  const [query, _setQuery] = useState();
  const [lastSongId, _setLastSongId] = useState("");
  const [lastSentQuerySongId, _setLastSentQuerySongId] = useState("");
  const [loading, _setLoading] = useState(true);
  const [endOfResults, _setEndOfResults] = useState(false);

  //refs needed for SCROLL LISTENER
  const resultsRef = useRef(results);
  const setResults = (data) => {
    resultsRef.current = data;
    _setResults(data);
  };

  const queryRef = useRef(query);
  const setQuery = (data) => {
    queryRef.current = data;
    _setQuery(data);
  };

  const lastSongIdRef = useRef(lastSongId);
  const setLastSongId = (data) => {
    lastSongIdRef.current = data;
    _setLastSongId(data);
  };

  const lastSentQuerySongIdRef = useRef(lastSentQuerySongId);
  const setLastSentQuerySongId = (data) => {
    lastSentQuerySongIdRef.current = data;
    _setLastSentQuerySongId(data);
  };

  const loadingRef = useRef(loading);
  const setLoading = (data) => {
    loadingRef.current = data;
    _setLoading(data);
  };

  const endOfResultsRef = useRef(endOfResults);
  const setEndOfResults = (data) => {
    endOfResultsRef.current = data;
    _setEndOfResults(data);
  };

  const lazyLoad = useCallback(() => {
    const scrollNearTheBottom =
      document.documentElement.scrollHeight - window.innerHeight - 100 <=
      Math.trunc(window.scrollY);
    if (
      scrollNearTheBottom &&
      lastSentQuerySongIdRef.current !== lastSongIdRef.current &&
      !loadingRef.current &&
      resultsRef.current.length > 0
    ) {
      setLoading(true);
      getAlbumSearch(queryRef.current, lastSongIdRef.current)
        .then((res) => {
          if (res.length > 0) {
            const newResults = [];

            res.forEach((singleResponse) => {
              newResults.push({
                id: singleResponse.id,
                name: singleResponse.name,
                author: singleResponse.artist,
                album: singleResponse.album,
                releaseDate: singleResponse.year,
                rate: singleResponse.avarageRate,
                numberOfVotes: singleResponse.numberOfRates,
                imageUrl: singleResponse.imageUrl,
              });
            });

            setLastSentQuerySongId(lastSongIdRef.current);
            setResults([...resultsRef.current, ...newResults]);
            setLoading(false);
          } else {
            setLastSentQuerySongId(lastSongIdRef.current);
            setEndOfResults(true);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  //NEW SEARCH
  useEffect(() => {
    setResults([]);
    setLoading(true);
    setEndOfResults(false);
    setLastSentQuerySongId(undefined);
    const newQuery = new URLSearchParams(location.search).get("q");
    setQuery(newQuery);
    setTitle("Wyszukano " + newQuery);

    getAlbumSearch(newQuery, "")
      .then((res) => {
        const newResults = [];

        res.forEach((singleResponse) => {
          newResults.push({
            id: singleResponse.id,
            name: singleResponse.name,
            author: singleResponse.artist,
            releaseDate: singleResponse.year,
            rate: singleResponse.avarageRate,
            numberOfVotes: singleResponse.numberOfRates,
            imageUrl: singleResponse.imageUrl,
          });
        });

        setResults(newResults);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [location]);

  useEffect(() => {
    if (results !== undefined && results.length > 0) {
      setLastSongId(results[results.length - 1].id);
    }
  }, [results]);

  useEffect(() => {
    window.removeEventListener("scroll", lazyLoad);
    if (activeTab === "album") {
      window.addEventListener("scroll", lazyLoad);
    }
    if (results !== undefined && results.length > 0)
      setLastSongId(results[results.length - 1].id);
  }, [activeTab]);

  return (
    <SongRanking
      showPosition={false}
      title={title}
      songRankingData={results}
      query={query}
      loading={loading}
      endOfResults={endOfResults}
    />
  );
};

SearchSongRanking.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

export default memo(SearchSongRanking);
