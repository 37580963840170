import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Carousel } from "antd";
import SingleRecentlySong from "./SingleRecentlySong";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "../../../../common/variables/breakpoints/breakpoints";
import { getRecentlyAdded } from "../../../../Api/songs/getRecentlyAdded";
import SingleRecentlySongSkeleton from "./SingleRecentlySongSkeleton";

const HomeCarousel = (props) => {
  const { className } = props;
  const [songData, setSongData] = useState();
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    getRecentlyAdded()
      .then((res) => {
        const newSongData = [];
        res.forEach((singleResponse) => {
          newSongData.push({
            id: singleResponse.id,
            albumIndex: singleResponse.albumIndex,
            name: singleResponse.name,
            author: singleResponse.artist,
            additionalArtists: singleResponse.additionalArtists,
            album: singleResponse.album,
            imageUrl: singleResponse.imageUrl,
          });
        });
        setSongData(newSongData);
      })
      .catch((err) => console.log(err));
  }, []);

  const isSmallDesktop = useMediaQuery({
    query: breakpoints.smallDesktop,
  });

  useEffect(() => {
    let newTiles = [];

    if (songData) {
      if (isSmallDesktop) {
        for (let i = 0; i < Math.ceil(songData.length / 3); i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              {songData.slice(i * 3, (i + 1) * 3).map((singleSongData) => (
                <SingleRecentlySong songData={singleSongData} />
              ))}
            </div>
          );
        }
      } else {
        newTiles = songData.map((singleSongData) => (
          <div className="recently-added-carousel__item">
            <SingleRecentlySong songData={singleSongData} />
          </div>
        ));
      }
    } else {
      if (isSmallDesktop) {
        for (let i = 0; i < 3; i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              <SingleRecentlySongSkeleton />
              <SingleRecentlySongSkeleton />
              <SingleRecentlySongSkeleton />
            </div>
          );
        }
      } else {
        for (let i = 0; i < 9; i++) {
          newTiles.push(
            <div className="recently-added-carousel__item">
              <SingleRecentlySongSkeleton />
            </div>
          );
        }
      }
    }

    setTiles(newTiles);
  }, [isSmallDesktop, songData]);

  return (
    <>
      <h2 className="recently-added-header">OSTATNIO DODANE UTWORY</h2>
      {tiles && (
        <Carousel
          className="recently-added-carousel"
          arrows={isSmallDesktop}
          draggable
          autoplay
          autoplaySpeed={5000}
          speed={isSmallDesktop ? 1000 : 400}
        >
          {tiles}
        </Carousel>
      )}
    </>
  );
};

HomeCarousel.propTypes = {
  className: PropTypes.string,
};

export default memo(HomeCarousel);
