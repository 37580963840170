import axios from "axios";
import { urls } from "../../Router/urls";
import store from "../../Redux/store";

export const postAddLike = (reviewData) => {
  const reduxState = store.getState();
  if (reduxState.user.credentials.email) {
    const dataForPost = {
      ...reviewData,
      currentUserEmail: reduxState.user.credentials.email,
    };

    return axios
      .post(`${urls.base}/api/like-review`, dataForPost)
      .then((res) => {
        return res.data.data;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  } else {
    window.location.replace(urls.login);
  }
};
