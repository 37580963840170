import React, { memo, useState } from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import RegisterAdvantageBaner from "../RegisterPage/components/RegisterAdvantageBaner/RegisterAdvantageBaner";
import { urls } from "../../Router/urls";
import { Link } from "react-router-dom";
import MetaData from "../../common/components/MetaData/MetaData";
import { Form } from "antd";
import { useHistory } from "react-router-dom";
import CenterContent from "../../common/components/CenterContent/CenterContent";
import Btn from "../../common/components/Btn/Btn";
import InputField from "../../common/components/InputField/InputField";
import { showNotificationAction } from "../../Redux/actions/notificationActions";
import { postResetPasswordEmail } from "../../Api/emails/postResetPassordEmail";
import { connect } from "react-redux";
import Spinner from "../../common/components/Spinner/Spinner";

const ForgotPassordPage = (props) => {
  const { className, showNotificationAction } = props;
  const [form] = Form.useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const forgotHandler = (resetData) => {
    setLoading(true);
    postResetPasswordEmail(resetData).then((res) => {
      setLoading(false);
      showNotificationAction(
        {
          title: "RESTART HASŁA",
          message:
            "By tego dokonać kliknij proszę w link, który został przesłany na Twoją skrzynkę pocztową.",
          icon: "email",
        },
        60
      );
      history.push(urls.homePage);
    });
  };

  const requiredRule = { required: true, message: "Proszę uzupełnij te pole." };

  return (
    <section className={`${className ?? ""}`}>
      <MetaData
        title={"Restart hasła - Rap Krytyk"}
        description="Zapomniałeś hasła do portalu RapKrytyk.pl i nie możesz się zalogować? Spokojnie, możesz je zrestartować jeśli pamiętasz jakim emailem się logowałeś."
      />
      <PageContainer className={"register-page"}>
        <PageTitle>Zapomniałeś hasła?</PageTitle>

        <Form
          form={form}
          className={`register-page__register ${className ?? ""}`}
          onFinish={forgotHandler}
        >
          <CenterContent>
            <h2>
              <strong className="mb-3">Restart hasła</strong>
            </h2>
          </CenterContent>
          <CenterContent className="mb-4 text-center">
            W tym celu podaj proszę email, którego używałeś do logowania, a my
            wyślemi Ci link do zrestartowania hasła.
          </CenterContent>
          <div className="mb-4">
            <Form.Item name="email" rules={[requiredRule]}>
              <InputField label="Email*" type="email" requierd />
            </Form.Item>
          </div>
          {loading ? (
            <Spinner />
          ) : (
            <Form.Item>
              <CenterContent>
                <Btn
                  type="primary"
                  htmlType="submit"
                  className="register-page__register__submit"
                >
                  Zrestartuj hasło
                </Btn>
              </CenterContent>
            </Form.Item>
          )}
        </Form>
        <RegisterAdvantageBaner />
      </PageContainer>
    </section>
  );
};

ForgotPassordPage.propTypes = {
  className: PropTypes.string,
};

const mapDispatchToProps = {
  showNotificationAction,
};

export default memo(connect(null, mapDispatchToProps)(ForgotPassordPage));
