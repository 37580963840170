import axios from "axios";
import { urls } from "../../Router/urls";

export const getMonthSummaries = (lastArticleId) => {
  return axios
    .get(`${urls.base}/api/get-newest-month-summaries?last=${lastArticleId}`)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error.response;
    });
};
