import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import MetaData from "../../common/components/MetaData/MetaData";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import { useLocation } from "react-router-dom";
import { getSingleArticleData } from "../../Api/articles/getSingleArticleData";
import ArticleTitle from "./components/Tile/ArticleTitle";
import ArticleBody from "./components/Body/ArticleBody";
import ArticleMonthSummaryRecomendation from "./components/Body/ArticleMonthSummaryRecomendation";
import ArticleRecommendation from "./components/Body/ArticleRecommendation";
import HeaderImageSkeleton from "./skeletons/HeaderImageSkeleton";
import TitleSkeleton from "./skeletons/TitleSkeleton";
import ArticleBodySkeleton from "./skeletons/ArticleBodySkeleton";
import HeaderImage from "./components/Body/HeaderImage";
import { getSingleMonthSummary } from "../../Api/articles/getSingleMonthSummary";
import CreateComment from "./components/Comments/CreateComment";
import CommentOverview from "./components/Comments/CommentOverview";

const SingleArticlePage = (props) => {
  const { type } = props;
  const location = useLocation();
  const [article, setArticle] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location && location.pathname && type == "article") {
      setLoading(true);
      const articleId = location.pathname.split("article/")[1];
      getSingleArticleData(articleId).then((res) => {
        setArticle(res);
        setLoading(false);
      });
    } else if (location && location.pathname && type == "month-summary") {
      setLoading(true);
      const articleId = location.pathname.split("month-summary/")[1];
      getSingleMonthSummary(articleId).then((res) => {
        setArticle(res);
        setLoading(false);
      });
    }
  }, [location]);

  return (
    <section>
      {article && (
        <MetaData
          title={article.title}
          description={`Rap Krytyk - artykuł na temat: ${article.title} Teaser: ${article.teaserText}`}
        />
      )}
      <PageContainer>
        {article && !loading && (
          <>
            <ArticleTitle timeStamp={article.timeStamp}>
              {article.title}
            </ArticleTitle>
            <HeaderImage src={article.headerImg} tags={article.tags} />
          </>
        )}
        {/* SKELETON */}
        {loading && (
          <>
            <TitleSkeleton />
            <HeaderImageSkeleton />
          </>
        )}

        {article && !loading && (
          <div className="article-grid">
            <div className="article-grid__left">
              <ArticleBody
                author={article.author}
                teaserText={article.teaserText}
                id={article.id}
                tags={article.tags}
                link={article.link}
                type={type}
              />
            </div>
            <div className="article-grid__right">
              <ArticleMonthSummaryRecomendation />
              <ArticleRecommendation
                recommendedList={article.recommendedList}
              />
            </div>
          </div>
        )}

        {/* SKELETON */}
        {loading && <ArticleBodySkeleton />}

        <div className="article-comment-wrapper">
          {article && !loading && <CreateComment type={type} id={article.id} />}

          {article && !loading && (
            <CommentOverview type={type} id={article.id} />
          )}
        </div>
      </PageContainer>
    </section>
  );
};

SingleArticlePage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default memo(SingleArticlePage);
