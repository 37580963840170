import React, { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getNewestMonthSummary } from "../../../../Api/articles/getNewestMonthSummary";
import RecommendedArticleTile from "./RecommendedArticleTile";
import ArticleMonthSummaryRecomendationSkeleton from "../../skeletons/ArticleMonthSummaryRecomendationSkeleton";

const ArticleMonthSummaryRecomendation = (props) => {
  const {} = props;
  const [monthSummaryData, setMonthSummaryData] = useState();

  useEffect(() => {
    getNewestMonthSummary().then((res) => {
      setMonthSummaryData(res);
    });
  }, []);

  return (
    <section className="recommend-month-section">
      {monthSummaryData && <h2 className="mb-2 w100">Ostatni miesiąc</h2>}
      {monthSummaryData && (
        <RecommendedArticleTile articleData={monthSummaryData} />
      )}
      {!monthSummaryData && <ArticleMonthSummaryRecomendationSkeleton />}
    </section>
  );
};

ArticleMonthSummaryRecomendation.propTypes = {
  className: PropTypes.string,
};

export default memo(ArticleMonthSummaryRecomendation);
