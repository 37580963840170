import React from "react";
import { FrownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { urls } from "../../Router/urls";
import Btn from "../../common/components/Btn/Btn";
import MetaData from "../../common/components/MetaData/MetaData";
import PageContainer from "../../common/components/PageContainer/PageContainer";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import SingleArtistResult from "../../common/components/ArtistRanking/components/SingleArtistResult/SingleArtistResult";

const MyListPage = (props) => {
  const dataList = [
    {
      author: "najlepszy bit",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/bit.jpeg?alt=media",
      redirectUrl: urls.myListBit,
    },
    {
      author: "najlepsze słowa",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/lirycs.jpg?alt=media",
      redirectUrl: urls.myListLirycs,
    },
    {
      author: "najlepszy wokal",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/vocal.jpg?alt=media",
      redirectUrl: urls.myListVocal,
    },
    {
      author: "energiczne",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/funny.jpg?alt=media",
      redirectUrl: urls.myListEnergy,
    },
    {
      author: "chill",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/neutral.jpg?alt=media",
      redirectUrl: urls.myListChill,
    },
    {
      author: "smutne",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/sad.jpeg?alt=media",
      redirectUrl: urls.myListSad,
    },
    {
      author: "poważne",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/serious.jpeg?alt=media",
      redirectUrl: urls.myListSerious,
    },
    {
      author: "flex",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/showoff.jpg?alt=media",
      redirectUrl: urls.myListFlex,
    },
    {
      author: "storytelling",
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/rapkrytyk-8950a.appspot.com/o/motivation.jpg?alt=media",
      redirectUrl: urls.myListStorytelling,
    },
  ];
  return (
    <PageContainer>
      <MetaData
        description={
          "Rap krytyk -Twoja lista utworów. Energiczne, chill, smutne, poważne, flex, storytelling."
        }
        title={"Twoje listy - Rap Krytyk"}
      />
      <PageTitle>Listy przygotowane dla Ciebie:</PageTitle>
      <ul className="pl-0 mb-0">
        {dataList.map((data, index) => (
          <SingleArtistResult artistRankingData={data} key={index} />
        ))}
      </ul>
    </PageContainer>
  );
};

export default MyListPage;
